module.exports = {
    common: {
        login: '登錄',
        register: '註冊',
        loginregister: "登錄/註冊",
        logout: '退出登錄',
        tip: '溫馨提示',
        logintip: '請先登錄',
        expect: '敬請期待!',
        delete: '確定刪除嗎?',
        nodata: '暫無記錄',
        set: '設置',
        update: '修改',
        slogan: "全球前50的數字貨幣交易所",
        subslogan: "國家機構擔保資產安全",
        more: "更多",
        please: "請先",
        pleaseselect: "請選擇",
        searchplaceholder: "輸入幣種名稱搜索",
        searchplaceholderswap: "輸入合約名稱搜索",
        loading: "加載中...",
        close: "關閉",
        ok: "確定",
		fillComplete:"請填寫完整的信息"
    },
    header: {
        index: '首頁',
        exchange: '幣幣交易',
        otc: '法幣OTC',
        kefu: '幫助中心',
        asset: '財務中心',
        ucenter: '賬戶中心',
        service: '公告',
        downloadapp: "掃碼進行下載安裝",
        appdownlaod: 'APP下載',
        usercenter: "個人中心",
        helpcenter: "幫助中心",
        aboutus: "關於",
        partner: "合夥人",
        bzb: "BZB",
        assetmanage: "資金管理",
        trademanage: "交易管理",
        activity: "最新活動",
        lab: "創新實驗室",
        ctc: "法幣C2C",
        labdetail: "幣嚴實驗室 ● 共享創新成果",
        invite: "推廣合夥人",
        whitepaper: "白皮書",
        scandownload: "掃一掃下載APP",
        innovationmanage: "創新管理",
        swap1: "永續合約",
        swap2: "永續合約(撮合)",
        swap3: "差價合約",
        swap4: "秒合約",
        cexchange: "快速兌換",
        option: "期權合約",
        news: "資訊"
    },
    sectionPage: {
        ptaqTitle: '平台安全',
        ptaqContent: '採用哈希算法加密，全球領先的安全認證',
        ptslTitle: '平台實力',
        ptslContent: '全球最大的比特幣交易平台',
        newsTitle: '多終端APP',
        brandTitle: "關於CoinPro",
        brandDetail: "誠實 | 公平 | 熱情 | 開放",
        brandDesc1: "COIN.PRO由一群比特幣早期參與者與極客創立，團隊核心成員來自於谷歌、微軟、阿里巴巴、騰訊等知名企業，具有深厚的研發實力與豐富的互聯網產品運營經驗。",
        brandDesc2: "COIN.PRO定位於區塊鏈基礎服務商，致力於為全球用戶提供優質加密資產交易平台，秉承著“不作惡”的基本原則，堅持誠實、公正、熱情的服務於客戶，以開放的態度迎接一切有利於用戶根本利益的伙伴/項目。",
        gettingstart: "新手入門 | 極速買幣",
        officialstart: "CoinPro官方新手入門輔助通道",
        oneminutebuy: "幣幣交易",
		oneminutebuytips: "用一種數字資產去直接換取另一種",
		baseknow: "合約交易",
		baseknowtips: "T+0雙向交易虛擬商品作押易貨合約",
		baseexchange: "期權合約",
		baseexchangetips: "是一種數字資產衍生品",
		usersocial: "快速兌換",
		usersocialtips: "快速兌換數字資產",
        mainboard: "主板交易區",
        preview: "上一頁",
        nextpage: "下一頁",
        downloadwhite: '下載白皮書(或在白皮書上鼠標右鍵 -> "另存為...")'
    },
    nav: {
        shouye: '首頁',
        jiaoyi: '法幣交易',
        dingdan: '訂單管理',
        zijin: '財務中心',
        yonghu: '用戶中心',
        bibi: '幣幣交易',
        fabu: '發布廣告',
        ptjy: '平台交易',
        ptaq: '平台安全',
        ptsl: '平台實力',
        xwzx: '多終端APP'
    },
    progress: {
        already: "已挖礦",
        total: "可挖礦總量60億 BHB"
    },
    service: {
        USDT: 'USDT',
        BTC: 'BTC',
        ETH: 'ETH',
        CUSTOM: '自選',
        COIN: '幣種',
        favor: '收藏',
        symbol: '交易對',
        NewPrice: '最新價',
        ExchangeNum: '交易數量',
        Change: '24h漲跌',
        OpenPrice: '開盤價',
        PriceTrend: '價格趨勢',
        Operate: '操作',
        Exchange: '去交易',
        trading: "立即交易",
        high: "24h最高價",
        low: "24h最低價"
    },
    exchange: {
        coin: '幣種',
        symbol: "交易對",
        lastprice: '最新價',
        daychange: '24h漲跌',
        market: '市場',
        favorite: '收藏',
        do_favorite: '已收藏',
        cancel_favorite: '取消收藏',
        type: "類型",
        dealamount: "成交金額",
        high: '高',
        low: '低',
        vol: '24H量',
        buy: '買入',
        sell: '賣出',
        limited_price: '限價',
        market_price: '市價',
        fees_rate: '費率',
        balance: '資產',
        or: '或',
        starttrade: '開始交易',
        canuse: '可用',
        recharge: '充幣',
        buyprice: '買入價',
        buynum: '買入量',
        amount: '交易額',
        buyin: '買入',
        disablebuyin: "禁止市價買入",
        buytip: '以市場上最優價格買入',
        sellprice: '賣出價',
        sellnum: '賣出量',
        sellout: '賣出',
        disablesellout: "禁止市價賣出",
        selltip: '以市場上最優價格賣出',
        curdelegation: '當前委託',
        hisdelegation: '委託歷史',
        realtransaction: '實時成交',
        num: '數量',
        price: '價格',
        direction: '方向',
        time: '時間',
        stall: '檔位',
        total: '累計',
        traded: '已成交',
        action: '操作',
        undo: '撤單',
        delegationnum: '委託量',
        done: '已成交',
        status: '狀態',
        finished: '已完成',
        canceled: '已取消',
        parted: "部分成交",
        tip: '提示',
        buyamounttip: '請輸入買入量',
        buyamounttipwarning: '買入數量不能高於',
        success: '提交成功',
        pricetip: '請輸入交易額',
        pricetipwarning: '交易額不能高於',
        sellamounttip: '請輸入賣出量',
        sellamounttipwarning: '賣出數量不能高於',
        sellpricetip: '請輸入賣出價格',
        sellpricetipwarning: '賣出價格不能高於',
        undotip: '是否確認撤單？ ',
        cancelsuccess: "撤單成功！",
        marketprice: '市價',
        expand: {
            time: '時間',
            price: '價格',
            amount: '數量',
            fee: '手續費',
        },
        realtime: '分時',
        kline: "K線圖",
        depth: "深度圖",
        publishstate0: "活動即將開始，請稍候...",
        publishstate1: "活動即將結束，請盡快交易...",
        publishstate2: "活動訂單盤整中，請稍候...",
        publishstate3: "盤整結束，請自由交易...",
        publishdetail: "活動規則詳情",
        publishprice: "發行價",
        publishamount: "總量",
        publishcounttxt0: "距活動開始還剩",
        publishcounttxt1: "距離活動結束還剩",
        publishcounttxt2: "距離清盤結束還剩",
        publishcounttxt3: "活動已結束",
        dateTimeDay: "天",
        dateTimeHour: "時",
        dateTimeMinutes: "分",
        dateTimeSeconds: "秒",
        moredetail: "更多詳情"
    },
    swap: {
        contract: "合約",
        swapindex: "永續",
        coin: '幣種',
        symbol: "交易對",
        volume: "成交量",
        lastprice: '最新價',
        daychange: '24H漲跌',
        market: '市場',
        favorite: '收藏',
        do_favorite: '已收藏',
        cancel_favorite: '取消收藏',
        type: "類型",
        dealamount: "成交金額",
        high: '高',
        low: '低',
        vol: '24H量',
        buy: '買入',
        sell: '賣出',
        limited_price: '限價委託',
        market_price: '市價委託',
        spot_price: '計劃委託',
        fees_rate: '費率',
        balance: '資產',
        or: '或',
        starttrade: '開始交易',
        canuse: '可用',
        recharge: '充幣',
        buyprice: '買入價',
        buynum: '買入量',
        amount: '交易額',
        buyin: '買入',
        disablebuyin: "禁止市價買入",
        buytip: '以市場上最優價格買入',
        sellprice: '賣出價',
        sellnum: '賣出量',
        sellout: '賣出',
        disablesellout: "禁止市價賣出",
        selltip: '以市場上最優價格賣出',
        curdelegation: '當前委託',
        hisdelegation: '歷史委託',
        realtransaction: '實時成交',
        num: '數量',
        price: '價格',
        direction: '方向',
        time: '時間',
        stall: '檔位',
        total: '累計',
        traded: '已成交',
        action: '操作',
        undo: '撤單',
        delegationnum: '委託量',
        done: '已成交',
        status: '狀態',
        finished: '已完成',
        canceled: '已取消',
        parted: "部分成交",
        tip: '提示',
        buyamounttip: '請輸入買入量',
        buyamounttipwarning: '買入數量不能高於',
        success: '提交成功',
        pricetip: '請輸入交易額',
        pricetipwarning: '交易額不能高於',
        sellamounttip: '請輸入賣出量',
        sellamounttipwarning: '賣出數量不能高於',
        sellpricetip: '請輸入賣出價格',
        sellpricetipwarning: '賣出價格不能高於',
        undotip: '是否確認撤單？ ',
        cancelsuccess: "撤單成功！",
        marketprice: '市價',
        expand: {
            time: '時間',
            price: '價格',
            amount: '數量',
            fee: '手續費',
        },
        realtime: '分時',
        kline: "K線圖",
        depth: "深度圖",
        open: "開倉",
        close: "平倉",
        latestdeal: "最新交易",
        myswapaccount: "我的合約賬戶",
        accountmode: "賬戶模式",
        accountmargin: "合約倍數",
        accountquanyi: "賬戶總權益",
        profitandloss: "未實現盈虧",
        principalAmount: "可用保證金",
        positionAmount: "倉位保證金",
        frozenAmount: "凍結保證金",
        principalRate: "資金利用率",
        zijinhuazhuan: "資金劃轉",
        up: " 多",
        down: " 空",
        canup: "可開多",
        candown: "可開空",
        cancloseup: "空倉可平",
        canclosedown: "多倉可平",
        openup: "買入開多",
        opendown: "賣出開空",
        closeup: "買入平空",
        closedown: "賣出平多",
        currentposition: "當前持倉",
        assetsdetail: "資金明細",
        marginMode1: "逐倉",
        marginMode2: "全倉",
        modifyMarginModeTitle: "修改賬戶模式",
        modifyLeverage: "調整合約倍數",
        moredetail: "更多詳情",
        shareNumber: "張",
        entrustprice: "委託價",
        triggleprice: "觸發價",
        openvolume: "開倉數量",
        closevolume: "平倉數量",
        triggleplaceholder: "默認委託價為市價",
        marketpriceplaceholder: "以市場最優價成交",
        levmaxtip: "合約倍數不能高於最高倍數",
        levmintip: "合約倍數不能低於最低倍數",
        marginModeSuccessTip: "合約賬戶模式修改成功",
        noenoughbalance: "賬戶保證金不足",
        pleaseinputopenvolume: "開倉數量必須大於0",
        pleaseinputcorrectopenvolume: "請輸入正確的開倉數量",
        pleaseinputrightentrustprice: "請輸入正確的委託價格",
        noenoughposition: "可平倉位不足",
        pleaseinputclosevolume: "平倉數量必須大於0",
        pleaseinputcorrectclosevolume: "請輸入正確的平倉數量",
        pos_Pl: "收益",
        pos_Ratio: "收益率",
        pos_pos: "持倉量",
        pos_canClose: "可平量",
        pos_openPrice: "開倉均價",
        pos_leverage: "合約倍數",
        pos_margin: "當前保證金",
        pos_mRatio: "保證金率",
        pos_cmRatio: "維持保證金率",
        pos_long: "多頭",
        pos_short: "空頭",
        ent_entrustType: "開倉/平倉",
        ent_direction: "方向",
        ent_type: "委託類型",
        ent_trigglePrice: "觸發價",
        ent_entrustPrice: "委託價",
        ent_tradedPrice: "成交價",
        ent_margin: "保證金",
        ent_volume: "委託數量",
        ent_status: "委託狀態",
        ent_status1: "委託中",
        ent_status2: "已撤銷",
        ent_status3: "委託失敗",
        ent_status4: "委託成功",
        ent_statusblast: "爆倉",
        ent_pl: "結算盈虧",
        ent_fee: "手續費",
        triggerpriceshouldbigger: "觸發價必須大於0"
    },
    ctc: {
        title: "一鍵買賣 ● 平台託管 ● 安全放心",
        desc: "由幣嚴平台託管數字資產，保障用戶資產安全",
        buyin: "買入",
        sell: "賣出",
        buyprice: "買入價",
        sellprice: "賣出價",
        buynum: "買入量",
        sellnum: "賣出量",
        payamount: "結算總額",
        getamount: "結算總額",
        avabalance: "可賣數量",
        time: "交易時間",
        tip: "提示",
        notice: "溫馨提示",
        notice1: "1. 法幣交易區是用戶與承兌商之間資產交易，資金不經轉平台，平台也不接受人民幣充值/匯款；",
        notice2: "2. 法幣交易承兌商均通過實名認證，提供交易保證金，並且代幣均由平台託管，您可以放心兌換；",
        notice3: "3. 承兌商服務時間為每天09:00-21:00，接單後30分鐘內完成承兌，2小時未完成交易作廢；",
        notice4: "4. 法幣交易區支持銀行卡，必須使用本人已實名認證的賬戶進行轉賬；",
        notice5: "5. 為保障交易資金安全，法幣交易要求用戶通過實名認證，請在通過實名認證後，及時設置資金密碼與收款賬戶，否則無法交易；",
        payType: "付款方式",
        receiveType: "收款方式",
        moneyTips: "以上金額僅供參考，請以下單後的實際結算金額為準",
        verifyset: "實名認證/資金密碼",
        payset: "付款/收款設置",
        trade: "交易",
        input50tips: "請輸入50~50000之間的數字",
        bank: "銀行卡",
        alipay: "支付寶",
        wechatpay: "微信",
        payaccountset: "付款賬戶設置",
        useselfaccount: "請務必使用與您本人實名認證相符的收款/付款賬戶",
        orderSn: "訂單號",
        tradetime: "下單時間",
        direction: "交易類型",
        amount: "數量(USDT)",
        money: "總額(CNY)",
        price: "單價(CNY)",
        status: "狀態",
        operate: "操作",
        moredetail: "更多買賣須知"
    },
    cexchange: {
        title: "BZB 活動兌換",
        desc: "BZB平台幣 ● 一鍵兌換 ● 坐享收益",
        quantity: "兌換數量",
        inputtips1: "輸入自定義數量",
        choosecurrency: "選擇兌換幣種",
        all: "全部",
        totalpay: "總兌換金額",
        restricted: "今日限量",
        currentprice: "當前兌換價格",
        nextprice: "下期兌換價格",
        confirmpay: "確認兌換",
        assets: "可用：",
        loginFirst: "請先登錄",
        success: "恭喜你！兌換成功！",
        tip: "提示",
        confirmmsg: "確認"
    },
    option: {
        title: "期權合約",
        buyupreward: "買漲總額",
        buydownreward: "買跌總額",
        quantity: "開倉金額",
        myamount: "我的開倉",
        buyup: "看漲",
        buydown: "看跌",
        history: "往期結果",
        seriers: "第",
        period: "期",
        currentoption: "本期預測週期",
        openprice: "開盤價",
        currentprice: "當前價",
        openprice1: "開盤價格",
        closeprice: "收盤價格",
        opentime: "開盤時間",
        closetime: "收盤時間",
        progress: "預測進度",
        loginFirst: "請先登錄!",
        selectAmount: "請選擇投注數量",
        balancenotenough: "可用餘額不足!",
        betsuccess: "恭喜你！投注成功！",
        noopening: "暫無待期權合約",
        col_createTime: "創建時間",
        col_direction: "預測方向",
        col_result: "預測結果",
        col_optionNo: "合約期數",
        col_betAmount: "開倉金額",
        col_rewardAmount: "獎金數量",
        col_fee: "開倉手續費",
        col_winfee: "抽水",
        win: "成功",
        lose: "失敗",
        wait: "進行中",
        avaliablebalance: "可用餘額",
        login: "請先登錄",
        kline: "實時行情"
    },
    otc: {
        ad: '廣告中心',
        buyin: '買入',
        sellout: '賣出',
        merchant: '商家',
        applymerchant: '申請為認證商家',
        volume: '交易筆數',
        paymethod: '付款方式',
        amount: '數量',
        price_coin: '價格/幣',
        operate: '操作',
        validate: '請先進行實名認證',
        sell: '賣出',
        buy: '買入',
        transaction: '買賣交易',
        myad: {
            title: '我的廣告',
            post: '發布廣告',
            alert: '【溫馨提示】：當廣告最小交易額所購買數量加上手續費大於廣告剩餘數量，該廣告自動下架',
            no: '廣告編號',
            type: '廣告類型',
            sell: '在線出售',
            buy: '在線購買',
            limit: '訂單限額',
            remain: '剩餘數量',
            coin: '幣種',
            created: '創建時間',
            operate: '操作',
            errmsg: '廣告下架後才可編輯修改',
            update: '修改',
            shelf: '上架',
            dropoff: '下架',
            delete: '刪除',
        },
        myorder: '我的訂單',
        chatline: {
            status_1: '買家未付款，等待買家付款！ ',
            status_2: '買家已付款，等待賣家放行！ ',
            status_3: '訂單已完成交易！ ',
            status_4: '訂單正在申訴中！ ',
            status_5: '訂單已取消！ ',
            loadmore: '加載更多',
            warning: '防詐騙提示：近期，屢有詐騙份子利用銀行轉賬匯款信息和假匯款憑據進行詐騙，請一定登陸自己的收款賬號核實。保證匯入資金的安全，避免銀行卡被凍結的風險！ ',
            contenttip: '請輸入聊天內容 回車鍵發送',
            contentmsg: '消息不能為空',
        },
        chat: {
            seller: '賣家',
            buyer: '買家',
            exchangeamount: '交易金額',
            operatetip: '操作提示',
            operatetip_1: '請在規定的時限內按照對方給出的賬號完成支付，並在本頁麵點擊',
            finishpayment: '付款完成',
            operatetip_1_1: '轉賬時請在留言中備註好交易單號',
            operatetip_1_2: '賣方收到款項後會在網站確認收款，系統會自動將您所購買的數字資產發放至您的賬戶，請注意查收',
            note: '注意',
            notetip: '請不要使用其他聊天軟件與對方溝通，更不要接受對方向您發送的任何文件、郵箱附件等，所有溝通環節請都在本頁面的聊天窗口完成',
            operatetip_2_1: '您所出售的數字資產，已交由平台託管凍結。請在確定收到對方付款後，點擊',
            operatetip_2_2: '請不要相信任何催促放幣的理由，確認收到款項後再釋放數字資產，避免造成損失！ ',
            operatetip_2_3: '在收到賬短信後，請務必登錄網上銀行或手機銀行確認款項是否入賬，避免因收到詐騙短信錯誤釋放數字資產！ ',
            confirmrelease: '確認放行',
            paydigital: '支付數字資產',
            orderstatus: '訂單狀態',
            orderstatus_1: '付款完成',
            orderstatus_2: '訂單申訴',
            orderstatus_3: '確認放行',
            orderstatus_4: '取消交易',
            orderstatus_5: '訂單申訴',
            order: '訂單',
            and: '與',
            transition: '的交易',
            transprice: '交易價格',
            transnum: '交易數量',
            transmoney: '交易金額',
            tip1: '用戶暫時未添加銀行卡卡號',
            tip2: '用戶暫時未添加支付寶賬號',
            tip3: '用戶暫時未添加微信賬號',
            zfb: '支付寶',
            wx: '微信',
            qrcode: '二維碼',
            msg1: '您確定已經付款完成嗎',
            msg2: '已付款項並不退還！您確定取消訂單嗎？ ',
            msg3: '【重要】：已付款項並不退還！您確定取消訂單嗎？ ',
            msg4: '已付款，未收到幣',
            msg5: '已付幣，未收到款',
            tip: '提示',
            comptype: '投訴類型',
            compremark: '投訴備註',
            willcomp: '我要投訴',
            msg6: '是否確認放幣？ ',
            result_1: '等待付款',
            result_2: '等待放行',
            result_3: '已完成',
            result_4: '申訴中',
            result_5: '已取消',
            msg7: '資金密碼',
            msg7tip: '請填寫資金密碼',
        },
        checkuser: {
            emaildone: '郵件已認證',
            emailundo: '郵件未認證',
            teldone: '手機號碼已認證',
            telundo: '手機號碼未認證',
            idcarddone: '身份證已認證',
            idcardundo: '身份證未認證',
            language: '語言',
            languagetext: '中文',
            registtime: '註冊時間',
            exchangetimes: '累計交易次數',
            exchangeinfo: '的交易信息',
            tablabel1: '在線出售',
            tablabel2: '在線購買',
            col_symbol: '幣種',
            col_paymode: '付款方式',
            col_num: '數量',
            col_price: '價格',
            col_created: '發佈時間',
            col_operate: '操作',
            operatemsg: '請先進行實名認證',
            buyin: '買入',
            sellout: '賣出',
        },
        tradecenter: {
            postad: '發布廣告',
            exchange: 'GCC交易',
            buyin: '我要買入',
            sellout: '我要賣出',
        },
        tradeinfo: {
            emaildone: '郵件已認證',
            emailundo: '郵件未認證',
            teldone: '手機號碼已認證',
            telundo: '手機號碼未認證',
            idcarddone: '身份證已認證',
            idcardundo: '身份證未認證',
            exchangetimes: '交易次數',
            price: '價格',
            num: '數量',
            paymethod: '付款方式',
            exchangelimitamount: '交易限額',
            location: '所在地',
            location_text: '中國',
            exchangeperiod: '交易期限',
            minute: '分鐘',
            amounttip: '請輸入金額',
            numtip: '請輸入數量',
            remarktip: '告訴他您的要求',
            remarktitle: '備註信息',
            exchangetitle: '交易須知',
            exchange_tip1: '在您發起交易請求後，數字貨幣被鎖定在託管中，受到平台保護。如果您是賣家，發起交易請求後，您可通過充值並等待買家付款。買家在付款時限內進行付款。您在收到付款後應放行處於託管中的數字貨幣。 ',
            exchange_tip2: '交易前請閱讀《平台網絡服務條款》以及常見問題、交易指南等幫助文檔。 ',
            exchange_tip3: '當心騙子！交易前請檢查該用戶收到的評價，並對新近創建的賬戶多加留意。 ',
            exchange_tip4: '請注意，四捨五入和價格的波動可能會影響最終成交的數字貨幣數額。您輸入的固定數額決定最後數額，數字貨幣金額將在請求發布的同一時間由即時的匯率算出。 ',
            exchange_tip5: '託管服務保護網上交易的買賣雙方。在發生爭議的情況下，我們將評估所提供的所有信息，並將託管的數字貨幣放行給其合法所有者。 ',
            warning1: '最多輸入2位小數',
            warning2: '下單金額為',
            warning3: '最多輸入8位小數',
            warning4: '下單數量為',
            confirmbuyin: '確認買入',
            confirmsellout: '確認賣出',
            buyin: '買入',
            sellout: '賣出',
            warning5: '請按要求填寫訂單',
        },
        publishad: {
            createad: '創建一個廣告交易',
            msg1: '如果您經常進行交易，您可以創建自己的交易廣告。如果您只是偶爾交易，我們建議您直接搜索',
            msg2: '創建一則交易廣告是',
            msg3: '免費的',
            msg4: '若您想直接編輯已創建的廣告，請查看',
            tradead: '交易廣告',
            myad: '我的廣告',
            iwant: '我想要',
            sellonline: '在線出售',
            buyonline: '在線購買',
            exchangecoin: '交易幣種',
            country: '國家',
            currency: '貨幣',
            openfixedprice: '開啟固定價格',
            open: '開啟',
            close: '關閉',
            usetip: '啟用後，您的幣價不會隨市場波動，價格不變。 ',
            premiseprice: '溢價',
            premisepricetip: '請設置您的溢價',
            fixedprice: '固定價格',
            fixedpricetip: '請輸入您的交易價格',
            marketprice: '市場參考價格',
            marketpricetip: '溢價是指高於當前市場價格多少百分比進行',
            exchangeprice: '交易價格',
            formual: '計價公式',
            num: '數量',
            num_text1: '請輸入您要',
            num_text2: '的數量',
            exchangeperiod: '交易期限',
            exchangeperiod_text1: '請輸入您的交易期限',
            minute: '分鐘',
            tip1: '可接受買方在多少時間內交易，請輸入整數',
            tip2: '【提示】可前往個人中心綁定/增加支付方式',
            tip3: '請輸入您的最小交易額',
            tip4: '請輸入您的最大交易額',
            tip5: '可以內備註信息裡填寫您的特殊要求，例如：對買方的要求，在線時間等。 ',
            paymode: '付款方式',
            minlimit: '最小交易額',
            maxlimit: '最大交易額',
            remark: '備註信息',
            openautoreply: '開啟自動回复',
            msg5: '啟用後，用戶通過此廣告向您發起交易時，系統自動將您選擇的自動回復用語發送給對方。 ',
            autoreply: '自動回复',
            autoreplytip: '在接收訂單後，自動回复給買家的信息，例如：收款方式、收款賬號等。 ',
            fundpwd: '資金密碼',
            fundpwdtip: '請輸入您的資金密碼',
            submit: '提交',
            warning1: '請輸入正確數字',
            warning2: '溢價值為0-20',
            warning3: '請輸入您的最大交易額！ ',
            warning4: '請輸入整數',
            warning5: '最大交易額必須大於最小交易額！ ',
            warning6: '最大交易額不能超過您的賣出總金額',
            warning7: '請輸入您的最小交易額！ ',
            warning8: '最小交易額必須大於等於100！ ',
            warning9: '最小交易額必須小於最大交易額',
            sellout: '賣出',
            buyin: '買入',
            inputtip1: '請輸入幣種',
            inputtip2: '請輸入正確選項',
            inputtip3: '溢價值為0-20，且不能為0',
            inputtip4: '請輸入正確固定價格',
            inputtip5: '請輸入正確數字，並且最大交易數量不超過100幣',
            inputtip6: '請輸入正確交易限期',
            inputtip7: '請選擇交易方式',
            inputtip8: '請輸入資金密碼',
            zfb: '支付寶',
            wx: '微信',
            unionpay: '銀聯',
            submit_failure: '提交失敗!',
            submit_success: '',
            submittip1: '為了您的資金安全，請先進行【實名認證】！ ',
            submittip2: '為了您的資金安全，請先進行【手機綁定】！ ',
            submittip3: '為了您的資金安全，請先設置【資金密碼】！ ',
            submittip4: '請至少綁定一種支付方式',
        },
        index: {
            title: '優質廣告推薦',
            exchangetimes: '交易次數',
            exchangeprice: '交易價格',
            exchangelimit: '交易限額',
            paymode: '付款方式',
            buy: '購買',
            sell: '賣出',
            viewmore: '查看更多',
            bot1: '安全可靠',
            bot1_tip: '超過10年金融風控經驗團隊全方為\n可定制的安全策略體系',
            bot2: '快速便捷',
            bot2_tip: '點對點的用戶自由交易模式支持多\n種資金渠道的兌換方式',
            bot3: '幣種全面',
            bot3_tip: '甄選主流數字資產交易幣種滿足多\n方面的多資產交易體驗',
            ibuy: '我要買入',
            isell: '我要賣出',
        }
    },
    activity: {
        all: "全部",
        prepare: "即將開始",
        ongoing: "進行中",
        distributing: "派發中",
        completed: "已完成",
        progress: "進度",
        totalsupply: "活動總量",
        starttime: "開始時間",
        endtime: "結束時間",
        viewdetail: "查看詳情",
        attend: "立即參與",
        tokendistribute: "Token派發中",
        baseinfo: "基本信息",
        status: "狀態",
        activitytype: "認購類型",
        activitytype0: "未知",
        activitytype1: "首次上線(搶購)",
        activitytype2: "首次上線(平分)",
        activitytype3: "持倉瓜分",
        activitytype4: "自由認購",
        activitytype5: "雲礦機認購",
        activitytype6: "鎖倉釋放",
        ruledetail: "類型說明",
        publishprice: "認購價格",
        activitycoin: "活動幣種",
        acceptcoin: "接受幣種",
        holdcoin: "持倉要求幣種",
        limittimes: "個人限購次數",
        limitamount: "個人限購數量",
        unlimited: "不限",
        alreadyamount: "已購數量",
        alreadyholdamount: "活動參與總持倉",
        myalreadyholdamount: "我的參與持倉",
        leftamount: "剩餘數量",
        attention: "注意",
        attentiontxt1: "1、提交參與【持倉瓜分】、【自由認購】、【雲礦機認購】、【鎖倉】類型活動後將凍結資產，活動結束後解凍或解鎖釋放",
        attentiontxt2: "2、參與【持倉瓜分】、【自由認購】、【雲礦機認購】、【鎖倉】類型活動後，用戶不得撤銷參與申請",
        attentiontxt3: "3、如因項目方及其他不可抗力因素取消活動，將原路退回凍結資產",
        attentiontxt4: "* 本活動最終解釋權歸幣嚴(WWW.COIN.PRO)官方所有",
        inputamount: "輸入兌換數量",
        inputholdamount: "輸入鎖倉數量",
        mybalance: "我的可用餘額",
        holdtips: "參與【持倉瓜分】類型活動需鎖倉至活動結束",
        pleaseinputamount: "請輸入兌換數量",
        pleaseinputholdamount: "請輸入鎖倉數量",
        commitfailed: "提交參與失敗！",
        minlimitamountfailed: "兌換數量不能小於最低起兌量！",
        maxlimitamountfailed: "兌換數量不能大於最高限購量！",
        limittimesfailed: "您參與的次數超過個人最高限購次數！",
        balancenotenough: "餘額不足！",
        headertitledesc: "投資者、項目方、交易所等多方共贏的開放實驗室",
        currentdivided: "當前我的持倉可瓜分約",
        leveloneCount: "需要一級好友數",
        submit: "提交",
        tipsmobile: "請進入電腦端頁面參與（www.coin.pro）",
        tipsmobile1: "請在電腦端打開（www.coin.pro）並進入創新實驗區參與",
        intvalue: "請輸入整數",
        inputminingamount: "輸入購買礦機數",
        moreactivity: "更多其他活動",
        releaseType: "釋放類型",
        releaseType0: "等額釋放",
        releaseType1: "等比釋放",
        lock: " 期釋放",
        releaseDetail: "釋放詳情",
        lockday: " 日",
        lockweek: " 週",
        lockmonth: " 月",
        lockyear: " 年",
        releasePercent: "釋放比例",
        lockFee: "鎖倉門檻",
        releaseTimes: "釋放倍數",
        times: "倍"
    },
    invite: {
        myinvitelink: "我的邀請鏈接",
        copy: "複製",
        copyed: "已復制",
        mylevelone: "我的一級好友",
        myleveltwo: "我的二級好友",
        mycommission: "佣金收入(折合USDT)",
        extrareward: "額外獎勵",
        partnerlevel: "合夥級別",
        ruledetail: "規則詳情",
        ruleprofile1: "幣嚴(WWW.COIN.PRO)的【推廣合夥人】是全網",
        ruleprofile2: "返佣比例最高、",
        ruleprofile3: "返佣時長最長(最高終身返佣)",
        ruleprofile4: "的推廣活動，讓付出相應努力的推廣者能夠成為真正意義上的平台“合夥人”，享受與幣嚴(WWW.COIN.PRO)交易平台共同成長的紅利！具體細則如下：",
        ruletext1: "一、為佈道數字資產理念與擴大用戶規模，幣嚴(WWW.COIN.PRO)推出【推廣合夥人】計劃，該活動長期有效。",
        ruletext2: "二、邀請好友分為兩級，如A邀請B，則B是A的一級好友，若B又邀請了C，則C屬於B的一級好友，同時也屬於A的二級好友。",
        ruletext3: "三、推廣好友註冊時，需通過邀請者提供的鏈接或手動輸入邀請者邀請碼方可成為邀請者的一級好友。",
        ruletext4: "四、被邀請者通過邀請者邀請碼註冊，並且完成實名認證後，返佣激勵方可生效。",
        ruletext5: "五、返佣時間為從被邀請者完成實名認證開始的N個月，根據等級不同，享受的返佣比例不同。",
        ruletext6: "六、推廣返佣資產為下級好友幣幣交易手續費，返佣幣為三大市場基礎幣，即：USDT、BTC、ETH。",
        ruletext7: "七、推廣返佣比例、返佣時長與推廣一級好友人數呈正相關，推廣人數越多，可享返佣比例越高。具體推廣返佣比例如下：",
        ruletext8: "八、推廣返佣排行榜TOP10或TOP100不定期會獲得由幣嚴(WWW.COIN.PRO)官方發放的額外獎勵。",
        ruletext9: "九、如其他活動與【推廣合夥人】活動衝突，則經協商後決定是否臨時變更，望諸合夥人諒解。",
        ruletext10: "十、L4(巡撫)、L5(太守)、L6(節度使)級合夥人分別享受每年返佣總額的5%、10%、15%的分紅獎勵。",
        ruletext11: "十一、本活動最終解釋權歸幣嚴(WWW.COIN.PRO)所有。",
        ruleexampletitle: "舉例說明：",
        ruleexamplecontent1: "若用戶小嚴邀請了100名一級好友，TA的一級好友分別邀請了5名二級好友，此時小嚴就擁有100名一級好友，500名二級好友。如果平均每人每天交易3000元，那麼小嚴月收入約：(100*3000*0.001*30% + 500*3000*0.001*10%) * 30 = 7200/月。",
        ruleexamplecontent2: "若用戶小嚴邀請了1000名一級好友，TA的一級好友分別邀請了5名二級好友，此時小嚴就擁有1000名一級好友，5000名二級好友。如果平均每人每天交易3000元，那麼小嚴月收入約：(1000*3000*0.001*50% + 5000*3000*0.001*20%) * 30 = 135000/月。",
        lastupdate: "最後更新於",
        laststastic: "統計截止時間",
        level: "級別",
        lv1: "裡正",
        lv2: "縣令",
        lv3: "知府",
        lv4: "巡撫",
        lv5: "太守",
        lv6: "節度使",
        levelonecount: "一級好友數",
        levelonecommission: "一級好友返佣比例 / 返佣時間",
        leveltwocommission: "二級好友返佣比例 / 返佣時間",
        partnerdivide: "合夥人分紅",
        month: "個月",
        alllife: "終身返佣",
        totalcommissionrank: "推廣返佣總額",
        rank: "排名",
        userid: "用戶ID",
        rankday: "(日榜)",
        rankweek: "(周榜)",
        rankmonth: "(月榜)",
        rankall: "(總榜)",
        promotioncount: "推廣人數",
        commissionusdt: "返佣(折合USDT)",
        ranktip1: "邀請人數為一級好友數，此榜為返佣總金額排名",
        ranktip2: "邀請人數為一級好友數，此榜為一級邀請人數排名",
        thanks: "感謝您陪著幣嚴(WWW.COIN.PRO)一起成長！",
        headertip: "以上佣金數據非實時更新，系統每24H統計並更新一次。",
        ptools: "推廣工具箱",
        pt_title: "數字貨幣禮品卡",
        pt_desc: "兌換後自動成為下級好友，送朋友、送親人、送客戶、送夥伴。助力推廣合夥人更好推廣。",
        pt_more: "更多工具，敬請期待",
        pt_tips: "如果您有好的創意，請發送郵件至promotion@coin.pro，採納後有獎勵哦！",
        pt_card_amount: "卡面金額",
        pt_card_deadline: "有效期間",
        pt_card_noend: "無限期",
        pt_card_btn: "推廣合夥人免費領30張(約2000元)",
        pt_card_btn_login: "登錄後免費領取30張",
        pt_card_rule: "規則詳情",
        pt_card_summary: "數字貨幣禮品卡是為了讓推廣合夥人能夠更好地邀請下線好友而開發的推廣工具之一，推廣對像用戶兌換禮品卡時，會自動成為推廣者的下線好友。",
        pt_card_rule1: "一、免費禮品卡每個用戶限領30張。如需發放更多禮品卡，則需要用戶付費定制，可定制卡面金額、LOGO、說明文等。定制需求請發送郵件至",
        pt_card_rule2: "二、免費禮品卡每個用戶限兌1份。由幣嚴(WWW.COIN.PRO)官方發放的免費禮品卡，每個用戶限兌一份，即使通過不同用戶領取到不同兌換碼的免費禮品卡，也只能兌換1份。自定義製作禮品卡不受此限制。",
        pt_card_rule3: "三、用戶兌換禮品卡前，如不存在“邀請者”（即註冊賬號時未輸入過邀請碼），則自動關聯爲發卡者的一級好友。如A註冊賬號時未輸入邀請碼，此時A不屬於任何人的一級或者二級好友，但當A通過B發放的禮品卡兌換後，那麼A自動成為B的一級好友。",
        pt_card_rule4: "四、為防止刷客，禮品卡賬面金額並非實時兌付，領取後需完成實名認證才可操作。",
        pt_card_rule5: "五、領取免費禮品卡時需要完成實名認證，兌換時無需完成實名認證即可領取，此舉是為了方便推廣者更快獲取下級好友。",
        pt_card_rule6: "六、免費禮品卡最終解釋權歸COIN.PRO所有。",
        pt_card_day: "天",
        pt_card_title_tips: "領2000元推廣助力金",
        pt_card_receivew_success: "恭喜！成功領取合夥人推廣禮品卡！請到【個人中心】->【卡券中心】查看！",
        usepromotion: "使用推廣素材",
        saveimage: "保存圖片",
        imagetips: "如無法正常下載圖片，請使用截圖工具截圖保存！",
        pt_invite_title: "邀請註冊宣傳圖",
        pt_invite_desc: "專屬二維碼，通過二維碼註冊自動成為下級好友，適合在各種群或論壇轉發。",
        colum_text0: "排名",
        colum_text1: "會員",
        colum_text2: "推廣人數",
        colum_text3: "返佣(折合USDT)",
        colum_text4: "額外獎勵",
		context_title: "推廣合夥人專屬兌換碼",
		context_title1: "掃一掃了解更多",
		context_title2: "我的邀請碼",
		context_title3: "邀請圖片"
    },
    uc: {
        verify: "實名認證",
        login: {
            noaccount: '沒有賬號？ ',
            register: '註冊',
            login: '登錄',
            welcomelogin: '歡迎登錄',
            usertip: '請輸入手機號或郵箱',
            pwdtip: '請輸入密碼',
            validatecodeload: '正在加載驗證碼',
            validatemsg: '請先完成驗證',
            forget: '忘記密碼?',
            loginvalidate: '請輸入賬號',
            pwdvalidate1: '請輸入密碼',
            pwdvalidate2: '密碼長度不能少於6位',
            success: '登錄成功',
            goregister: "立即註冊",
            getlostpwd: "忘記密碼"
        },
        forget: {
            title: "找回密碼",
            hasaccount: '已有賬號？請點擊登錄',
            login: '登錄',
            sendcode: '發送驗證碼',
            newpwd: '請輸入新密碼',
            confirmpwd: '請再次確認密碼',
            save: '提交',
            pwdvalidate1: '請輸入確認密碼',
            pwdvalidate2: '兩次密碼輸入不一致!',
            resettelpwd: '重置手機密碼',
            resetemailpwd: '重置郵箱密碼',
            newpwdtip: '請輸入新密碼',
            pwdvalidate3: '密碼長度不能少於6位',
            telno: '手機號',
            smscode: '請輸入驗證碼',
            teltip: '請輸入手機號',
            smscodetip: '請輸入短信驗證碼',
            email: '郵箱',
            emailcode: '郵箱驗證碼',
            emailtip: '請輸入郵箱',
            emailcodetip: '請輸入郵箱驗證碼',
            resetpwdsuccess: '重置密碼成功',
            smswarn: '請注意查收短信',
        },
        finance: {
            center: '財務中心',
            personalassets: '幣幣賬戶',
            swapassets: '永續賬戶',
            billdetail: '資產流水',
            tradetail: '交易挖礦',
            paydividends: '持幣分紅',
            invitingmining: '邀請挖礦獎勵',
            charge: '充幣',
            pickup: '提幣',
            money: {
                cointype: '幣種名稱',
                balance: '可用資產',
                frozen: '凍結資產',
                operate: '操作',
                charge: '充幣',
                pickup: '提幣',
                onkeytrans: "一鍵轉",
                getaddress: '獲取地址',
                resetsuccess: '獲取成功',
                match: '配對',
                matchtip1: '可配對GCX的數量',
                matchtip2: '請輸入配對數量',
                matcherr1: '請輸入有效的數量!',
                matcherr2: '超出最大配對數量!',
                matchsuccess: '配對成功!',
                needreleased: "待釋放資產",
                totalassets: "總資產折合："
            },
            swap: {
                totalassets: "賬戶權益折合：",
                swap: " 永續賬戶",
                swaptype: '合約賬戶',
                swapassets: "賬戶權益",
                avabalance: "可用保證金",
                usebalance: "已用保證金",
                frozenbalance: "凍結保證金",
                profitandloss: "未實現盈虧",
                transfor: "資金劃轉",
                inputtransferamount: "劃轉數量",
                all: "全部劃轉",
                currencyaccount: "幣幣賬戶",
                swapaccount: "永續合約賬戶",
                clickchange: "點擊切換劃轉方向",
                avaamount: "可轉額度",
                pleaseselectwallet: "請先選擇轉入/轉出賬戶",
                pleaseinputamount: "請輸入劃轉數量",
                oktransfer: "確認劃轉"
            },
            record: {
                start_end: '起止時間',
                to: '至',
                operatetype: '操作類型',
                search: '搜索',
                charge: '充值',
                pickup: '提現',
                transaccount: '轉賬',
                exchange: '幣幣交易',
                otcbuy: '法幣買入',
                otcsell: '法幣賣出',
                activityaward: '活動獎勵',
                promotionaward: '推廣獎勵',
                dividend: '分紅',
                vote: '投票',
                handrecharge: '人工充值',
                match: '配對',
                activitybuy: "活動兌換",
                ctcbuy: "CTC買入",
                ctcsell: "CTC賣出",
                redout: "發紅包",
                redin: "領紅包",
                chargetime: '交易時間',
                type: '類型',
                fee: '手續費',
                shouldfee: '應付手續費',
                discountfee: '抵扣手續費',
                realfee: '實付手續費',
                symbol: '幣種',
                num: '數量',
                status: '狀態',
                finish: '已完成',
                activitybuy: "活動兌換",
                ctcbuy: "C2C買入",
                ctcsell: "C2C賣出",
                quickExchange: "閃兌",
                currentRate: "當前兌換比率",
                from: "從",
                inputexchangeamount: "輸入閃兌數量",
                inputexchangepasswd: "輸入交易密碼",
                chooseTransCoinUnit: "選擇轉化幣種",
                inputTransAmount: "輸入轉化數量",
                predictAmount: "預計可兌數量",
                qamount: "閃兌數量",
                qrate: "兌換比例",
                qexamount: "兌換數量",
                withdrawcodein: "提幣碼充幣",
                withdrawcodeout: "提幣碼提幣",
                contractfee: "永續合約手續費",
                contractprofit: "永續合約盈利",
                contractloss: "永續合約虧損",
                optionfail: "預測合約失敗",
                optionfee: "預測合約手續費",
                optionreward: "預測合約獎金",
                other: "其他"
            },
            paydividende: {
                money_holding: '持幣分紅已返還累計(ETH) : ',
                money_hold: '持幣分紅待返還累計(ETH) : ',
                paydividends: '持幣分紅(ETH)',
                account_date: '到帳時間',
                datehodld: '持幣日期',
                start_end: '起止時間',
                to: '至',
                operatetype: '操作類型',
                search: '搜索',
                charge: '充值',
                pickup: '提現',
                transaccount: '轉賬',
                exchange: '幣幣交易',
                otcbuy: '法幣買入',
                otcsell: '法幣賣出',
                activityaward: '活動獎勵',
                promotionaward: '推廣獎勵',
                dividend: '分紅',
                vote: '投票',
                handrecharge: '人工充值',
                match: '配對',
                chargetime: '交易時間',
                type: '類型',
                fee: '手續費',
                symbol: '幣種',
                num: '數量',
                status: '狀態',
                finish: '已完成',
            },
            trade: {
                accumulative_return: '累計挖礦(BHB) : ',
                accumulat_return: '待挖礦(BHB) : ',
                start_end: '起止時間',
                account_date: '到帳時間',
                to: '至',
                operatetype: '操作類型',
                search: '搜索',
                charge: '充值',
                pickup: '提現',
                transaccount: '轉賬',
                exchange: '幣幣交易',
                otcbuy: '法幣買入',
                otcsell: '法幣賣出',
                activityaward: '活動獎勵',
                promotionaward: '推廣獎勵',
                dividend: '分紅',
                vote: '投票',
                handrecharge: '人工充值',
                match: '配對',
                chargetime: '訂單生成時間',
                type: '類型',
                fee: '挖礦手續費返還(BHB)',
                symbol: '幣種',
                num: '數量',
                status: '狀態',
                finish: '已完成',
                transactionTime: '交易時間',
                symbol: '交易對',
                direction: '交易方向',
                price: '價格',
                entrustment: '委託量',
                havedeal: '已成交',
                poundageAmount: '手續費',
                exchangeOrderId: "訂單ID",
                mineAmount: "挖幣數量 (BHB)",
                buy: "買入",
                sell: "賣出",
                limit: "限價",
                market: "市價",
                clear: "清空條件",
                turnover: "成交金額"
            },
            inviting: {
                accumulative_return: '邀請挖礦獎勵已返還累計(BHB) : ',
                accumulat_return: '邀請挖礦獎勵待返還累計(BHB) : ',
                start_end: '起止時間',
                account_date: '到帳時間',
                to: '至',
                operatetype: '操作類型',
                search: '搜索',
                charge: '充值',
                pickup: '提現',
                transaccount: '轉賬',
                exchange: '幣幣交易',
                otcbuy: '法幣買入',
                otcsell: '法幣賣出',
                activityaward: '活動獎勵',
                promotionaward: '推廣獎勵',
                dividend: '分紅',
                vote: '投票',
                handrecharge: '人工充值',
                match: '配對',
                chargetime: '訂單生成時間',
                type: '類型',
                fee: '挖礦手續費返還(BHB)',
                symbol: '幣種',
                num: '數量',
                status: '狀態',
                finish: '已完成',
                refereename: '被推薦人姓名',
                referetel: '被推薦人手機號',
                invitingawards: '邀請挖礦獎勵(BHB)',
                refere: '被推薦人',
                refereinput: '請輸入姓名/手機號'
            },
            recharge: {
                recharge: '充幣',
                symbol: '幣種',
                address: '充幣地址',
                copy: '複製',
                qrcode: '二維碼',
                qrcodeaddress: '充值地址二維碼',
                msg1: '請勿向上述地址充值任何非幣種資產，否則資產將不可找回。 ',
                msg2: '您充值至上述地址後，需要整個區塊鍊網絡節點的確認，一般區塊鍊主網3次網絡確認後到賬。 ',
                msg3: '最小充值金額：',
                msg3_1: "，小於最小金額的充值將不會上賬。",
                msg4: '您的充值地址不會經常改變，可以重複充值；如有更改，我們會盡量通過網站公告或郵件通知您。 ',
                msg5: '請務必確認電腦及瀏覽器安全，防止信息被篡改或洩露。 ',
                record: '充值記錄',
                copysuccess: '複製成功！ ',
                copyerr: '複製失敗！請手動複製',
                time: '到賬時間',
                amount: '充值數量',
                gettingaddress: "獲取充值地址中，請稍候...",
                getaddress: "獲取充值地址",
                memotips: "充值時請按要求輸入此Memo，否則會丟失資產。",
                withdrawrecharge: "提幣碼充值",
                coderechargetip: "請輸入提幣碼",
                rechargesuccess: "恭喜！提幣碼充值成功！",
                rechargeconfirm: "充值確認",
                status: "狀態"
            },
            withdraw: {
                pickup: '提幣',
                addressmanager: '提幣地址管理',
                withdrawbycode: '提幣碼提幣',
                withdrawcode: '提幣碼',
                symbol: '幣種',
                address: '提幣地址',
                num: '提幣數量',
                avabalance: '可用餘額',
                msg1: '提幣數量低於',
                msg2: '時自動到賬，否則需要人工審核',
                increase: '提升額度',
                tip1: '最多輸入',
                tip11: '位小數,最小值為',
                tip2: '最大值為',
                numtip1: '輸入提幣數量',
                fee: '手續費',
                range: '範圍',
                arriamount: '到賬數量',
                msg3: '最小提幣數量為',
                msg4: '幣',
                msg5: '為保障資金安全，當您賬戶安全策略變更、密碼修改、使用新地址提幣，我們會對提幣進行人工審核，請耐心等待工作人員電話或郵件聯繫',
                msg6: '請務必確認電腦及瀏覽器安全，防止信息被篡改或洩露。 ',
                record: '提現記錄',
                symboltip: '請選擇幣種',
                addresstip: '請填入地址',
                numtip2: '請填寫正確提幣數量，最小值為',
                numtip3: '提幣數量不得小於手續費',
                amounttip: '請輸入提幣數量',
                feetip1: '手續費最小值為',
                feetip2: '最大值為',
                time: '提現時間',
                status: '狀態',
                status_1: '審核中',
                status_2: '轉賬中',
                status_3: '失敗',
                status_4: '成功',
                remark: '備註',
                add: '添加',
                addresslist: '地址列表',
                safevalidate: '安全驗證',
                telno: '手機號碼',
                smscode: '手機驗證碼',
                second: '秒',
                clickget: '點擊獲取',
                email: '郵箱',
                emailcode: '郵箱驗證碼',
                save: '保 存',
                delete: '刪除',
                telerr: '手機號不正確',
                emailerr: '郵箱不正確',
                codeerr: '驗證碼不正確',
                remarktip: '請輸入備註',
                savemsg1: '保存失敗!',
                savemsg2: '保存成功!',
                operate: '操作',
                fundpwdtip: '請輸入資金密碼',
                click: '點擊',
                filtrate: '可篩選',
                txid: "TxId",
                generateCode: "生成提幣碼",
                withdrawcodelist: "提幣碼列表",
                withdrawcodestatus: "狀態",
                submit: "提交",
                generatecodesuccess: "提現碼生成成功"
            }
        },
        member: {
            usercenter: "個人中心",
            securitycenter: '安全中心',
            securitysetting: '安全設置',
            accountsetting: '收款方式',
            account: "賬戶管理",
            assets: "資金管理",
            exchange: "交易管理",
            curdelegate: "當前委託",
            hisdelegate: "歷史委託",
            otc: "OTC管理"
        },
        order: {
            ordercenter: '訂單中心',
            myorder: '我的訂單',
            myad: '我的廣告',
        },
        regist: {
            hasaccount: '已有賬號？請點擊登錄',
            login: '登錄',
            username: '用戶名',
            country: '國家',
            smscode: '短信驗證碼',
            sendcode: '發送驗證碼',
            resendcode: '重新發送(',
            pwd: '登錄密碼',
            repwd: '確認密碼',
            confrimpwd: '確認密碼',
            agreement: '我已閱讀並同意',
            userprotocol: '用戶協議',
            regist: '註冊',
            teltip: '請輸入手機號',
            telerr: '手機號碼格式不正確,請重新輸入',
            emailtip: '請輸入郵箱',
            emailerr: '郵箱格式不正確,請重新輸入',
            confirmpwdtip: '請輸入確認密碼',
            confirmpwderr: '兩次密碼輸入不一致!',
            telregist: '手機註冊',
            emailregist: '郵箱註冊',
            usernametip: '請輸入用戶名',
            usernamemsg: '用戶名長度不能少於3位,多於15位',
            countrytip: '請選擇國家',
            smscodetip: '請輸入短信驗證碼',
            pwdtip: '請輸入登錄密碼',
            pwdmsg: '密碼長度不能少於6位',
            telno: '手機號碼',
            email: '郵箱地址',
            agreementtip: '請點擊同意',
            modaltitle: '請校驗',
            promotion: '邀請碼(選填)',
			china: '中國',
            singapore: '新加坡',
            korea: '韓國',
            japan: '日本',
            thailand: '泰國',
            russia: '俄羅斯',
            uk: '英國',
            vietnam: '越南',
            india: '印度',
            italy: '意大利',
            hk: '香港',
            malaysia: '馬來西亞',
            taiwan: '台灣省',
            turkey: '土耳其',
            germany: '德國',
            france: '法國',
            spain: '西班牙'
        },
        safe: {
            safelevel_low: '安全等級：低',
            safelevel_high: '安全等級：高',
            safelevel_medium: '安全等級：中',
            nickname: '暱稱',
            bind: '綁定',
            binded: '已綁定',
            binding: '審核中',
            binderr: '失敗',
            bindretry: '重試',
            verifypass: "已認證",
            verified: '實名認證',
            notverified: "未認證",
            verifiedtip: '為保障您的賬戶安全，請完成實名認證後方可交易操作！ ',
            realname: '真實姓名',
            idcard: '身份證號',
            upload: '點擊上傳',
            upload_positive: '身份證正面照',
            upload_negative: '身份證反面照',
            upload_hand: '手持身份證與個人簽名照',
            save: '保 存',
            reset: '重置',
            email: '郵箱',
            bindemail: '綁定郵箱',
            loginpwd: '登錄密碼',
            emailcode: '郵箱驗證碼',
            clickget: '點擊獲取',
            second: '秒',
            phone: '手機',
            bindphone: '綁定手機',
            phonecode: '手機驗證碼',
            logintip: '登錄平台時使用',
            edit: '修改',
            oldpwd: '原登錄密碼',
            newpwd: '新登錄密碼',
            confirmnewpwd: '確認新密碼',
            fundpwd: '資金密碼',
            fundtip: '賬戶資金變動時，需先驗證資金密碼',
            set: '設置',
            confirmpwd: '確認密碼',
            oldfundpwd: '原資金密碼',
            newfundpwd: '新資金密碼',
            newpwdmsg1: '請輸入不小於6位新登錄密碼',
            newpwdmsg2: '新登錄密碼不一致',
            pwdmsg1: '請輸入不小於6位密碼',
            pwdmsg2: '密碼不一致',
            emailtip: '請輸入郵箱號',
            codetip: '請輸入驗證碼',
            telnotip: '請輸入手機號',
            oldpwdtip: '請輸入原密碼',
            realnametip: '請輸入真實姓名',
            idcardtip: '請輸入身份證號碼',
            bindphonetip: '請先綁定手機!',
            resetfundpwd: '重置資金密碼',
            upload_positivetip: '請上傳身份證正面照',
            upload_negativetip: '請上傳身份證反面照',
            upload_handtip: '請上傳手持身份證照',
            save_success: '保存成功!',
            save_failure: '保存失敗!',
            idcard_verifymsg1: "1、支持JPG、PNG格式，文件小於8M；證件上的信息，清晰可見，不允許任何修改和遮擋，必須能看清證件號和姓名。",
            idcard_verifymsg2: "2、照片需免冠，建議未化妝，手持證件人的五官清晰可鑑，完整露出手臂。",
            idcard_verifymsg3: "3、需要同時手持證件和手寫文案（姓名+申請日期）'我在本站的所有行為均為本人操作，並已知悉相關風險，願意承擔本賬戶的一切法律後果。'"
        },
        account: {
            pagetitle: '綁定實名帳號',
            pagetip: '請設置您的收款方式，請務必使用本人的實名賬號',
            backcardno: '銀行卡賬號',
            backcardtip: '個人銀行卡信息未綁定',
            modify: '修改',
            bind: '綁定',
            name: '姓 名',
            bankaccount: '開戶銀行',
            bankbranch: '開戶支行',
            bankno: '銀行卡號',
            confirmbankno: '確認卡號',
            fundpwd: '資金密碼',
            save: '保 存',
            zfbaccount: '支付寶賬號',
            zfbaccounttip: '個人支付寶賬戶未綁定',
            wxaccount: '微信賬號',
            wxaccounttip: '個人微信賬戶未綁定',
            banknomsg1: '請輸入正確銀行卡號',
            banknomsg2: '兩次輸入的銀行卡號不一致!',
            verifiedmsg: '請先進行實名認證',
            bankaccountmsg: '請選擇開戶銀行',
            bankbranchmsg: '請輸入開戶支行',
            banknomsg: '請輸入正確的銀行卡號',
            fundpwdmsg1: '請輸入正確的資金密碼',
            fundpwdmsg2: '密碼不得少於6個字符',
            zfbaccountmsg: '請輸入支付寶賬號',
            wxaccountmsg: '請輸入微信賬號',
            save_success: '保存成功!',
            save_failure: '保存失敗!',
            imgtip: '請上傳您的收款碼',
        },
        otcorder: {
            unpaid: '未付款',
            paided: '已付款',
            finished: '已完成',
            canceled: '已取消',
            appealing: '申訴中',
            searchtip: '輸入訂單編號開始搜索',
            orderno: '訂單號',
            created: '創建時間',
            symbol: '交易幣種',
            type: '交易類型',
            type_sell: '賣出',
            type_buy: '買入',
            tradename: '交易對象',
            amount: '數量',
            money: '金額',
            fee: '手續費',
        },
        identity: {
            certified: "已認證",
            placeholder: "請填寫取消原因",
            apply: '申請成為商家',
            become: '成為CoinPro認證商家， 享更多交易特權',
            zhusnhu: "商家享有專屬廣告展位，增加交易成功率",
            tijiaoziliao: "提交商家認證資料",
            place: "請您將準備好的商家認證資料上傳至平台並提交",
            tijiao: "您的商家認證審核已提交",
            tijiaosuc: "恭喜！您的商家認證審核已通過",
            tijiaofail: "抱歉！您的商家認證審核未通過",
            zhuxiaotijiao: "您的商家註銷申請已提交",
            shenhefail: "您的商家註銷申請審核未通過",
            shenhesuc: "您的商家註銷申請審核已通過",
            shangjiazhuxiao: "商家註銷",
            shenheshibai: "審核失敗",
            shenagain: "重新審核",
            sheqinggain: "重新申請",
            reason: "原因",
            shenqingtuibao: "申請退保",
            getquan: "您獲得以下權限",
            read: "我已閱讀並同意",
            lijishenqing: "立即申請",
            tips: "提示",
            wufachexiao: "您正在進行商家註銷操作，確認提交申請操作後，將無法撤銷。",
            suredo: "是否確認執行此操作？",
            shuzizichan: "數字資產交易證明",
            gerenzichan: "個人數字資產證明",
            second: {
                line: "如何申請成為商家?",
                step1: "第一步：按要求準備商家申請資料",
                step1c1: "準備如下申請資料：",
                step1c2: "手機號、微信號、QQ號、個人數字資產證明(圖片)、數字資產交易證明(圖片)",
                step2: "第二步：提交申請",
                step2c: "完成需要填寫和上傳的商家認證審核資料，點擊提交審核。",
                step3: "第三步：資料審核",
                stepc: "我們將在3-5個工作日內對您的商家申請資料進行審核，請隨時關注審核狀態，可在提交頁面查看。審核通過後，您即可在法幣交易區發布廣告。",
                agree: "同意凍結",
                agreec: "作為商家保證金",
                shenqingchngweishangjia: "申請成為商家",
            },
            yuanyin: "原因",
            tijiaoshenqing: "提交申請",
            bizhong: "保證金幣種",
            shuliang: "保證金數量",
            chosen: "選擇幣種",
            seat: '專屬展位',
            service: '一對一服務',
            lowfee: '更低手續費',
            phone: '手機',
            balance: '個人資產情況',
            cardphoto: '身份證正反面照片',
            wx: '微信',
            exchange: '是否從事過數字資產的場外交易',
            handphoto: '用戶手持身份證照片',
            qq: 'QQ',
            ploy: '是否有相應的風控策略',
            agreement: '《認證商家協議》',
            applyfor: '確認申請',
            sendcode: '發送驗證碼',
            confirm: '確定',
            prepare: '準備資料',
            review: '提交審核',
            result: "等待結果",
            passed: '審核通過',
            approve: '請同意認證商家協議',
            emailtip1: '請將如下材料用郵件發送至',
            emailtip2: '我們將盡快對您的申請進行審核。 ',
        },
        extension: {
            title1: '推廣鏈接',
            title2: '推廣好友',
            title3: '我的佣金',
            linkdesc: '以下網址是您對外界進行推廣的地址，您可以通過朋友、QQ、微信、微博、博客、論壇或者自己的網站進行推廣，所有通過該地址訪問過來的人，註冊後就都屬於您的用戶，而當這些用戶在本站提交策略時，您就可以賺取佣金了，詳細的推廣情況可到訪問記錄裡查看。 ',
            linktitle: '您的推廣鏈接',
            copy: '複製',
            copy_msg1: '複製成功！ ',
            copy_msg2: '複製失敗！請手動複製',
            username: '用戶名',
            currrecharge: '當前交易',
            userlevel: '推薦級別',
            createdtime: '註冊時間',
            currcommission: '當前佣金',
            managerfee: '管理費',
            yuan: '元',
            symbol: '幣種',
            amount: '金額',
            remark: '備註',
            amounttime: '發放時間',
        },
        promotion: {
            title: "我的推廣",
            subtitle1: "我的推廣卡",
            subtitle2: "兌換記錄",
            subtitle3: "我的邀請",
            card_column0: "兌換碼",
            card_column1: "卡名",
            card_column2: "卡面幣種",
            card_column3: "卡面金額",
            card_column4: "有效期",
            card_column5: "總數",
            card_column6: "已兌",
            card_column7: "領取時間",
            my_column0: "邀請用戶",
            my_column1: "註冊時間",
            my_column2: "實名狀態",
            gopromotion: "推廣素材",
            inputcardno: "請輸入兌換碼",
            exchange: "立即兌換",
            exchangesuccess: "兌換成功！請到資產管理查看餘額！",
            exchangewithcode: "兌換碼兌換"
        },
        activity: {
            navTitle: "創新管理",
            subNavTitle1: "我參與的",
            subNavTitle2: "我的礦機",
            subNavTitle3: "我的鎖倉",
            column1: "名稱",
            column2: "類型",
            column3: "認購數量",
            column4: "參與幣種",
            column5: "認購單位",
            column6: "當前狀態",
            column7: "成交額",
            column8: "創建時間",
            mColumn1: "礦機",
            mColumn2: "名稱",
            mColumn3: "幣種",
            mColumn4: "週期",
            mColumn5: "天數",
            mColumn6: "已挖",
            mColumn7: "狀態",
            mColumn8: "基礎產能",
            mColumn9: "當前產能",
            mColumn10: "總產出",
            mColumn11: "結束日期",
            mColumn12: "創建日期",
            lColumn1: "鎖倉",
            lColumn2: "名稱",
            lColumn3: "幣種",
            lColumn4: "週期",
            lColumn5: "天數",
            lColumn6: "已釋放",
            lColumn7: "狀態",
            lColumn8: "基礎產能",
            lColumn9: "當前產能",
            lColumn10: "總產出",
            lColumn11: "結束日期",
            lColumn12: "創建日期",
        },
        mining: {
            title: "我的礦機列表",
            empty: "暫無礦機",
            day: "天",
            week: "週",
            month: "月",
            year: "年",
            invitetip1: "本礦機產能可通過邀請好友獲得提升，每邀請一人兌換礦機，產能提升",
            invitetip2: "%，產能提升上限",
            miningUnit: "產出幣種",
            miningPeriod: "產出週期",
            miningDays: "挖礦時間",
            miningedDays: "已挖時間",
            miningDaysProfit: "基礎產能",
            miningCurrentDaysProfit: "實際產能",
            status0: "待部署",
            status1: "已部署",
            status2: "已結束"
        },
        locked: {
            title: "我的鎖倉列表",
            empty: "暫無鎖倉",
            day: "天",
            week: "週",
            month: "月",
            year: "年",
            invitetip1: "本鎖倉產能可通過邀請好友獲得提升，每邀請一人參與鎖倉，產能提升",
            invitetip2: "%，產能提升上限",
            miningUnit: "釋放幣種",
            miningPeriod: "釋放週期",
            miningDays: "總釋放週期",
            miningedDays: "已釋放週期",
            miningDaysProfit: "基礎釋放",
            miningCurrentDaysProfit: "實際釋放",
            status0: "待釋放",
            status1: "釋放中",
            status2: "已結束",
            totallocked: "總鎖倉"
        }
    },
    cms: {
        noticecenter: '公告中心',
        newshelp: '新手幫助',
        appdownload: 'APP下載',
        onlineservice: '在線客服',
        faq: '常見問題',
        notice: '公告',
        servicecenter: '客服中心',
        about: '關於',
        joinus: '加入我們',
        aboutus: '關於我們',
        exchangerule: '交易規則',
        useprotocol: '使用協議',
        feenote: '資費說明',
        merchantprocotol: '商家協議',
        features: "平台特色",
        contactus: "聯繫我們",
        noticelist: '公告列表',
        nextnotice: '下一篇',
        prevnotice: "上一篇",
        notexist: "您訪問的公告不存在或已被刪除！",
        otherhelp: "此組別內的文章",
        scanforshare: "掃一掃分享",
        download: "立即下載",
        downloadslogan: "幣嚴APP - 全球數字資產交易平台"
    },
    description: {
        // message1: 'SSL、動態身份驗證等銀行級別安全技術，\n保障交易安全；支持多種數字貨幣交易',
        // message2: '嚴格的項目准入門檻，確保平台用戶利益；\n100%保證金，錢包冷熱隔離，確保用戶資金安全',
        // message3: 'CoinPro數字資產交易平台支持每秒1000筆交易，給用戶帶來酣暢淋漓\n的交易體驗',
        title1: '金融級安全',
        title2: '極速充提',
        title3: '全球服務',
        title4: '嚴選資產',
        message1: '全方位金融風控系統和防盜系統，冷熱錢包、多簽系統保證資金安全',
        message2: '充值提現最快3分鐘完成，24H人工在線審核，保護客戶不錯過最佳投資機會',
        message3: '全球業務服務網絡覆蓋，助您投資全球加密資產，與全球用戶交易',
        message4: '嚴格選擇優質加密項目，為您過濾80%極高風險項目',
        message5: '幣嚴(WWW.COIN.PRO)國際數字加密資產交易平台手機客戶端，專注掌上交易體驗',
        scanqrcode: "掃描二維碼，下載APP",
        aboutdesc1: "幣嚴(WWW.COIN.PRO)是技術全球領先的數字資產交易平台，註冊於開曼群島，核心運營團隊位於香港。幣嚴(WWW.COIN.PRO)核心成員來自於頂級互聯網和金融公司，大部分成員是深度比特幣和區塊鏈信仰者，我們深信區塊鏈將改變傳統壟斷性金融體系，會建立一個更加民主、自治的社會結構。",
        aboutdesc2: "幣嚴(WWW.COIN.PRO)平台擁有專業金融級別的交易架構，擁有自主研發的高並發內存撮合交易引擎，平台採用全冷充值錢包系統+多重簽名+高防護DDOS攻擊系統等交易架構，保證客戶資產安全。",
        aboutdesc3: "幣嚴(WWW.COIN.PRO)為了讓客戶更好的把握投資機會，客服部門內部採用扁平化管理，建立了極速服務響應機制，資產客服經理常年7*24H在線，為客戶提供資產充值提現等服務，保障客戶在25分鐘內完成資產充提。",
        aboutdesc4: "幣嚴(WWW.COIN.PRO)嚴格篩選優質項目並提供安全穩定的資產託管服務。幣嚴(WWW.COIN.PRO)秉承著“誠實、公正、熱情、開放”的理念，竭力為用戶打造安全、可靠、高效和友好的極致交易所。",
        aboutdesc5: "儘管數字資產現在只在一個小小的圈子裡，但是我們相信，這種每個人能夠完全掌握的資產在未來一定能夠流行起來，讓我們一起努力並期待著！",
        support: "技術支持",
        report: "投訴建議",
        service: "客戶服務",
        apply: "上幣申請",
        coop: "商務合作",
        community: "幣嚴社區",
        wechat: "微信",
        addwechat: "添加 “coinpro01” 為微信好友，進入微信福利社群",
        weibo: "微博",
        twitter: "推特",
        biyong: "幣用",
        telegram: "電報",
        medium: "Medium",
        reddit: "Reddit"
    },
    bzb_description: {
        title1: "分紅權益",
        title2: "手續費折扣",
        title3: "優先認購",
        title4: "投票權",
        title5: "第一知情權",
        title6: "優先上幣",
        title7: "共商決策",
        title8: "更多",
        desc1: "手續費分紅、收益分紅、上幣分紅、股權分紅等",
        desc2: "交易手續費最高80%折扣返還獎勵，細則待定",
        desc3: "IEO首發及Boot促銷Token優先認購權利",
        desc4: "參與CoinPro社區投票活動，包括但不限於上幣、回購等",
        desc5: "持倉量達一定數量進SVIP群，跟大V零距離接觸",
        desc6: "僅限超級節點，擁有推薦上幣資格",
        desc7: "僅限超級節點，擁有共商重大決策權利",
        desc8: "更多權益，敬請期待"
    },
    footer: {
        wechatkefu: "微信客服",
        notice: '平台公告',
        changjian: '常見問題',
        shiyong: '使用協議',
        feilv: '費率說明',
        lianxi: '聯繫郵箱',
        biyong: '幣用',
        gsmc: '嚴選全球優質數字資產',
        bah: '滬ICP備13026899號-3',
        gsjj: '關於',
        gywm: '關於我們',
        jrwm: '加入我們',
        lxwm: '聯繫我們',
        yqlj: '友情鏈接',
        bzzx: '幫助中心',
        xsrm: '新手教程',
        czzn: '充值指南',
        jyzn: '交易指南',
        ptsm: '平台說明',
        fysm: '交易規則',
        fwtk: '用戶協議',
        fltk: '資費說明',
        wechat: "微信客服",
        xszn: "新手指南",
        cjwt: "常見問題",
        mztk: "免責條款",
        ystk: "隱私條款",
        tkxy: "條款協議",
        gybzb: "關於BZB",
        kfyx: "客服郵箱",
        swhz: "商務合作",
        sbsq: "上幣申請",
        jyzn: "交易指南",
        bzzl: "幣種資料",
        tsjb: "投訴舉報",
        apidoc: "API接口",
        zwkf: "暫未開放"
    },
    financeNav: {
        wdzc: '我的資產'
    },
    index: {
        circulation: "BHB 安全與發展基金流通量",
        hourEth: '今日待分配收入累計折合',
        yesterdayEth: '昨日分配收入累計折合',
        yesterday: '昨日挖礦產出',
        bhbTotal: "BHB 總流通量",
        bhbSecond: "BHB二級市場流通量",
        checkMineprinciple: "查看挖礦原理",
        checkFlowVolum: '檢查流通量說明',
        checkLockPosition: '查看鎖倉情況',
        BHBregister: "CoinPro賬戶註冊",
        tibi: "提幣到賬時間及限額",
        line_plane: "BHB上線計劃、流通量及手續費返還公告",
        fenpeijizhi: '關於CoinPro收入分配機制的說明',
        jiangli_jihua: "邀請挖礦獎勵計劃",
        friend_fanhuan: "邀請好友、賺取額外手續費返還"
    },
    plate: {
        title: "平台收入分配方案 (BHB 持有者權益)",
        content1: "正如白皮書所描述的，CoinPro會拿出平台的80% (扣除稅費後) 的收入分配給CoinPro持有者，20%的收入用來支持平台的研發及運營。",
        content2: "收入分配以日為一個分配週期，2018年6月6日為首個分配日。之後的每一天，會將前一天累積的所有待分配收入，一次性的按比例分給BHB持有者",
        content3: "（注：1.此處的BHB僅指已經釋放的/可流通的BHB，具體請參見【",
        content3_1: '關於BHB流通量/參與收入分配比例的說明',
        content3_2: "2.每小時（整點）快照併計算一次，收入分配執行為每天一次）。",
        hourFenpei: "今日分配收入折合",
        hourTotal: '今日平台總收入折合',
        xiangqing: '今日收入分配詳情',
        yesterdaytit: "昨日天分配收入折合",
        yesterdaytotal: "昨日平台總收入折合",
        yesterdayxiangqing: "昨日收入分配詳情",
        /*yesterday:{
          total:"平台總手續費",
          allocated:"待分配收入",
        }*/
    },
    feereturn: {
        ruletitle: "返還規則",
        rulecontent: "白皮書裡已經對BHB的分配比例有詳細的說明。51%比例的BHB通過“交易即挖礦”的方式逐步回饋給交易用戶。一旦51%的BHB全部回饋完成，“挖礦”即自動終止。",
        recordtitle: '返還記錄',
        recordcontent: '每日（UTC+8，以下同）都會將前一日的用戶所產生交易手續費，100%折算成BHB返還給用戶，折算價格按前一交易日BHB的均價（均價計算方式為總成交金額/總成交量）。我們將於每日上午11點，開始發放前一日交易手續費折合BHB的返還。 ',
        /*time:"日期",
        todaycharge:"當日CoinPro均價(ETH)",
        totalChange:'當日總手續費折合(ETH)',
        returnCharge:"當日挖礦手續費返還(BHB)",
        todayChargeReturn:"當日挖礦收入倍增計劃返還（BHB）"*/
    }
}
