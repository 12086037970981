module.exports = {
    common: {
        login: '登录',
        register: '注册',
        loginregister: "登录/注册",
        logout: '退出登录',
        tip: '温馨提示',
        logintip: '请先登录',
        expect: '敬请期待!',
        delete: '确定删除吗?',
        nodata: '暂无记录',
        set: '设置',
        update: '修改',
        slogan: "全球前50的数字货币交易所",
        subslogan: "国家机构担保资产安全",
        more: "更多",
        please: "请先",
        pleaseselect: "请选择",
        searchplaceholder: "输入币种名称搜索",
        searchplaceholderswap: "输入合约名称搜索",
        loading: "加载中...",
        close: "关闭",
        ok: "确定",
		fillComplete:"请填写完整的信息"
    },
    header: {
        index: '首页',
        exchange: '币币交易',
        otc: '法币OTC',
        kefu: '帮助中心',
        asset: '财务中心',
        ucenter: '账户中心',
        service: '公告',
        downloadapp: "扫码进行下载安装",
        appdownlaod: 'APP下载',
        usercenter: "个人中心",
        helpcenter: "帮助中心",
        aboutus: "关于",
        partner: "合伙人",
        bzb: "BZB",
        assetmanage: "资金管理",
        trademanage: "交易管理",
        activity: "最新活动",
        lab: "创新实验室",
        ctc: "法币C2C",
        labdetail: "币严实验室  ●  共享创新成果",
        invite: "推广合伙人",
        whitepaper: "白皮书",
        scandownload: "扫一扫下载APP",
        innovationmanage: "创新管理",
        swap1: "永续合约",
        swap2: "永续合约(撮合)",
        swap3: "差价合约",
        swap4: "秒合约",
        cexchange: "快速兑换",
        option: "期权合约",
        news: "资讯"
    },
    sectionPage: {
        ptaqTitle: '平台安全',
        ptaqContent: '采用哈希算法加密，全球领先的安全认证',
        ptslTitle: '平台实力',
        ptslContent: '全球最大的比特币交易平台',
        newsTitle: '多终端APP',
        brandTitle: "关于CoinPro",
        brandDetail: "诚实 | 公平 | 热情 | 开放",
        brandDesc1: "WWW.CoinPro.COM由一群比特币早期参与者与极客创立，团队核心成员来自于谷歌、微软、阿里巴巴、腾讯等知名企业，具有深厚的研发实力与丰富的互联网产品运营经验。",
        brandDesc2: "WWW.CoinPro.COM定位于区块链基础服务商，致力于为全球用户提供优质加密资产交易平台，秉承着“不作恶”的基本原则，坚持诚实、公正、热情的服务于客户，以开放的态度迎接一切有利于用户根本利益的伙伴/项目。",
        gettingstart: "新手入门 | 极速买币",
        officialstart: "CoinPro官方新手入门辅助通道",
        oneminutebuy: "币币交易",
	    oneminutebuytips: "用一种数字资产去直接换取另一种",
	    baseknow: "合约交易",
	    baseknowtips: "T+0双向交易虚拟商品作押易货合约",
	    baseexchange: "期权合约",
	    baseexchangetips: "是一种数字资产衍生品",
	    usersocial: "快速兑换",
	    usersocialtips: "快速兑换数字资产",
        mainboard: "主板交易区",
        preview: "上一页",
        nextpage: "下一页",
        downloadwhite: '下载白皮书(或在白皮书上鼠标右键 -> "另存为...")'
    },
    nav: {
        shouye: '首页',
        jiaoyi: '法币交易',
        dingdan: '订单管理',
        zijin: '财务中心',
        yonghu: '用户中心',
        bibi: '币币交易',
        fabu: '发布广告',
        ptjy: '平台交易',
        ptaq: '平台安全',
        ptsl: '平台实力',
        xwzx: '多终端APP'
    },
    progress: {
        already: "已挖矿",
        total: "可挖矿总量60亿 BHB"
    },
    service: {
        USDT: 'USDT',
        BTC: 'BTC',
        ETH: 'ETH',
        CUSTOM: '自选',
        COIN: '币种',
        favor:'收藏',
        symbol: '交易对',
        NewPrice: '最新价',
        ExchangeNum: '交易数量',
        Change: '24h涨跌',
        OpenPrice: '开盘价',
        PriceTrend: '价格趋势',
        Operate: '操作',
        Exchange: '去交易',
        trading: "立即交易",
        high: "24h最高价",
        low: "24h最低价"
    },
    exchange: {
        coin: '币种',
        symbol:"交易对",
        lastprice: '最新价',
        daychange: '24h涨跌',
        market: '市场',
        favorite: '收藏',
        do_favorite: '已收藏',
        cancel_favorite: '取消收藏',
        type: "类型",
        dealamount: "成交金额",
        high: '高',
        low: '低',
        vol: '24H量',
        buy: '买入',
        sell: '卖出',
        limited_price: '限价',
        market_price: '市价',
        fees_rate: '费率',
        balance: '资产',
        or: '或',
        starttrade: '开始交易',
        canuse: '可用',
        recharge: '充币',
        buyprice: '买入价',
        buynum: '买入量',
        amount: '交易额',
        buyin: '买入',
        disablebuyin: "禁止市价买入",
        buytip: '以市场上最优价格买入',
        sellprice: '卖出价',
        sellnum: '卖出量',
        sellout: '卖出',
        disablesellout: "禁止市价卖出",
        selltip: '以市场上最优价格卖出',
        curdelegation: '当前委托',
        hisdelegation: '委托历史',
        realtransaction: '实时成交',
        num: '数量',
        price: '价格',
        direction: '方向',
        time: '时间',
        stall: '档位',
        total: '累计',
        traded: '已成交',
        action: '操作',
        undo: '撤单',
        delegationnum: '委托量',
        done: '已成交',
        status: '状态',
        finished: '已完成',
        canceled: '已取消',
        parted: "部分成交",
        tip: '提示',
        buyamounttip: '请输入买入量',
        buyamounttipwarning: '买入数量不能高于',
        success: '提交成功',
        pricetip: '请输入交易额',
        pricetipwarning: '交易额不能高于',
        sellamounttip: '请输入卖出量',
        sellamounttipwarning: '卖出数量不能高于',
        sellpricetip: '请输入卖出价格',
        sellpricetipwarning: '卖出价格不能高于',
        undotip: '是否确认撤单？',
        cancelsuccess: "撤单成功！",
        marketprice: '市价',
        expand: {
            time: '时间',
            price: '价格',
            amount: '数量',
            fee: '手续费',
        },
        realtime: '分时',
        kline: "K线图",
        depth: "深度图",
        publishstate0: "活动即将开始，请稍候...",
        publishstate1: "活动即将结束，请尽快交易...",
        publishstate2: "活动订单盘整中，请稍候...",
        publishstate3: "盘整结束，请自由交易...",
        publishdetail: "活动规则详情",
        publishprice: "发行价",
        publishamount: "总量",
        publishcounttxt0: "距活动开始还剩",
        publishcounttxt1: "距离活动结束还剩",
        publishcounttxt2: "距离清盘结束还剩",
        publishcounttxt3: "活动已结束",
        dateTimeDay: "天",
        dateTimeHour: "时",
        dateTimeMinutes: "分",
        dateTimeSeconds: "秒",
        moredetail: "更多详情"
    },
    swap: {
        contract: "合约",
        swapindex: "永续",
        coin: '币种',
        symbol:"交易对",
        volume: "成交量",
        lastprice: '最新价',
        daychange: '24H涨跌',
        market: '市场',
        favorite: '收藏',
        do_favorite: '已收藏',
        cancel_favorite: '取消收藏',
        type: "类型",
        dealamount: "成交金额",
        high: '高',
        low: '低',
        vol: '24H量',
        buy: '买入',
        sell: '卖出',
        limited_price: '限价委托',
        market_price: '市价委托',
        spot_price: '止盈止损',
        fees_rate: '费率',
        balance: '资产',
        or: '或',
        starttrade: '开始交易',
        canuse: '可用',
        recharge: '充币',
        buyprice: '买入价',
        buynum: '买入量',
        amount: '交易额',
        buyin: '买入',
        disablebuyin: "禁止市价买入",
        buytip: '以市场上最优价格买入',
        sellprice: '卖出价',
        sellnum: '卖出量',
        sellout: '卖出',
        disablesellout: "禁止市价卖出",
        selltip: '以市场上最优价格卖出',
        curdelegation: '当前委托',
        hisdelegation: '历史委托',
        realtransaction: '实时成交',
        num: '数量',
        price: '价格',
        direction: '方向',
        time: '时间',
        stall: '档位',
        total: '累计',
        traded: '已成交',
        action: '操作',
        undo: '撤单',
        delegationnum: '委托量',
        done: '已成交',
        status: '状态',
        finished: '已完成',
        canceled: '已取消',
        parted: "部分成交",
        tip: '提示',
        buyamounttip: '请输入买入量',
        buyamounttipwarning: '买入数量不能高于',
        success: '提交成功',
        pricetip: '请输入交易额',
        pricetipwarning: '交易额不能高于',
        sellamounttip: '请输入卖出量',
        sellamounttipwarning: '卖出数量不能高于',
        sellpricetip: '请输入卖出价格',
        sellpricetipwarning: '卖出价格不能高于',
        undotip: '是否确认撤单？',
        cancelsuccess: "撤单成功！",
        marketprice: '市价',
        expand: {
            time: '时间',
            price: '价格',
            amount: '数量',
            fee: '手续费',
        },
        realtime: '分时',
        kline: "K线图",
        depth: "深度图",
        open: "开仓",
        close: "平仓",
        latestdeal: "最新交易",
        myswapaccount: "我的合约账户",
        accountmode: "账户模式",
        accountmargin: "合约倍数",
        accountquanyi: "账户总权益",
        profitandloss: "未实现盈亏",
        principalAmount: "可用保证金",
        positionAmount: "仓位保证金",
        frozenAmount: "冻结保证金",
        principalRate: "资金利用率",
        zijinhuazhuan: "资金划转",
        up: " 多",
        down: " 空",
        canup: "可开多",
        candown: "可开空",
        cancloseup: "空仓可平",
        canclosedown: "多仓可平",
        openup: "买入开多",
        opendown: "卖出开空",
        closeup: "买入平空",
        closedown: "卖出平多",
        currentposition: "当前持仓",
        assetsdetail: "资金明细",
        marginMode1: "逐仓",
        marginMode2: "全仓",
        modifyMarginModeTitle: "修改账户模式",
        modifyLeverage: "调整合约倍数",
        moredetail: "更多详情",
        shareNumber: "张",
        entrustprice: "委托价",
        triggleprice: "触发价",
        openvolume: "开仓数量",
        closevolume: "平仓数量",
        triggleplaceholder: "默认委托价为市价",
        marketpriceplaceholder: "以市场最优价成交",
        levmaxtip: "合约倍数不能高于最高倍数",
        levmintip: "合约倍数不能低于最低倍数",
        marginModeSuccessTip: "合约账户模式修改成功",
        noenoughbalance: "账户保证金不足",
        pleaseinputopenvolume: "开仓数量必须大于0",
        pleaseinputcorrectopenvolume: "请输入正确的开仓数量",
        pleaseinputrightentrustprice: "请输入正确的委托价格",
        noenoughposition: "可平仓位不足",
        pleaseinputclosevolume: "平仓数量必须大于0",
        pleaseinputcorrectclosevolume: "请输入正确的平仓数量",
        pos_Pl: "收益",
        pos_Ratio: "收益率",
        pos_pos: "持仓量",
        pos_canClose: "可平量",
        pos_openPrice: "开仓均价",
        pos_leverage: "合约倍数",
        pos_margin: "当前保证金",
        pos_mRatio: "保证金率",
        pos_cmRatio: "维持保证金率",
        pos_long: "多头",
        pos_short: "空头",
        ent_entrustType: "开仓/平仓",
        ent_direction: "方向",
        ent_type: "委托类型",
        ent_trigglePrice: "触发价",
        ent_entrustPrice: "委托价",
        ent_tradedPrice: "成交价",
        ent_margin: "保证金",
        ent_volume: "委托数量",
        ent_status: "委托状态",
        ent_status1: "委托中",
        ent_status2: "已撤销",
        ent_status3: "委托失败",
        ent_status4: "委托成功",
        ent_statusblast: "爆仓",
        ent_pl: "结算盈亏",
        ent_fee: "手续费",
        triggerpriceshouldbigger: "触发价必须大于0"
    },
    ctc: {
        title: "一键买卖 ● 平台托管 ● 安全放心",
        desc:"由币严平台托管数字资产，保障用户资产安全",
        buyin: "买入",
        sell: "卖出",
        buyprice: "买入价",
        sellprice: "卖出价",
        buynum: "买入量",
        sellnum: "卖出量",
        payamount: "结算总额",
        getamount: "结算总额",
        avabalance: "可卖数量",
        time: "交易时间",
        tip: "提示",
        notice: "温馨提示",
        notice1: "1. 法币交易区是用户与承兑商之间资产交易，资金不经转平台，平台也不接受人民币充值/汇款；",
        notice2: "2. 法币交易承兑商均通过实名认证，提供交易保证金，并且代币均由平台托管，您可以放心兑换；",
        notice3: "3. 承兑商服务时间为每天09:00-21:00，接单后30分钟内完成承兑，2小时未完成交易作废；",
        notice4: "4. 法币交易区支持银行卡，必须使用本人已实名认证的账户进行转账；",
        notice5: "5. 为保障交易资金安全，法币交易要求用户通过实名认证，请在通过实名认证后，及时设置资金密码与收款账户，否则无法交易；",
        payType: "付款方式",
        receiveType: "收款方式",
        moneyTips: "以上金额仅供参考，请以下单后的实际结算金额为准",
        verifyset: "实名认证/资金密码",
        payset: "付款/收款设置",
        trade: "交易",
        input50tips: "请输入50~50000之间的数字",
        bank: "银行卡",
        alipay: "支付宝",
        wechatpay: "微信",
        payaccountset: "付款账户设置",
        useselfaccount: "请务必使用与您本人实名认证相符的收款/付款账户",
        orderSn: "订单号",
        tradetime: "下单时间",
        direction: "交易类型",
        amount: "数量(USDT)",
        money: "总额(CNY)",
        price: "单价(CNY)",
        status: "状态",
        operate: "操作",
        moredetail: "更多买卖须知"
    },
    cexchange: {
        title: "BZB 活动兑换",
        desc: "BZB平台币 ● 一键兑换 ● 坐享收益",
        quantity: "兑换数量",
        inputtips1: "输入自定义数量",
        choosecurrency: "选择兑换币种",
        all: "全部",
        totalpay: "总兑换金额",
        restricted: "今日限量",
        currentprice: "当前兑换价格",
        nextprice: "下期兑换价格",
        confirmpay: "确认兑换",
        assets: "可用：",
        loginFirst: "请先登录",
        success: "恭喜你！兑换成功！",
        tip: "提示",
        confirmmsg: "确认"
    },
    option: {
        title: "期权合约",
        buyupreward: "买涨总额",
        buydownreward: "买跌总额",
        quantity: "开仓金额",
        myamount: "我的开仓",
        buyup: "看涨",
        buydown: "看跌",
        history: "往期结果",
        seriers: "第",
        period: "期",
        currentoption: "本期预测周期",
        openprice: "开盘价",
        currentprice: "当前价",
        openprice1: "开盘价格",
        closeprice: "收盘价格",
        opentime: "开盘时间",
        closetime: "收盘时间",
        progress: "预测进度",
        loginFirst: "请先登录!",
        selectAmount: "请选择投注数量",
        balancenotenough: "可用余额不足!",
        betsuccess: "恭喜你！投注成功！",
        noopening: "暂无待期权合约",
        col_createTime: "创建时间",
        col_direction: "预测方向",
        col_result: "预测结果",
        col_optionNo: "合约期数",
        col_betAmount: "开仓金额",
        col_rewardAmount: "奖金数量",
        col_fee: "开仓手续费",
        col_winfee: "抽水",
        win: "成功",
        lose: "失败",
        wait: "进行中",
        avaliablebalance: "可用余额",
        login: "请先登录",
        kline: "实时行情"
    },
    otc: {
        ad: '广告中心',
        buyin: '买入',
        sellout: '卖出',
        merchant: '商家',
        applymerchant: '申请为认证商家',
        volume: '交易笔数',
        paymethod: '付款方式',
        amount: '数量',
        price_coin: '价格/币',
        operate: '操作',
        validate: '请先进行实名认证',
        sell: '卖出',
        buy: '买入',
        transaction: '买卖交易',
        buttontxt: "成为商家",
        title: "法币交易",
        desc: "便捷、安全、快速买卖数字货币",
        title1: "动态调整",
        desc1: "根据市场价格实时波动",
        title2: "免手续费",
        desc2: "用户所见即所得，买卖价格外，无需任何平台手续费",
        title3: "即时成交",
        desc3: "引入平台服务商家，智能匹配，成交订单，无须等待撮合",
        title4: "平台担保",
        desc4: "平台认证商家，安全有保障，24小时客服为交易保驾护航",
        myad: {
            title: '我的广告',
            post: '发布广告',
            alert: '【温馨提示】：当广告最小交易额所购买数量加上手续费大于广告剩余数量，该广告自动下架',
            no: '广告编号',
            type: '广告类型',
            sell: '在线出售',
            buy: '在线购买',
            limit: '订单限额',
            remain: '剩余数量',
            coin: '币种',
            created: '创建时间',
            operate: '操作',
            errmsg: '广告下架后才可编辑修改',
            update: '修改',
            shelf: '上架',
            dropoff: '下架',
            delete: '删除',
        },
        myorder: '我的订单',
        chatline: {
            status_1: '买家未付款，等待买家付款！',
            status_2: '买家已付款，等待卖家放行！',
            status_3: '订单已完成交易！',
            status_4: '订单正在申诉中！',
            status_5: '订单已取消！',
            loadmore: '加载更多',
            warning: '防诈骗提示：近期，屡有诈骗份子利用银行转账汇款信息和假汇款凭据进行诈骗，请一定登陆自己的收款账号核实。保证汇入资金的安全，避免银行卡被冻结的风险！',
            contenttip: '请输入聊天内容 回车键发送',
            contentmsg: '消息不能为空',
        },
        chat: {
            seller: '卖家',
            buyer: '买家',
            exchangeamount: '交易金额',
            operatetip: '操作提示',
            operatetip_1: '请在规定的时限内按照对方给出的账号完成支付，并在本页面点击',
            finishpayment: '付款完成',
            operatetip_1_1: '转账时请在留言中备注好交易单号',
            operatetip_1_2: '卖方收到款项后会在网站确认收款，系统会自动将您所购买的数字资产发放至您的账户，请注意查收',
            note: '注意',
            notetip: '请不要使用其他聊天软件与对方沟通，更不要接受对方向您发送的任何文件、邮箱附件等，所有沟通环节请都在本页面的聊天窗口完成',
            operatetip_2_1: '您所出售的数字资产，已交由平台托管冻结。请在确定收到对方付款后，点击',
            operatetip_2_2: '请不要相信任何催促放币的理由，确认收到款项后再释放数字资产，避免造成损失！',
            operatetip_2_3: '在收到账短信后，请务必登录网上银行或手机银行确认款项是否入账，避免因收到诈骗短信错误释放数字资产！',
            confirmrelease: '确认放行',
            paydigital: '支付数字资产',
            orderstatus: '订单状态',
            orderstatus_1: '付款完成',
            orderstatus_2: '订单申诉',
            orderstatus_3: '确认放行',
            orderstatus_4: '取消交易',
            orderstatus_5: '订单申诉',
            order: '订单',
            and: '与',
            transition: '的交易',
            transprice: '交易价格',
            transnum: '交易数量',
            transmoney: '交易金额',
            tip1: '用户暂时未添加银行卡卡号',
            tip2: '用户暂时未添加支付宝账号',
            tip3: '用户暂时未添加微信账号',
            zfb: '支付宝',
            wx: '微信',
            qrcode: '二维码',
            msg1: '您确定已经付款完成吗',
            msg2: '已付款项并不退还！您确定取消订单吗？',
            msg3: '【重要】：已付款项并不退还！您确定取消订单吗？',
            msg4: '已付款，未收到币',
            msg5: '已付币，未收到款',
            tip: '提示',
            comptype: '投诉类型',
            compremark: '投诉备注',
            willcomp: '我要投诉',
            msg6: '是否确认放币？',
            result_1: '等待付款',
            result_2: '等待放行',
            result_3: '已完成',
            result_4: '申诉中',
            result_5: '已取消',
            msg7: '资金密码',
            msg7tip: '请填写资金密码',
        },
        checkuser: {
            emaildone: '邮件已认证',
            emailundo: '邮件未认证',
            teldone: '手机号码已认证',
            telundo: '手机号码未认证',
            idcarddone: '身份证已认证',
            idcardundo: '身份证未认证',
            language: '语言',
            languagetext: '中文',
            registtime: '注册时间',
            exchangetimes: '累计交易次数',
            exchangeinfo: '的交易信息',
            tablabel1: '在线出售',
            tablabel2: '在线购买',
            col_symbol: '币种',
            col_paymode: '付款方式',
            col_num: '数量',
            col_price: '价格',
            col_created: '发布时间',
            col_operate: '操作',
            operatemsg: '请先进行实名认证',
            buyin: '买入',
            sellout: '卖出',
        },
        tradecenter: {
            postad: '发布广告',
            exchange: 'GCC交易',
            buyin: '我要买入',
            sellout: '我要卖出',
        },
        tradeinfo: {
            emaildone: '邮件已认证',
            emailundo: '邮件未认证',
            teldone: '手机号码已认证',
            telundo: '手机号码未认证',
            idcarddone: '身份证已认证',
            idcardundo: '身份证未认证',
            exchangetimes: '交易次数',
            price: '价格',
            num: '数量',
            paymethod: '付款方式',
            exchangelimitamount: '交易限额',
            location: '所在地',
            location_text: '中国',
            exchangeperiod: '交易期限',
            minute: '分钟',
            amounttip: '请输入金额',
            numtip: '请输入数量',
            remarktip: '告诉他您的要求',
            remarktitle: '备注信息',
            exchangetitle: '交易须知',
            exchange_tip1: '在您发起交易请求后，数字货币被锁定在托管中，受到平台保护。 如果您是卖家，发起交易请求后，您可通过充值并等待买家付款。买家在付款时限内进行付款。您在收到付款后应放行处于托管中的数字货币。',
            exchange_tip2: '交易前请阅读《平台网络服务条款》以及常见问题、交易指南等帮助文档。',
            exchange_tip3: '当心骗子！交易前请检查该用户收到的评价，并对新近创建的账户多加留意。',
            exchange_tip4: '请注意，四舍五入和价格的波动可能会影响最终成交的数字货币数额。您输入的固定数额决定最后数额，数字货币金额将在请求发布的同一时间由即时的汇率算出。',
            exchange_tip5: '托管服务保护网上交易的买卖双方。在发生争议的情况下，我们将评估所提供的所有信息，并将托管的数字货币放行给其合法所有者。',
            warning1: '最多输入2位小数',
            warning2: '下单金额为',
            warning3: '最多输入8位小数',
            warning4: '下单数量为',
            confirmbuyin: '确认买入',
            confirmsellout: '确认卖出',
            buyin: '买入',
            sellout: '卖出',
            warning5: '请按要求填写订单',
        },
        publishad: {
            createad: '创建一个广告交易',
            msg1: '如果您经常进行交易，您可以创建自己的交易广告。如果您只是偶尔交易，我们建议您直接搜索',
            msg2: '创建一则交易广告是',
            msg3: '免费的',
            msg4: '若您想直接编辑已创建的广告，请查看',
            tradead: '交易广告',
            myad: '我的广告',
            iwant: '我想要',
            sellonline: '在线出售',
            buyonline: '在线购买',
            exchangecoin: '交易币种',
            country: '国家',
            currency: '货币',
            openfixedprice: '开启固定价格',
            open: '开启',
            close: '关闭',
            usetip: '启用后，您的币价不会随市场波动，价格不变。',
            premiseprice: '溢价',
            premisepricetip: '请设置您的溢价',
            fixedprice: '固定价格',
            fixedpricetip: '请输入您的交易价格',
            marketprice: '市场参考价格',
            marketpricetip: '溢价是指高于当前市场价格多少百分比进行',
            exchangeprice: '交易价格',
            formual: '计价公式',
            num: '数量',
            num_text1: '请输入您要',
            num_text2: '的数量',
            exchangeperiod: '交易期限',
            exchangeperiod_text1: '请输入您的交易期限',
            minute: '分钟',
            tip1: '可接受买方在多少时间内交易，请输入整数',
            tip2: '【提示】可前往个人中心绑定/增加支付方式',
            tip3: '请输入您的最小交易额',
            tip4: '请输入您的最大交易额',
            tip5: '可以内备注信息里填写您的特殊要求，例如：对买方的要求，在线时间等。',
            paymode: '付款方式',
            minlimit: '最小交易额',
            maxlimit: '最大交易额',
            remark: '备注信息',
            openautoreply: '开启自动回复',
            msg5: '启用后，用户通过此广告向您发起交易时，系统自动将您选择的自动回复用语发送给对方。',
            autoreply: '自动回复',
            autoreplytip: '在接收订单后，自动回复给买家的信息，例如：收款方式、收款账号等。',
            fundpwd: '资金密码',
            fundpwdtip: '请输入您的资金密码',
            submit: '提交',
            warning1: '请输入正确数字',
            warning2: '溢价值为0-20',
            warning3: '请输入您的最大交易额！',
            warning4: '请输入整数',
            warning5: '最大交易额必须大于最小交易额！',
            warning6: '最大交易额不能超过您的卖出总金额',
            warning7: '请输入您的最小交易额！',
            warning8: '最小交易额必须大于等于100！',
            warning9: '最小交易额必须小于最大交易额',
            sellout: '卖出',
            buyin: '买入',
            inputtip1: '请输入币种',
            inputtip2: '请输入正确选项',
            inputtip3: '溢价值为0-20，且不能为0',
            inputtip4: '请输入正确固定价格',
            inputtip5: '请输入正确数字，并且最大交易数量不超过100币',
            inputtip6: '请输入正确交易限期',
            inputtip7: '请选择交易方式',
            inputtip8: '请输入资金密码',
            zfb: '支付宝',
            wx: '微信',
            unionpay: '银联',
            submit_failure: '提交失败!',
            submit_success: '',
            submittip1: '为了您的资金安全，请先进行【实名认证】！',
            submittip2: '为了您的资金安全，请先进行【手机绑定】！',
            submittip3: '为了您的资金安全，请先设置【资金密码】！',
            submittip4: '请至少绑定一种支付方式',
        },
        index: {
            title: '优质广告推荐',
            exchangetimes: '交易次数',
            exchangeprice: '交易价格',
            exchangelimit: '交易限额',
            paymode: '付款方式',
            buy: '购买',
            sell: '卖出',
            viewmore: '查看更多',
            bot1: '安全可靠',
            bot1_tip: '超过10年金融风控经验团队全方为\n可定制的安全策略体系',
            bot2: '快速便捷',
            bot2_tip: '点对点的用户自由交易模式支持多\n种资金渠道的兑换方式',
            bot3: '币种全面',
            bot3_tip: '甄选主流数字资产交易币种满足多\n方面的多资产交易体验',
            ibuy: '我要买入',
            isell: '我要卖出',
        }
    },
    activity: {
        all: "全部",
        prepare: "即将开始",
        ongoing: "进行中",
        distributing: "派发中",
        completed: "已完成",
        progress: "进度",
        totalsupply: "活动总量",
        starttime: "开始时间",
        endtime:"结束时间",
        viewdetail: "查看详情",
        attend: "立即参与",
        tokendistribute: "Token派发中",
        baseinfo: "基本信息",
        status: "状态",
        activitytype: "认购类型",
        activitytype0: "未知",
        activitytype1: "首次上线(抢购)",
        activitytype2: "首次上线(平分)",
        activitytype3: "持仓瓜分",
        activitytype4: "自由认购",
        activitytype5: "云矿机认购",
        activitytype6: "锁仓释放",
        ruledetail: "类型说明",
        publishprice: "认购价格",
        activitycoin: "活动币种",
        acceptcoin: "接受币种",
        holdcoin: "持仓要求币种",
        limittimes: "个人限购次数",
        limitamount: "个人限购数量",
        unlimited: "不限",
        alreadyamount: "已购数量",
        alreadyholdamount: "活动参与总持仓",
        myalreadyholdamount: "我的参与持仓",
        leftamount: "剩余数量",
        attention: "注意",
        attentiontxt1: "1、提交参与【持仓瓜分】、【自由认购】、【云矿机认购】、【锁仓】类型活动后将冻结资产，活动结束后解冻或解锁释放",
        attentiontxt2: "2、参与【持仓瓜分】、【自由认购】、【云矿机认购】、【锁仓】类型活动后，用户不得撤销参与申请",
        attentiontxt3: "3、如因项目方及其他不可抗力因素取消活动，将原路退回冻结资产",
        attentiontxt4: "* 本活动最终解释权归币严(WWW.CoinPro.COM)官方所有",
        inputamount: "输入兑换数量",
        inputholdamount: "输入锁仓数量",
        mybalance: "我的可用余额",
        holdtips: "参与【持仓瓜分】类型活动需锁仓至活动结束",
        pleaseinputamount: "请输入兑换数量",
        pleaseinputholdamount: "请输入锁仓数量",
        commitfailed: "提交参与失败！",
        minlimitamountfailed: "兑换数量不能小于最低起兑量！",
        maxlimitamountfailed: "兑换数量不能大于最高限购量！",
        limittimesfailed: "您参与的次数超过个人最高限购次数！",
        balancenotenough: "余额不足！",
        headertitledesc: "投资者、项目方、交易所等多方共赢的开放实验室",
        currentdivided: "当前我的持仓可瓜分约",
        leveloneCount: "需要一级好友数",
        submit: "提交",
        tipsmobile: "请进入电脑端页面参与（WWW.CoinPro.COM）",
        tipsmobile1: "请在电脑端打开（WWW.CoinPro.COM）并进入创新实验区参与",
        intvalue: "请输入整数",
        inputminingamount: "输入购买矿机数",
        moreactivity: "更多其他活动",
        releaseType: "释放类型",
        releaseType0: "等额释放",
        releaseType1: "等比释放",
        lock: " 期释放",
        releaseDetail: "释放详情",
        lockday: " 日",
        lockweek: " 周",
        lockmonth: " 月",
        lockyear: " 年",
        releasePercent: "释放比例",
        lockFee: "锁仓门槛",
        releaseTimes: "释放倍数",
        times: "倍"
    },
    invite: {
        myinvitelink: "我的邀请链接",
        copy: "复制",
        copyed: "已复制",
        mylevelone: "我的一级好友",
        myleveltwo: "我的二级好友",
        mycommission: "佣金收入(折合USDT)",
        extrareward: "额外奖励",
        partnerlevel: "合伙级别",
        ruledetail: "规则详情",
        ruleprofile1:"币严(WWW.CoinPro.COM)的【推广合伙人】是全网",
        ruleprofile2:"返佣比例最高、",
        ruleprofile3:"返佣时长最长(最高终身返佣)",
        ruleprofile4:"的推广活动，让付出相应努力的推广者能够成为真正意义上的平台“合伙人”，享受与币严(WWW.CoinPro.COM)交易平台共同成长的红利！具体细则如下：",
        ruletext1: "一、为布道数字资产理念与扩大用户规模，币严(WWW.CoinPro.COM)推出【推广合伙人】计划，该活动长期有效。",
        ruletext2: "二、邀请好友分为两级，如A邀请B，则B是A的一级好友，若B又邀请了C，则C属于B的一级好友，同时也属于A的二级好友。",
        ruletext3: "三、推广好友注册时，需通过邀请者提供的链接或手动输入邀请者邀请码方可成为邀请者的一级好友。",
        ruletext4: "四、被邀请者通过邀请者邀请码注册，并且完成实名认证后，返佣激励方可生效。",
        ruletext5: "五、返佣时间为从被邀请者完成实名认证开始的N个月，根据等级不同，享受的返佣比例不同。",
        ruletext6: "六、推广返佣资产为下级好友币币交易手续费，返佣币为三大市场基础币，即：USDT、BTC、ETH。",
        ruletext7: "七、推广返佣比例、返佣时长与推广一级好友人数呈正相关，推广人数越多，可享返佣比例越高。具体推广返佣比例如下：",
        ruletext8: "八、推广返佣排行榜TOP10或TOP100不定期会获得由币严(WWW.CoinPro.COM)官方发放的额外奖励。",
        ruletext9: "九、如其他活动与【推广合伙人】活动冲突，则经协商后决定是否临时变更，望诸合伙人谅解。",
        ruletext10: "十、L4(巡抚)、L5(太守)、L6(节度使)级合伙人分别享受每年返佣总额的5%、10%、15%的分红奖励。",
        ruletext11: "十一、本活动最终解释权归币严(WWW.CoinPro.COM)所有。",
        ruleexampletitle: "举例说明：",
        ruleexamplecontent1: "若用户小严邀请了100名一级好友，TA的一级好友分别邀请了5名二级好友，此时小严就拥有100名一级好友，500名二级好友。如果平均每人每天交易3000元，那么小严月收入约：(100*3000*0.001*30% + 500*3000*0.001*10%) * 30 = 7200/月。",
        ruleexamplecontent2: "若用户小严邀请了1000名一级好友，TA的一级好友分别邀请了5名二级好友，此时小严就拥有1000名一级好友，5000名二级好友。如果平均每人每天交易3000元，那么小严月收入约：(1000*3000*0.001*50% + 5000*3000*0.001*20%) * 30 = 135000/月。",
        lastupdate: "最后更新于",
        laststastic: "统计截止时间",
        level: "级别",
        lv1: "里正",
        lv2: "县令",
        lv3: "知府",
        lv4: "巡抚",
        lv5: "太守",
        lv6: "节度使",
        levelonecount: "一级好友数",
        levelonecommission: "一级好友返佣比例 / 返佣时间",
        leveltwocommission: "二级好友返佣比例 / 返佣时间",
        partnerdivide: "合伙人分红",
        month: "个月",
        alllife: "终身返佣",
        totalcommissionrank: "推广返佣总额",
        rank: "排名",
        userid: "用户ID",
        rankday: "(日榜)",
        rankweek: "(周榜)",
        rankmonth: "(月榜)",
        rankall: "(总榜)",
        promotioncount: "推广人数",
        commissionusdt: "返佣(折合USDT)",
        ranktip1: "邀请人数为一级好友数，此榜为返佣总金额排名",
        ranktip2: "邀请人数为一级好友数，此榜为一级邀请人数排名",
        thanks: "感谢您陪着币严(WWW.CoinPro.COM)一起成长！",
        headertip: "以上佣金数据非实时更新，系统每24H统计并更新一次。",
        ptools: "推广工具箱",
        pt_title: "数字货币礼品卡",
        pt_desc: "兑换后自动成为下级好友，送朋友、送亲人、送客户、送伙伴。助力推广合伙人更好推广。",
        pt_more: "更多工具，敬请期待",
        pt_tips: "如果您有好的创意，请发送邮件至promotion@coinproex.com，采纳后有奖励哦！",
        pt_card_amount: "卡面金额",
        pt_card_deadline: "有效期间",
        pt_card_noend: "无限期",
        pt_card_btn: "推广合伙人免费领30张(约2000元)",
        pt_card_btn_login: "登录后免费领取30张",
        pt_card_rule: "规则详情",
        pt_card_summary: "数字货币礼品卡是为了让推广合伙人能够更好地邀请下线好友而开发的推广工具之一，推广对象用户兑换礼品卡时，会自动成为推广者的下线好友。",
        pt_card_rule1: "一、免费礼品卡每个用户限领30张。如需发放更多礼品卡，则需要用户付费定制，可定制卡面金额、LOGO、说明文等。定制需求请发送邮件至",
        pt_card_rule2: "二、免费礼品卡每个用户限兑1份。由币严(WWW.CoinPro.COM)官方发放的免费礼品卡，每个用户限兑一份，即使通过不同用户领取到不同兑换码的免费礼品卡，也只能兑换1份。自定义制作礼品卡不受此限制。",
        pt_card_rule3: "三、用户兑换礼品卡前，如不存在“邀请者”（即注册账号时未输入过邀请码），则自动关联为发卡者的一级好友。如A注册账号时未输入邀请码，此时A不属于任何人的一级或者二级好友，但当A通过B发放的礼品卡兑换后，那么A自动成为B的一级好友。",
        pt_card_rule4: "四、为防止刷客，礼品卡账面金额并非实时兑付，领取后需完成实名认证才可操作。",
        pt_card_rule5: "五、领取免费礼品卡时需要完成实名认证，兑换时无需完成实名认证即可领取，此举是为了方便推广者更快获取下级好友。",
        pt_card_rule6: "六、免费礼品卡最终解释权归WWW.CoinPro.COM所有。",
        pt_card_day: "天",
        pt_card_title_tips: "领2000元推广助力金",
        pt_card_receivew_success: "恭喜！成功领取合伙人推广礼品卡！请到【个人中心】->【卡券中心】查看！",
        usepromotion: "使用推广素材",
        saveimage: "保存图片",
        imagetips: "如无法正常下载图片，请使用截图工具截图保存！",
        pt_invite_title: "邀请注册宣传图",
        pt_invite_desc: "专属二维码，通过二维码注册自动成为下级好友，适合在各种群或论坛转发。",
        colum_text0: "排名",
        colum_text1: "会员",
        colum_text2: "推广人数",
        colum_text3: "返佣(折合USDT)",
        colum_text4: "额外奖励",
		context_title: "推广合伙人专属兑换码",
		context_title1: "扫一扫了解更多",
		context_title2: "我的邀请码",
		context_title3: "邀请图片"
		
    },
    uc: {
        verify: "实名认证",
        login: {
            noaccount: '没有账号？',
            register: '注册',
            login: '登录',
            welcomelogin: '欢迎登录',
            usertip: '请输入手机号或邮箱',
            pwdtip: '请输入密码',
            validatecodeload: '正在加载验证码',
            validatemsg: '请先完成验证',
            forget: '忘记密码?',
            loginvalidate: '请输入账号',
            pwdvalidate1: '请输入密码',
            pwdvalidate2: '密码长度不能少于6位',
            success: '登录成功',
            goregister: "立即注册",
            getlostpwd: "忘记密码"
        },
        forget: {
            title: "找回密码",
            hasaccount: '已有账号？请点击登录',
            login: '登录',
            sendcode: '发送验证码',
            newpwd: '请输入新密码',
            confirmpwd: '请再次确认密码',
            save: '提交',
            pwdvalidate1: '请输入确认密码',
            pwdvalidate2: '两次密码输入不一致!',
            resettelpwd: '重置手机密码',
            resetemailpwd: '重置邮箱密码',
            newpwdtip: '请输入新密码',
            pwdvalidate3: '密码长度不能少于6位',
            telno: '手机号',
            smscode: '请输入验证码',
            teltip: '请输入手机号',
            smscodetip: '请输入短信验证码',
            email: '邮箱',
            emailcode: '邮箱验证码',
            emailtip: '请输入邮箱',
            emailcodetip: '请输入邮箱验证码',
            resetpwdsuccess: '重置密码成功',
            smswarn: '请注意查收短信',
        },
        finance: {
            center: '财务中心',
            personalassets: '币币账户',
            swapassets: '永续账户',
            billdetail: '资产流水',
            tradetail: '交易挖矿',
            paydividends: '持币分红',
            invitingmining: '邀请挖矿奖励',
            charge: '充币',
            pickup: '提币',
            money: {
                cointype: '币种名称',
                balance: '可用资产',
                frozen: '冻结资产',
                operate: '操作',
                charge: '充币',
                pickup: '提币',
                onkeytrans: "一键转",
                getaddress: '获取地址',
                resetsuccess: '获取成功',
                match: '配对',
                matchtip1: '可配对GCX的数量',
                matchtip2: '请输入配对数量',
                matcherr1: '请输入有效的数量!',
                matcherr2: '超出最大配对数量!',
                matchsuccess: '配对成功!',
                needreleased: "待释放资产",
                totalassets: "总资产折合："
            },
            swap: {
                totalassets: "账户权益折合：",
                swap: " 永续账户",
                swaptype: '合约账户',
                swapassets: "账户权益",
                avabalance: "可用保证金",
                usebalance: "已用保证金",
                frozenbalance: "冻结保证金",
                profitandloss: "未实现盈亏",
                transfor: "资金划转",
                inputtransferamount: "划转数量",
                all: "全部划转",
                currencyaccount: "币币账户",
                swapaccount: "永续合约账户",
                clickchange: "点击切换划转方向",
                avaamount: "可转额度",
                pleaseselectwallet: "请先选择转入/转出账户",
                pleaseinputamount: "请输入划转数量",
                oktransfer: "确认划转"
            },
            record: {
                start_end: '起止时间',
                to: '至',
                operatetype: '操作类型',
                search: '搜索',
                charge: '充值',
                pickup: '提现',
                transaccount: '转账',
                exchange: '币币交易',
                otcbuy: '法币买入',
                otcsell: '法币卖出',
                activityaward: '活动奖励',
                promotionaward: '推广奖励',
                dividend: '分红',
                vote: '投票',
                handrecharge: '人工充值',
                match: '配对',
                activitybuy: "活动兑换",
                ctcbuy: "CTC买入",
                ctcsell: "CTC卖出",
                redout: "发红包",
                redin: "领红包",
                chargetime: '交易时间',
                type: '类型',
                fee: '手续费',
                shouldfee: '应付手续费',
                discountfee: '抵扣手续费',
                realfee: '实付手续费',
                symbol: '币种',
                num: '数量',
                status: '状态',
                finish: '已完成',
                activitybuy: "活动兑换",
                ctcbuy: "C2C买入",
                ctcsell: "C2C卖出",
                quickExchange: "闪兑",
                currentRate: "当前兑换比率",
                from: "从",
                inputexchangeamount: "输入闪兑数量",
                inputexchangepasswd: "输入交易密码",
                chooseTransCoinUnit: "选择转化币种",
                inputTransAmount: "输入转化数量",
                predictAmount: "预计可兑数量",
                qamount: "闪兑数量",
                qrate: "兑换比例",
                qexamount: "兑换数量",
                withdrawcodein: "提币码充币",
                withdrawcodeout: "提币码提币",
                contractfee: "永续合约手续费",
                contractprofit: "永续合约盈利",
                contractloss: "永续合约亏损",
                optionfail: "预测合约失败",
                optionfee: "预测合约手续费",
                optionreward: "预测合约奖金",
                other: "其他"
            },
            paydividende: {
                money_holding: '持币分红已返还累计(ETH) : ',
                money_hold: '持币分红待返还累计(ETH) : ',
                paydividends: '持币分红(ETH)',
                account_date: '到帐时间',
                datehodld: '持币日期',
                start_end: '起止时间',
                to: '至',
                operatetype: '操作类型',
                search: '搜索',
                charge: '充值',
                pickup: '提现',
                transaccount: '转账',
                exchange: '币币交易',
                otcbuy: '法币买入',
                otcsell: '法币卖出',
                activityaward: '活动奖励',
                promotionaward: '推广奖励',
                dividend: '分红',
                vote: '投票',
                handrecharge: '人工充值',
                match: '配对',
                chargetime: '交易时间',
                type: '类型',
                fee: '手续费',
                symbol: '币种',
                num: '数量',
                status: '状态',
                finish: '已完成',
            },
            trade: {
                accumulative_return: '累计挖矿(BHB) : ',
                accumulat_return: '待挖矿(BHB) : ',
                start_end: '起止时间',
                account_date: '到帐时间',
                to: '至',
                operatetype: '操作类型',
                search: '搜索',
                charge: '充值',
                pickup: '提现',
                transaccount: '转账',
                exchange: '币币交易',
                otcbuy: '法币买入',
                otcsell: '法币卖出',
                activityaward: '活动奖励',
                promotionaward: '推广奖励',
                dividend: '分红',
                vote: '投票',
                handrecharge: '人工充值',
                match: '配对',
                chargetime: '订单生成时间',
                type: '类型',
                fee: '挖矿手续费返还(BHB)',
                num: '数量',
                status: '状态',
                finish: '已完成',
                transactionTime: '交易时间',
                symbol: '交易对',
                direction: '交易方向',
                price: '价格',
                entrustment: '委托量',
                havedeal: '已成交',
                poundageAmount: '手续费',
                exchangeOrderId: "订单ID",
                mineAmount: "挖币数量 (BHB)",
                buy: "买入",
                sell: "卖出",
                limit: "限价",
                market: "市价",
                clear: "清空条件",
                turnover: "成交金额"
            },
            inviting: {
                accumulative_return: '邀请挖矿奖励已返还累计(BHB) : ',
                accumulat_return: '邀请挖矿奖励待返还累计(BHB) : ',
                start_end: '起止时间',
                account_date: '到帐时间',
                to: '至',
                operatetype: '操作类型',
                search: '搜索',
                charge: '充值',
                pickup: '提现',
                transaccount: '转账',
                exchange: '币币交易',
                otcbuy: '法币买入',
                otcsell: '法币卖出',
                activityaward: '活动奖励',
                promotionaward: '推广奖励',
                dividend: '分红',
                vote: '投票',
                handrecharge: '人工充值',
                match: '配对',
                chargetime: '订单生成时间',
                type: '类型',
                fee: '挖矿手续费返还(BHB)',
                symbol: '币种',
                num: '数量',
                status: '状态',
                finish: '已完成',
                refereename: '被推荐人姓名',
                referetel: '被推荐人手机号',
                invitingawards: '邀请挖矿奖励(BHB)',
                refere: '被推荐人',
                refereinput: '请输入姓名/手机号'

            },
            recharge: {
                recharge: '充币',
                symbol: '币种',
                address: '充币地址',
                copy: '复制',
                qrcode: '二维码',
                qrcodeaddress: '充值地址二维码',
                msg1: '请勿向上述地址充值任何非币种资产，否则资产将不可找回。',
                msg2: '您充值至上述地址后，需要整个区块链网络节点的确认，一般区块链主网3次网络确认后到账。',
                msg3: '最小充值金额：',
                msg3_1: "，小于最小金额的充值将不会上账。",
                msg4: '您的充值地址不会经常改变，可以重复充值；如有更改，我们会尽量通过网站公告或邮件通知您。',
                msg5: '请务必确认电脑及浏览器安全，防止信息被篡改或泄露。',
                record: '充值记录',
                copysuccess: '复制成功！',
                copyerr: '复制失败！请手动复制',
                time: '到账时间',
                amount: '充值数量',
                gettingaddress: "获取充值地址中，请稍候...",
                getaddress: "获取充值地址",
                memotips: "充值时请按要求输入此Memo，否则会丢失资产。",
                withdrawrecharge: "提币码充值",
                coderechargetip: "请输入提币码",
                rechargesuccess: "恭喜！提币码充值成功！",
                rechargeconfirm: "充值确认",
                status: "状态"
            },
            withdraw: {
                pickup: '提币',
                addressmanager: '提币地址管理',
                withdrawbycode: '提币码提币',
                withdrawcode: '提币码',
                symbol: '币种',
                address: '提币地址',
                num: '提币数量',
                avabalance: '可用余额',
                msg1: '提币数量低于',
                msg2: '时自动到账，否则需要人工审核',
                increase: '提升额度',
                tip1: '最多输入',
                tip11: '位小数,最小值为',
                tip2: '最大值为',
                numtip1: '输入提币数量',
                fee: '手续费',
                range: '范围',
                arriamount: '到账数量',
                msg3: '最小提币数量为',
                msg4: '币',
                msg5: '为保障资金安全，当您账户安全策略变更、密码修改、使用新地址提币，我们会对提币进行人工审核，请耐心等待工作人员电话或邮件联系',
                msg6: '请务必确认电脑及浏览器安全，防止信息被篡改或泄露。',
                record: '提现记录',
                symboltip: '请选择币种',
                addresstip: '请填入地址',
                numtip2: '请填写正确提币数量，最小值为',
                numtip3: '提币数量不得小于手续费',
                amounttip: '请输入提币数量',
                feetip1: '手续费最小值为',
                feetip2: '最大值为',
                time: '提现时间',
                status: '状态',
                status_1: '审核中',
                status_2: '转账中',
                status_3: '失败',
                status_4: '成功',
                remark: '备注',
                add: '添加',
                addresslist: '地址列表',
                safevalidate: '安全验证',
                telno: '手机号码',
                smscode: '手机验证码',
                second: '秒',
                clickget: '点击获取',
                email: '邮箱',
                emailcode: '邮箱验证码',
                save: '保 存',
                delete: '删除',
                telerr: '手机号不正确',
                emailerr: '邮箱不正确',
                codeerr: '验证码不正确',
                remarktip: '请输入备注',
                savemsg1: '保存失败!',
                savemsg2: '保存成功!',
                operate: '操作',
                fundpwdtip: '请输入资金密码',
                click: '点击',
                filtrate: '可筛选',
                txid: "TxId",
                generateCode: "生成提币码",
                withdrawcodelist: "提币码列表",
                withdrawcodestatus: "状态",
                submit: "提交",
                generatecodesuccess: "提现码生成成功"
            }
        },
        member: {
            usercenter: "个人中心",
            securitycenter: '安全中心',
            securitysetting: '安全设置',
            accountsetting: '收款方式',
            account: "账户管理",
            assets: "资金管理",
            exchange: "交易管理",
            curdelegate: "当前委托",
            hisdelegate: "历史委托",
            otc: "OTC管理"
        },
        order: {
            ordercenter: '订单中心',
            myorder: '我的订单',
            myad: '我的广告',
        },
        regist: {
            hasaccount: '已有账号？请点击登录',
            login: '登录',
            username: '用户名',
            country: '国家',
            smscode: '短信验证码',
            sendcode: '发送验证码',
            resendcode: '重新发送(',
            pwd: '登录密码',
            repwd: '确认密码',
            confrimpwd: '确认密码',
            agreement: '我已阅读并同意',
            userprotocol: '用户协议',
            regist: '注册',
            teltip: '请输入手机号',
            telerr: '手机号码格式不正确,请重新输入',
            emailtip: '请输入邮箱',
            emailerr: '邮箱格式不正确,请重新输入',
            confirmpwdtip: '请输入确认密码',
            confirmpwderr: '两次密码输入不一致!',
            telregist: '手机注册',
            emailregist: '邮箱注册',
            usernametip: '请输入用户名',
            usernamemsg: '用户名长度不能少于3位,多于15位',
            countrytip: '请选择国家',
            smscodetip: '请输入短信验证码',
            pwdtip: '请输入登录密码',
            pwdmsg: '密码长度不能少于6位',
            telno: '手机号码',
            email: '邮箱地址',
            agreementtip: '请点击同意',
            modaltitle: '请校验',
            promotion: '邀请码(选填)',
			china: '中国',
            singapore: '新加坡',
            korea: '韩国',
            japan: '日本',
            thailand: '泰国',
            russia: '俄罗斯',
            uk: '英国',
            vietnam: '越南',
            india: '印度',
            italy: '意大利',
            hk: '香港',
            malaysia: '马来西亚',
            taiwan: '台湾省',
            turkey: '土耳其',
            germany: '德国',
            france: '法国',
            spain: '西班牙'
        },
        safe: {
            safelevel_low: '安全等级：低',
            safelevel_high: '安全等级：高',
            safelevel_medium: '安全等级：中',
            nickname: '昵称',
            bind: '绑定',
            binded: '已绑定',
            binding: '审核中',
            binderr: '失败',
            bindretry: '重试',
            verifypass: "已认证",
            verified: '实名认证',
            notverified: "未认证",
            verifiedtip: '为保障您的账户安全，请完成实名认证后方可交易操作！',
            realname: '真实姓名',
            idcard: '身份证号',
            upload: '点击上传',
            upload_positive: '身份证正面照',
            upload_negative: '身份证反面照',
            upload_hand: '手持身份证与个人签名照',
            save: '保 存',
            reset: '重置',
            email: '邮箱',
            bindemail: '绑定邮箱',
            loginpwd: '登录密码',
            emailcode: '邮箱验证码',
            clickget: '点击获取',
            second: '秒',
            phone: '手机',
            bindphone: '绑定手机',
            phonecode: '手机验证码',
            logintip: '登录平台时使用',
            edit: '修改',
            oldpwd: '原登录密码',
            newpwd: '新登录密码',
            confirmnewpwd: '确认新密码',
            fundpwd: '资金密码',
            fundtip: '账户资金变动时，需先验证资金密码',
            set: '设置',
            confirmpwd: '确认密码',
            oldfundpwd: '原资金密码',
            newfundpwd: '新资金密码',
            newpwdmsg1: '请输入不小于6位新登录密码',
            newpwdmsg2: '新登录密码不一致',
            pwdmsg1: '请输入不小于6位密码',
            pwdmsg2: '密码不一致',
            emailtip: '请输入邮箱号',
            codetip: '请输入验证码',
            telnotip: '请输入手机号',
            oldpwdtip: '请输入原密码',
            realnametip: '请输入真实姓名',
            idcardtip: '请输入身份证号码',
            bindphonetip: '请先绑定手机!',
            resetfundpwd: '重置资金密码',
            upload_positivetip: '请上传身份证正面照',
            upload_negativetip: '请上传身份证反面照',
            upload_handtip: '请上传手持身份证照',
            save_success: '保存成功!',
            save_failure: '保存失败!',
            idcard_verifymsg1: "1、支持JPG、PNG格式，文件小于8M；证件上的信息，清晰可见，不允许任何修改和遮挡，必须能看清证件号和姓名。",
            idcard_verifymsg2: "2、照片需免冠，建议未化妆，手持证件人的五官清晰可鉴，完整露出手臂。",
            idcard_verifymsg3: "3、需要同时手持证件和手写文案（姓名+申请日期）'我在本站的所有行为均为本人操作，并已知悉相关风险，愿意承担本账户的一切法律后果。'"
        },
        account: {
            pagetitle: '绑定实名帐号',
            pagetip: '请设置您的收款方式，请务必使用本人的实名账号',
            backcardno: '银行卡账号',
            backcardtip: '个人银行卡信息未绑定',
            modify: '修改',
            bind: '绑定',
            name: '姓 名',
            bankaccount: '开户银行',
            bankbranch: '开户支行',
            bankno: '银行卡号',
            confirmbankno: '确认卡号',
            fundpwd: '资金密码',
            save: '保 存',
            zfbaccount: '支付宝账号',
            zfbaccounttip: '个人支付宝账户未绑定',
            wxaccount: '微信账号',
            wxaccounttip: '个人微信账户未绑定',
            banknomsg1: '请输入正确银行卡号',
            banknomsg2: '两次输入的银行卡号不一致!',
            verifiedmsg: '请先进行实名认证',
            bankaccountmsg: '请选择开户银行',
            bankbranchmsg: '请输入开户支行',
            banknomsg: '请输入正确的银行卡号',
            fundpwdmsg1: '请输入正确的资金密码',
            fundpwdmsg2: '密码不得少于6个字符',
            zfbaccountmsg: '请输入支付宝账号',
            wxaccountmsg: '请输入微信账号',
            save_success: '保存成功!',
            save_failure: '保存失败!',
            imgtip: '请上传您的收款码',
        },
        otcorder: {
            unpaid: '未付款',
            paided: '已付款',
            finished: '已完成',
            canceled: '已取消',
            appealing: '申诉中',
            searchtip: '输入订单编号开始搜索',
            orderno: '订单号',
            created: '创建时间',
            symbol: '交易币种',
            type: '交易类型',
            type_sell: '卖出',
            type_buy: '买入',
            tradename: '交易对象',
            amount: '数量',
            money: '金额',
            fee: '手续费',
        },
        identity: {
            certified: "已认证",
            placeholder: "请填写取消原因",
            apply: '申请成为商家',
            become: '成为CoinPro认证商家， 享更多交易特权',
            zhusnhu: "商家享有专属广告展位，增加交易成功率",
            tijiaoziliao: "提交商家认证资料",
            place: "请您将准备好的商家认证资料上传至平台并提交",
            tijiao: "您的商家认证审核已提交",
            tijiaosuc: "恭喜！您的商家认证审核已通过",
            tijiaofail: "抱歉！您的商家认证审核未通过",
            zhuxiaotijiao: "您的商家注销申请已提交",
            shenhefail: "您的商家注销申请审核未通过",
            shenhesuc: "您的商家注销申请审核已通过",
            shangjiazhuxiao: "商家注销",
            shenheshibai: "审核失败",
            shenagain: "重新审核",
            sheqinggain: "重新申请",
            reason: "原因",
            shenqingtuibao: "申请退保",
            getquan: "您获得以下权限",
            read: "我已阅读并同意",
            lijishenqing: "立即申请",
            tips: "提示",
            wufachexiao: "您正在进行商家注销操作，确认提交申请操作后，将无法撤销。",
            suredo: "是否确认执行此操作？",
            shuzizichan: "数字资产交易证明",
            gerenzichan: "个人数字资产证明",
            second: {
                line: "如何申请成为商家?",
                step1: "第一步：按要求准备商家申请资料",
                step1c1: "准备如下申请资料：",
                step1c2: "手机号、微信号、QQ号、个人数字资产证明(图片)、数字资产交易证明(图片)",
                step2: "第二步：提交申请",
                step2c: "完成需要填写和上传的商家认证审核资料，点击提交审核。",
                step3: "第三步：资料审核",
                stepc: "我们将在3-5个工作日内对您的商家申请资料进行审核，请随时关注审核状态，可在提交页面查看。审核通过后，您即可在法币交易区发布广告。",
                agree: "同意冻结",
                agreec: "作为商家保证金",
                shenqingchngweishangjia: "申请成为商家",
            },
            yuanyin: "原因",
            tijiaoshenqing: "提交申请",
            bizhong: "保证金币种",
            shuliang: "保证金数量",
            chosen: "选择币种",
            seat: '专属展位',
            service: '一对一服务',
            lowfee: '更低手续费',
            phone: '手机',
            balance: '个人资产情况',
            cardphoto: '身份证正反面照片',
            wx: '微信',
            exchange: '是否从事过数字资产的场外交易',
            handphoto: '用户手持身份证照片',
            qq: 'QQ',
            ploy: '是否有相应的风控策略',
            agreement: '《认证商家协议》',
            applyfor: '确认申请',
            sendcode: '发送验证码',
            confirm: '确定',
            prepare: '准备资料',
            review: '提交审核',
            result: "等待结果",
            passed: '审核通过',
            approve: '请同意认证商家协议',
            emailtip1: '请将如下材料用邮件发送至',
            emailtip2: '我们将尽快对您的申请进行审核。',
        },
        extension: {
            title1: '推广链接',
            title2: '推广好友',
            title3: '我的佣金',
            linkdesc: '以下网址是您对外界进行推广的地址，您可以通过朋友、QQ、微信、微博、博客、论坛或者自己的网站进行推广，所有通过该地址访问过来的人，注册后就都属于您的用户，而当这些用户在本站提交策略时，您就可以赚取佣金了，详细的推广情况可到访问记录里查看。',
            linktitle: '您的推广链接',
            copy: '复制',
            copy_msg1: '复制成功！',
            copy_msg2: '复制失败！请手动复制',
            username: '用户名',
            currrecharge: '当前交易',
            userlevel: '推荐级别',
            createdtime: '注册时间',
            currcommission: '当前佣金',
            managerfee: '管理费',
            yuan: '元',
            symbol: '币种',
            amount: '金额',
            remark: '备注',
            amounttime: '发放时间',
        },
        promotion: {
            title: "我的推广",
            subtitle1: "我的推广卡",
            subtitle2: "兑换记录",
            subtitle3: "我的邀请",
            card_column0: "兑换码",
            card_column1: "卡名",
            card_column2: "卡面币种",
            card_column3: "卡面金额",
            card_column4: "有效期",
            card_column5: "总数",
            card_column6: "已兑",
            card_column7: "领取时间",
            my_column0: "邀请用户",
            my_column1: "注册时间",
            my_column2: "实名状态",
            gopromotion: "推广素材",
            inputcardno: "请输入兑换码",
            exchange: "立即兑换",
            exchangesuccess: "兑换成功！请到资产管理查看余额！",
            exchangewithcode: "兑换码兑换"
        },
        activity: {
            navTitle: "创新管理",
            subNavTitle1: "我参与的",
            subNavTitle2: "我的矿机",
            subNavTitle3: "我的锁仓",
            column1: "名称",
            column2: "类型",
            column3: "认购数量",
            column4: "参与币种",
            column5: "认购单位",
            column6: "当前状态",
            column7: "成交额",
            column8: "创建时间",
            mColumn1: "矿机",
            mColumn2: "名称",
            mColumn3: "币种",
            mColumn4: "周期",
            mColumn5: "天数",
            mColumn6: "已挖",
            mColumn7: "状态",
            mColumn8: "基础产能",
            mColumn9: "当前产能",
            mColumn10: "总产出",
            mColumn11: "结束日期",
            mColumn12: "创建日期",
            lColumn1: "锁仓",
            lColumn2: "名称",
            lColumn3: "币种",
            lColumn4: "周期",
            lColumn5: "天数",
            lColumn6: "已释放",
            lColumn7: "状态",
            lColumn8: "基础产能",
            lColumn9: "当前产能",
            lColumn10: "总产出",
            lColumn11: "结束日期",
            lColumn12: "创建日期",
        },
        mining: {
            title: "我的矿机列表",
            empty: "暂无矿机",
            day: "天",
            week: "周",
            month: "月",
            year: "年",
            invitetip1: "本矿机产能可通过邀请好友获得提升，每邀请一人兑换矿机，产能提升",
            invitetip2: "%，产能提升上限",
            miningUnit: "产出币种",
            miningPeriod: "产出周期",
            miningDays: "挖矿时间",
            miningedDays: "已挖时间",
            miningDaysProfit: "基础产能",
            miningCurrentDaysProfit: "实际产能",
            status0: "待部署",
            status1: "已部署",
            status2: "已结束"
        },
        locked: {
            title: "我的锁仓列表",
            empty: "暂无锁仓",
            day: "天",
            week: "周",
            month: "月",
            year: "年",
            invitetip1: "本锁仓产能可通过邀请好友获得提升，每邀请一人参与锁仓，产能提升",
            invitetip2: "%，产能提升上限",
            miningUnit: "释放币种",
            miningPeriod: "释放周期",
            miningDays: "总释放周期",
            miningedDays: "已释放周期",
            miningDaysProfit: "基础释放",
            miningCurrentDaysProfit: "实际释放",
            status0: "待释放",
            status1: "释放中",
            status2: "已结束",
            totallocked: "总锁仓"
        },
        api: {
            apiAdmin: "API管理",
            createApi: "创建新API Key",
            mark: "API备注",
            bindAddress: "授权IP（授权IP地址的密钥有效期为90天）",
            hint: '提示',
            part1: "我们为您提供了强大的API,您可以通过API使用行情查询、自动交易等服务。通过",
            apiDoc: "API文档",
            howUse: "查看如何使用。",
            part2: "每个用户最多创建5组API Key",
            part3: "请不要泄露您的API Key，以免造成资产损失。 出于安全考虑，建议为API Key绑定IP，每个API Key最多绑定5个Ip。单个地址直接填写，多个IP地址用半角逗号分隔，如：192.168.1.1,192.168.1.2,192.168.1.3。",
            myApiKey: "我的API Key",
            ipAddress: "ip地址",
            createTime: "创建时间",
            accessKey: "访问密钥",
            bindIpAddress: "绑定IP地址",
            ioDays: "剩余有效期（天）",
            operation: "操作",
            create: "创建",
            edit: "编辑",
            delete: "删除",
            sureDelete: "确认删除吗",
            tips: "密钥只在新增时展示，请及时保存",
            copy: "复制",
            access: "密钥",
            okText: "确认",
            cancel: "取消"
        }
    },
    cms: {
        noticecenter: '公告中心',
        newshelp: '新手帮助',
        appdownload: 'APP下载',
        onlineservice: '在线客服',
        faq: '常见问题',
        notice: '公告',
        servicecenter: '客服中心',
        about: '关于',
        joinus: '加入我们',
        aboutus: '关于我们',
        exchangerule: '交易规则',
        useprotocol: '使用协议',
        feenote: '资费说明',
        merchantprocotol: '商家协议',
        features: "平台特色",
        contactus: "联系我们",
        noticelist: '公告列表',
        nextnotice: '下一篇',
        prevnotice: "上一篇",
        notexist: "您访问的公告不存在或已被删除！",
        otherhelp: "此组别内的文章",
        scanforshare: "扫一扫分享",
        download: "立即下载",
        downloadslogan: "币严APP - 全球数字资产交易平台"
    },
    description: {
        // message1: 'SSL、动态身份验证等银行级别安全技术，\n保障交易安全；支持多种数字货币交易',
        // message2: '严格的项目准入门槛，确保平台用户利益；\n100%保证金，钱包冷热隔离，确保用户资金安全',
        // message3: 'CoinPro数字资产交易平台支持每秒1000笔交易，给用户带来酣畅淋漓\n的交易体验',
        title1: '金融级安全',
        title2: '极速充提',
        title3: '全球服务',
        title4: '严选资产',
        message1: '全方位金融风控系统和防盗系统，冷热钱包、多签系统保证资金安全',
        message2: '充值提现最快3分钟完成，24H人工在线审核，保护客户不错过最佳投资机会',
        message3: '全球业务服务网络覆盖，助您投资全球加密资产，与全球用户交易',
        message4: '严格选择优质加密项目，为您过滤80%极高风险项目',
        message5: '币严(WWW.CoinPro.COM)国际数字加密资产交易平台手机客户端，专注掌上交易体验',
        scanqrcode: "扫描二维码，下载APP",
        aboutdesc1: "币严(WWW.CoinPro.COM)是技术全球领先的数字资产交易平台，注册于开曼群岛，核心运营团队位于香港。币严(WWW.CoinPro.COM)核心成员来自于顶级互联网和金融公司，大部分成员是深度比特币和区块链信仰者，我们深信区块链将改变传统垄断性金融体系，会建立一个更加民主、自治的社会结构。",
        aboutdesc2: "币严(WWW.CoinPro.COM)平台拥有专业金融级别的交易架构，拥有自主研发的高并发内存撮合交易引擎，平台采用全冷充值钱包系统+多重签名+高防护DDOS攻击系统等交易架构，保证客户资产安全。",
        aboutdesc3: "币严(WWW.CoinPro.COM)为了让客户更好的把握投资机会，客服部门内部采用扁平化管理，建立了极速服务响应机制，资产客服经理常年7*24H在线，为客户提供资产充值提现等服务，保障客户在25分钟内完成资产充提。",
        aboutdesc4: "币严(WWW.CoinPro.COM)严格筛选优质项目并提供安全稳定的资产托管服务。币严(WWW.CoinPro.COM)秉承着“诚实、公正、热情、开放”的理念，竭力为用户打造安全、可靠、高效和友好的极致交易所。",
        aboutdesc5: "尽管数字资产现在只在一个小小的圈子里，但是我们相信，这种每个人能够完全掌握的资产在未来一定能够流行起来，让我们一起努力并期待着！",
        support: "技术支持",
        report: "投诉建议",
        service: "客户服务",
        apply: "上币申请",
        coop: "商务合作",
        community: "币严社区",
        wechat: "微信",
        addwechat: "添加 “bmate601” 为微信好友，进入微信福利社群",
        weibo: "微博",
        twitter: "推特",
        biyong: "币用",
        telegram: "电报",
        medium: "Medium",
        reddit: "Reddit"
    },
    bzb_description: {
        title1: "分红权益",
        title2: "手续费折扣",
        title3: "优先认购",
        title4: "投票权",
        title5: "第一知情权",
        title6: "优先上币",
        title7: "共商决策",
        title8: "更多",
        desc1: "手续费分红、收益分红、上币分红、股权分红等",
        desc2: "交易手续费最高80%折扣返还奖励，细则待定",
        desc3: "IEO首发及Boot促销Token优先认购权利",
        desc4: "参与CoinPro社区投票活动，包括但不限于上币、回购等",
        desc5: "持仓量达一定数量进SVIP群，跟大V零距离接触",
        desc6: "仅限超级节点，拥有推荐上币资格",
        desc7: "仅限超级节点，拥有共商重大决策权利",
        desc8: "更多权益，敬请期待"
    },
    footer: {
        wechatkefu: "微信客服",
        notice: '平台公告',
        changjian: '常见问题',
        shiyong: '使用协议',
        feilv: '费率说明',
        lianxi: '联系邮箱',
        biyong: '币用',
        gsmc: '全球优质数字资产交易所',
        bah: '沪ICP备13026899号-3',
        gsjj: '关于',
        gywm: '关于我们',
        jrwm: '加入我们',
        lxwm: '联系我们',
        yqlj: '友情链接',
        bzzx: '帮助中心',
        xsrm: '新手教程',
        czzn: '充值指南',
        ptsm: '平台说明',
        fysm: '交易规则',
        fwtk: '用户协议',
        fltk: '资费说明',
        wechat: "微信客服",
        xszn: "新手指南",
        cjwt: "常见问题",
        mztk: "免责条款",
        ystk: "隐私条款",
        tkxy: "条款协议",
        gybzb: "关于BZB",
        kfyx: "客服邮箱",
        swhz: "商务合作",
        sbsq: "上币申请",
        jyzn: "交易指南",
        bzzl: "币种资料",
        tsjb: "投诉举报",
        apidoc: "API接口",
        zwkf: "暂未开放"
    },
    financeNav: {
        wdzc: '我的资产'
    },
    index: {
        circulation: "BHB 安全与发展基金流通量",
        hourEth: '今日待分配收入累计折合',
        yesterdayEth: '昨日分配收入累计折合',
        yesterday: '昨日挖矿产出',
        bhbTotal: "BHB 总流通量",
        bhbSecond: "BHB二级市场流通量",
        checkMineprinciple: "查看挖矿原理",
        checkFlowVolum: '检查流通量说明',
        checkLockPosition: '查看锁仓情况',
        BHBregister: "CoinPro账户注册",
        tibi: "提币到账时间及限额",
        line_plane: "BHB上线计划、流通量及手续费返还公告",
        fenpeijizhi: '关于CoinPro收入分配机制的说明',
        jiangli_jihua: "邀请挖矿奖励计划",
        friend_fanhuan: "邀请好友、赚取额外手续费返还"
    },
    plate: {
        title: "平台收入分配方案 (BHB 持有者权益)",
        content1: "正如白皮书所描述的，CoinPro会拿出平台的80% (扣除税费后) 的收入分配给CoinPro持有者，20%的收入用来支持平台的研发及运营。",
        content2: "收入分配以日为一个分配周期，2018年6月6日为首个分配日。之后的每一天，会将前一天累积的所有待分配收入，一次性的按比例分给BHB持有者",
        content3: "（注：1.此处的BHB仅指已经释放的/可流通的BHB，具体请参见【",
        content3_1: '关于BHB流通量/参与收入分配比例的说明',
        content3_2: "2.每小时（整点）快照并计算一次，收入分配执行为每天一次）。",
        hourFenpei: "今日分配收入折合",
        hourTotal: '今日平台总收入折合',
        xiangqing: '今日收入分配详情',
        yesterdaytit: "昨日天分配收入折合",
        yesterdaytotal: "昨日平台总收入折合",
        yesterdayxiangqing: "昨日收入分配详情",
        /*yesterday:{
          total:"平台总手续费",
          allocated:"待分配收入",
        }*/
    },
    feereturn: {
        ruletitle: "返还规则",
        rulecontent: "白皮书里已经对BHB的分配比例有详细的说明。51%比例的BHB通过“交易即挖矿”的方式逐步回馈给交易用户。一旦51%的BHB全部回馈完成，“挖矿”即自动终止。",
        recordtitle: '返还记录',
        recordcontent: '每日（UTC+8，以下同）都会将前一日的用户所产生交易手续费，100%折算成BHB返还给用户，折算价格按前一交易日BHB的均价（均价计算方式为总成交金额/总成交量）。我们将于每日上午11点，开始发放前一日交易手续费折合BHB的返还。',
        /*time:"日期",
        todaycharge:"当日CoinPro均价(ETH)",
        totalChange:'当日总手续费折合(ETH)',
        returnCharge:"当日挖矿手续费返还(BHB)",
        todayChargeReturn:"当日挖矿收入倍增计划返还（BHB）"*/
    }
}
