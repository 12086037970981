module.exports = {
    common: {
        login: 'Log In',
        register: 'Sign Up',
        loginregister: "Login/Register",
        logout: 'Log Out',
        tip: 'Tip',
        logintip: 'please log in first',
        expect: 'Look forward to expectation!',
        delete: 'Are you confirm to delete?',
        nodata: 'No Data',
        set: 'Set',
        update: 'Modify',
        slogan: "The world's top 50 digital currency exchanges",
        subslogan: "State agency guarantees asset security",
        more: "More",
        please: "Please",
        nodata: "No Records",
        pleaseselect: "Select",
        searchplaceholder: "Enter the coin symbol...",
        searchplaceholderswap: "Enter the swap symbol...",
        loading: "Loading...",
        close: "Close",
        ok: "OK",
		fillComplete:"Please fill in the complete information"
    },
    header: {
        index: 'Home',
        otc: 'OTC',
        exchange: 'Exchange',
        xiaoxi: 'Message',
        kefu: 'Help',
        login: 'LOGIN',
        register: 'REGISTER',
        asset: 'ASSET',
        otcOrder: 'ORDER',
        ucenter: 'ACCOUNT',
        service: 'Announcement',
        downloadapp: "Scan the code for download and installation",
        appdownlaod: 'Download APP',
        usercenter: "User Center",
        helpcenter: "HELP CENTER",
        aboutus: "About",
        partner: "Partner",
        bzb: "BZB ECO",
        assetmanage: "Finance",
        trademanage: "Exchange",
        activity: "Lightning",
        lab: "CoinPro Lab",
        ctc: "C2C Exchange",
        labdetail: "CoinPro Lightning Lab",
        invite: "Promoting Partner",
        whitepaper: "White Paper",
        scandownload: "Download App",
        innovationmanage: "Innovation",
        swap1: "Swap(PRO)",
        swap2: "Swap(Ex)",
        swap3: "CFD",
        swap4: "Second",
        cexchange: "QuikExchange",
        option: "Option Contract",
        news: "News"
    },
    sectionPage: {
        ptaqTitle: 'Platform Security',
        ptaqContent: "The hash algorithm is used to encrypt the world's leading security authentication",
        ptslTitle: 'Platform Strength',
        ptslContent: "The world's largest bitcoin trading platform",
        newsTitle: 'News Center',
        brandTitle: "About CoinPro",
        brandDetail: "Honest  |  Fair  |  Enthusiasm  |  Open",
        brandDesc1: "CoinPro was founded by a group of early Bitcoin participants and geeks. The core members of the team are from well-known enterprises such as Google, Microsoft, Alibaba, Tencent and so on. It has deep research and development strength and rich experience in Internet product operation.",
        brandDesc2: "CoinPro is located in the basic service provider of block chain, dedicated to providing high-quality encrypted assets trading platform for global users, adhering to the basic principle of “DO NOT BE EVIL”, upholding honest, fair and enthusiastic service to customers, and welcoming all partners/projects that are beneficial to the fundamental interests of users with an open attitude.",
        gettingstart: "HOT | Beginner's Guide",
        officialstart: "CoinPro Official Customer Service",
        oneminutebuy: "Currency Transaction",
        oneminutebuytips: "Use one digital asset to directly exchange for another",
        baseknow: "Contract Transaction",
        baseknowtips: "T+0 two-way trading virtual goods as a barter contract",
        baseexchange: "Option Contract",
        baseexchangetips: "Is a digital asset derivative",
        usersocial: "Quick Exchange",
        usersocialtips: "Quickly exchange digital assets",
        mainboard: "Main Board",
        preview: "Previous",
        nextpage: "Next",
        downloadwhite: "Download White Paper"
    },
    nav: {
        ptjy: 'Platform Trading',
        ptaq: 'Platform Security',
        ptsl: 'Platform strength',
        xwzx: 'News Center',
        fabu: 'advertising',
    },
    progress: {
        already: "Have to dig",
        total: "Amount of excavable ore 6 billion BHB"
    },
    service: {
        USDT: 'USDT',
        BTC: 'BTC',
        ETH: 'ETH',
        CUSTOM: 'Favor',
        COIN: 'Coin',
        symbol:'Transaction pair',
        favor:'Favor',
        NewPrice: "Lastest",
        ExchangeNum: 'Amount',
        Change: 'Change',
        OpenPrice: 'Open Price',
        PriceTrend: 'Price Trend',
        Operate: 'Operate',
        Exchange: 'Exchange',
        trading: "Exchange",
        high: "Higher",
        low: "Lower"
    },
    exchange: {
        coin: 'Coin',
        symbol:'Transaction pair',
        lastprice: 'Lastest',
        daychange: 'Change',
        market: 'Markets',
        favorite: 'Marked',
        do_favorite: 'Collected',
        cancel_favorite: 'Cancel Collected',
        type: "Type",
        dealamount: "Finish Amount",
        high: 'High',
        low: 'Low',
        vol: '24H Vol',
        buy: 'Buy',
        sell: 'Sell',
        limited_price: 'Limited Price',
        market_price: 'Market Price',
        fees_rate: 'fee rate',
        balance: 'balances',
        or: 'or',
        starttrade: 'Trade Now!',
        canuse: 'Available',
        recharge: 'Deposit',
        buyprice: 'Buy Price',
        buynum: 'Buy Amount',
        amount: 'Amount',
        buyin: 'Buy',
        disablebuyin: "Market Buy Disabled",
        buytip: 'Optimum Buy',
        sellprice: 'Sell Price',
        sellnum: 'Sell Amount',
        sellout: 'Sell',
        disablesellout: "Market Sell Disabled",
        selltip: 'Optimum Sell',
        curdelegation: 'Delegate',
        hisdelegation: 'History Delegate',
        realtransaction: 'Market Trades',
        num: 'Amount',
        price: 'Price',
        direction: 'Direction',
        time: 'Time',
        stall: 'Stall',
        total: 'Total',
        traded: 'Done',
        action: 'Action',
        undo: 'Undo',
        delegationnum: 'Delegate Amount',
        done: 'Done',
        status: 'Status',
        finished: 'Finished',
        canceled: 'Canceled',
        parted: "Parted",
        tip: 'Tip',
        buyamounttip: 'enter buy amount',
        buyamounttipwarning: 'buy amount not more',
        success: 'submit success!',
        pricetip: 'enter buy price',
        pricetipwarning: 'buy price not more',
        sellamounttip: 'enter sell amount',
        sellamounttipwarning: 'sell amount not more',
        sellpricetip: 'enter sell price',
        sellpricetipwarning: 'sell price not more',
        undotip: 'Confirm Undo?',
        cancelsuccess: "Cancel order Successful",
        marketprice: 'Market Price',
        expand: {
            time: 'time',
            price: 'price',
            amount: 'amount',
            fee: 'fee',
        },
        realtime: 'realtime',
        kline: "KLine",
        depth: "Depth",
        publishstate0: "It is about to begin. Please wait....",
        publishstate1: "It is about to end. Please wait...",
        publishstate2: "Order consolidation, Please wait...",
        publishstate3: "Consolidation is over, Please trade free....",
        publishdetail: "More details",
        publishprice: "Price",
        publishamount: "Amount",
        publishcounttxt0: "Start After",
        publishcounttxt1: "End After",
        publishcounttxt2: "Free Trade After",
        publishcounttxt3: "Activity over!",
        dateTimeDay: "D",
        dateTimeHour: "H",
        dateTimeMinutes: "M",
        dateTimeSeconds: "S",
        moredetail: "View More"
    },
    swap: {
        contract: "Contract",
        swapindex: "SWAP",
        coin: 'Coin',
        symbol:'Coin Pair',
        volume: "Volume",
        lastprice: 'Lastest',
        daychange: 'Change',
        market: 'Markets',
        favorite: 'Marked',
        do_favorite: 'Collected',
        cancel_favorite: 'Cancel Collected',
        type: "Type",
        dealamount: "Finish Amount",
        high: 'High',
        low: 'Low',
        vol: '24H Vol',
        buy: 'Buy',
        sell: 'Sell',
        limited_price: 'Limited Price',
        market_price: 'Market Price',
        spot_price: 'Trigger Price',
        fees_rate: 'fee rate',
        balance: 'balances',
        or: 'or',
        starttrade: 'Trade Now!',
        canuse: 'Available',
        recharge: 'Deposit',
        buyprice: 'Buy Price',
        buynum: 'Buy Amount',
        amount: 'Amount',
        buyin: 'Buy',
        disablebuyin: "Market Buy Disabled",
        buytip: 'Optimum Buy',
        sellprice: 'Sell Price',
        sellnum: 'Sell Amount',
        sellout: 'Sell',
        disablesellout: "Market Sell Disabled",
        selltip: 'Optimum Sell',
        curdelegation: 'Delegate',
        hisdelegation: 'History Delegate',
        realtransaction: 'Market Trades',
        num: 'Amount',
        price: 'Price',
        direction: 'Direction',
        time: 'Time',
        stall: 'Stall',
        total: 'Total',
        traded: 'Done',
        action: 'Action',
        undo: 'Undo',
        delegationnum: 'Delegate Amount',
        done: 'Done',
        status: 'Status',
        finished: 'Finished',
        canceled: 'Canceled',
        parted: "Parted",
        tip: 'Tip',
        buyamounttip: 'enter buy amount',
        buyamounttipwarning: 'buy amount not more',
        success: 'submit success!',
        pricetip: 'enter buy price',
        pricetipwarning: 'buy price not more',
        sellamounttip: 'enter sell amount',
        sellamounttipwarning: 'sell amount not more',
        sellpricetip: 'enter sell price',
        sellpricetipwarning: 'sell price not more',
        undotip: 'Confirm Undo?',
        cancelsuccess: "Cancel order Successful",
        marketprice: 'Market Price',
        expand: {
            time: 'time',
            price: 'price',
            amount: 'amount',
            fee: 'fee',
        },
        realtime: 'realtime',
        kline: "KLine",
        depth: "Depth",
        open: "Open",
        close: "Close",
        latestdeal: "Latest deal",
        myswapaccount: "My Swap Account",
        accountmode: "Pattren",
        accountmargin: "Leverage",
        accountquanyi: "Equity",
        profitandloss: "Unrealized P/L",
        principalAmount: "Avail. Margin",
        positionAmount: "Used Margin",
        frozenAmount: "Frozen Margin",
        principalRate: "Margin Ratio",
        zijinhuazhuan: "Transfer",
        up: "L",
        down: "S",
        canup: "Max. L",
        candown: "Max. S",
        cancloseup: "Max(S)",
        canclosedown: "Max(L)",
        openup: "Open Long",
        opendown: "Open Short",
        closeup: "Close Short",
        closedown: "Close Long",
        currentposition: "Open Positions",
        assetsdetail: "Transactions",
        marginMode1: "Fixed",
        marginMode2: "Cross",
        modifyMarginModeTitle: "Modify Account Margin Mode",
        modifyLeverage: "Adjust Leverage",
        moredetail: "View More",
        shareNumber: "Cont",
        entrustprice: "Price",
        triggleprice: "Trigger",
        openvolume: "Long Volume",
        closevolume: "Short Volume",
        triggleplaceholder: "The default price is market",
        marketpriceplaceholder: "Will trade by the best price",
        levmaxtip: "The leverage should not above the Max",
        levmintip: "The leverage should not below the Min",
        marginModeSuccessTip: "Modify success.",
        noenoughbalance: "Insufficient balance",
        pleaseinputopenvolume: "The volume should above 0",
        pleaseinputcorrectopenvolume: "Please input correct volume",
        pleaseinputrightentrustprice: "Plwase input correct price",
        noenoughposition: "Insufficient position",
        pleaseinputclosevolume: "The volume should above 0",
        pleaseinputcorrectclosevolume: "Please input correct volume",
        pos_Pl: "PL.",
        pos_Ratio: "PL Ratio",
        pos_pos: "Position",
        pos_canClose: "Ava Position",
        pos_openPrice: "Open Price",
        pos_leverage: "Leverage",
        pos_margin: "Cur Margin",
        pos_mRatio: "Margin Ratio",
        pos_cmRatio: "Mainten Ratio",
        pos_long: "Long",
        pos_short: "Short",
        ent_entrustType: "Open/Close",
        ent_direction: "Direction",
        ent_type: "Type",
        ent_trigglePrice: "Trigger Price",
        ent_entrustPrice: "Entrust Price",
        ent_tradedPrice: "Traded Price",
        ent_margin: "Margin",
        ent_volume: "Volume",
        ent_status: "Status",
        ent_status1: "Entrusting",
        ent_status2: "Canceled",
        ent_status3: "Entrust Failed",
        ent_status4: "Traded",
        ent_statusblast: "Blast",
        ent_pl: "P/L",
        ent_fee: "Fee",
        triggerpriceshouldbigger: "The trigger price should above 0"
    },
    ctc: {
        title: "One-click Sale ● Platform Guarantee ● Security",
        desc:"Crypto assets are managed by CoinPro, and the acceptor provides exchange services for exchange.",
        buyin: "Buy",
        sell: "Sell",
        buyprice: "Buy Price",
        sellprice: "Sell Price",
        buynum: "Buy Amount",
        sellnum: "Sell Amount",
        payamount: "Payment",
        getamount: "Payment",
        avabalance: "Available",
        time: "Trade Date",
        tip: "Notice",
        notice: "Notice",
        notice1: "1. The legal currency trading area is an asset transaction between users and acceptors. The funds are not transferred to a platform, and the platform does not accept RMB recharge/remittance；",
        notice2: "2. The acceptors of legal currency transactions have passed real-name certification, offering transaction margins, and the tokens are hosted by the platform, so you can be assured that they can be converted；",
        notice3: "3. The service time of the acceptor is 09:00-21:00 per day. The acceptance is completed within 30 minutes after the acceptance of the order, and the two-hour uncompleted transaction is cancelled；",
        notice4: "4. In order to support bank cards in the legal currency trading area, it is necessary to transfer funds using an account that you have authenticated by your real name；",
        notice5: "5. In order to ensure the security of transaction funds, the legal currency transaction requires users to pass real-name authentication；",
        payType: "Pay Account",
        receiveType: "Receive Account",
        moneyTips: "The above price is for reference only. Please refer to the actual settlement amount after the following order.",
        verifyset: "RealName/Asset Password",
        payset: "Payment Settings",
        trade: " Exchange",
        input50tips: "Please enter a number between 50 and 50,000",
        bank: "Bank Card",
        alipay: "Alipay",
        wechatpay: "Wechat",
        payaccountset: "Pay Account Settings",
        useselfaccount: "Please be sure to use your real-name authenticated account.",
        orderSn: "Order No.",
        tradetime: "Exchange Date",
        direction: "Direction",
        amount: "Amount(USDT)",
        money: "Total(CNY)",
        price: "Price(CNY)",
        status: "Status",
        operate: "Operate",
        moredetail: "More details"
    },
    cexchange: {
        title: "BZB Activity",
        desc: "BZB Coin ● One-click ● Profit everyday",
        quantity: "ActivityPurchase Quantity",
        inputtips1: "The number of custom",
        choosecurrency: "Choose a currency",
        all: "All",
        totalpay: "Currency Amount paid",
        restricted: "The total restricted today",
        currentprice: "Current price",
        nextprice: "Next issue price",
        confirmpay: "Confirm payment",
        assets: "Assets：",
        loginFirst: "Please login",
        success: "Congratulations, Exchange successful!",
        tip: "Tips",
        confirm: "Confirm tips",
        confirmmsg: "Are you sure to exchange BZB?",
        ok: "OK",
        cancel: "Cancel"
    },
    option: {
        title: "Option Contract",
        buyupreward: "Buy Reward Pool",
        buydownreward: "Sell Reward Pool",
        quantity: "Bet Amount",
        myamount: "My Bet Amount",
        buyup: "Price Up",
        buydown: "Price Down",
        history: "History",
        seriers: "No.",
        period: "",
        currentoption: "Time Range",
        openprice: "Open Price",
        currentprice: "Curent Price",
        openprice1: "Open Price",
        closeprice: "Close Price",
        opentime: "Open Time",
        closetime: "Close Time",
        progress: "Progress",
        loginFirst: "Please login first!",
        selectAmount: "Please select your amount",
        balancenotenough: "Banlance is not enough!",
        betsuccess: "Congratulations！Bet successfull！",
        noopening: "No opening now!",
        col_createTime: "Create Time",
        col_direction: "Direction",
        col_result: "Result",
        col_optionNo: "No.",
        col_betAmount: "Bet Amount",
        col_rewardAmount: "Reward Amount",
        col_fee: "Open Fee",
        col_winfee: "Reward Fee",
        win: "Win",
        lose: "Lose",
        wait: "Opening",
        avaliablebalance: "Avaliable Balance",
        login: "Please Login",
		kline: "Real-Time Quotes"
    },
    otc: {
        ad: 'Advertising',
        buyin: 'Buy',
        sellout: 'Sell',
        merchant: 'Merchant',
        applymerchant: 'Apply Merchant',
        volume: 'Volume',
        paymethod: 'Pay Method',
        amount: 'Amount',
        price_coin: 'Price/Coin',
        operate: 'Action',
        validate: 'Please first Verified',
        sell: 'sell',
        buy: 'buy',
        transaction: 'Trading transactions',
        buttontxt: "Become Merchant",
        title: "OTC Exchange",
        desc: "Convenient, safe and fast sale of digital currency",
        title1: "Dynamic Tuning",
        desc1: "Real time fluctuation according to market price",
        title2: "No Fee",
        desc2: "What you see is what you get. Besides the selling price, there is no service charge for the platform",
        title3: "Immediate Transaction",
        desc3: "The introduction of platform services for merchants, intelligent matching, order closing, no need to wait for matchmaking",
        title4: "Platform Guarantee",
        desc4: "Platform certification business, security is guaranteed, 24H customer service for the transaction escort",
        myad: {
            title: 'My Ad',
            post: 'Post An Ad',
            alert: '【Tip】：When the minimum amount of advertising purchases plus the fee is greater than the remaining number of advertisements, the ad is automatically taken off the shelf.',
            no: 'no',
            type: 'type',
            sell: 'sell',
            buy: 'buy',
            limit: 'limit',
            remain: 'remain amount',
            coin: 'coin',
            created: 'created date',
            operate: 'action',
            errmsg: 'Can be edited and modified after the ad is down',
            update: 'edit',
            shelf: 'shelf',
            dropoff: 'dropoff',
            delete: 'delete',
        },
        myorder: 'My Order',
        chatline: {
            status_1: 'The buyer did not pay and waited for the buyer to pay！',
            status_2: 'The buyer has paid and waits for the seller to release！',
            status_3: 'Order completed transaction！',
            status_4: 'Order is being appealed！',
            status_5: 'Order cancelled！',
            loadmore: 'Load more',
            warning: 'Anti-fraud alerts: In the recent past, fraudsters have repeatedly used bank transfer remittance information and fake remittance credentials for fraud, so please be sure to check your own payment account number. Ensure the safety of remittance funds and avoid the risk of bank cards being frozen！',
            contenttip: 'Please enter chat content Enter key to send',
            contentmsg: 'Message cannot be empty',
        },
        chat: {
            seller: 'Seller',
            buyer: 'Buyer',
            exchangeamount: 'Exchange amount',
            operatetip: 'Operate tip',
            operatetip_1: 'Please complete the payment in accordance with the account given by the other party within the time limit and click on this page.',
            finishpayment: 'payment completed',
            operatetip_1_1: 'Please note in the comments when you transfer the good payment reference number',
            operatetip_1_2: 'After receiving the payment, the seller will confirm the payment on the website. The system will automatically send the digital assets you have purchased to your account. Please note that',
            note: 'Note',
            notetip: 'Please do not use other chat software to communicate with each other, and do not accept any documents, email attachments, etc. sent to you by the direction. All communication links are completed in the chat window on this page.',
            operatetip_2_1: 'The digital assets that you have sold have been submitted to the platform for hosting and freezing. ou have confirmed your payment, click',
            operatetip_2_2: 'Please do not believe any reason for urging the currency to be released, confirm the receipt of the money and release the digital assets to avoid loss！',
            operatetip_2_3: 'After receiving the account short message, please be sure to log in to online banking or mobile banking to confirm whether the payment is accounted for, to avoid the false release of digital assets due to receiving fraudulent messages！',
            confirmrelease: 'Confirm release',
            paydigital: 'Pay digital assets',
            orderstatus: 'Order status',
            orderstatus_1: 'payment completed',
            orderstatus_2: 'Order appeal',
            orderstatus_3: 'Confirm release',
            orderstatus_4: 'cancel the deal',
            orderstatus_5: 'Order appeal',
            order: 'Order',
            and: 'and',
            transition: 'exchange',
            transprice: 'Trading price',
            transnum: 'Trading num',
            transmoney: 'Trading amount',
            tip1: 'The user has not added the bank card number yet',
            tip2: 'The user has not added the Alipay account for the time being',
            tip3: 'The user has not added the WeChat account yet',
            zfb: 'Alipay',
            wx: 'WeChat',
            qrcode: 'QRCode',
            msg1: 'Are you sure you have paid?',
            msg2: 'Payments are not refundable! Are you sure to cancel your order?',
            msg3: '【Repeat】：Payments are not refundable!Are you sure to cancel your order?',
            msg4: 'Paid, not received',
            msg5: 'Already paid, not received',
            tip: 'Tip',
            comptype: 'Complaint type',
            compremark: 'Complaint note',
            willcomp: 'I want to complain',
            msg6: 'Do you confirm currency release?',
            result_1: 'waiting for payment',
            result_2: 'Waiting for release',
            result_3: 'Completed',
            result_4: 'Appealing',
            result_5: 'Canceled',
            msg7: 'Fund Password',
            msg7tip: 'please enter fund password',
        },
        checkuser: {
            emaildone: 'Email certified',
            emailundo: 'Email unauthorized',
            teldone: 'Telno certified',
            telundo: 'Telno unauthorized',
            idcarddone: 'IDCard certified',
            idcardundo: 'IDCard unauthorized',
            language: 'language',
            languagetext: 'Chinese',
            registtime: 'Registration time',
            exchangetimes: 'Accumulated transactions',
            exchangeinfo: 'Trading Information',
            tablabel1: 'Sell online',
            tablabel2: 'Buy online',
            col_symbol: 'Coin',
            col_paymode: 'Payment method',
            col_num: 'Amount',
            col_price: 'Price',
            col_created: 'Release time',
            col_operate: 'Operate',
            operatemsg: 'Please perform real name authentication first',
            buyin: 'Buy in',
            sellout: 'Sell out',
        },
        tradecenter: {
            postad: 'Post an ad',
            exchange: 'GCC transaction',
            buyin: 'I want buy in',
            sellout: 'I want sell out',
        },
        tradeinfo: {
            emaildone: 'Email certified',
            emailundo: 'Email unauthorized',
            teldone: 'Telno certified',
            telundo: 'Telno unauthorized',
            idcarddone: 'IDCard certified',
            idcardundo: 'IDCard unauthorized',
            exchangetimes: 'Exchange Times',
            price: 'Price',
            num: 'Amount',
            paymethod: 'PayMethod',
            exchangelimitamount: 'Exchange limit',
            location: 'Location',
            location_text: 'China',
            exchangeperiod: 'Exchange period',
            minute: 'min',
            amounttip: 'Please enter the amount',
            numtip: 'Please enter the num',
            remarktip: 'Tell him your request',
            remarktitle: 'Horizon Remarks',
            exchangetitle: 'Trading Information',
            exchange_tip1: 'After you initiate the transaction request, the digital currency is locked in the hosting and protected by the platform. If you are a seller, after you initiate a transaction request, you can top up and wait for the buyer to pay. Buyers pay within the payment deadline. After you receive the payment, you should release the digital currency that is under your custody.',
            exchange_tip2: 'Read before trading《Platform Network Terms of Service》 and FAQs, trading guides and other help documentation.',
            exchange_tip3: 'Beware of liar!Before the transaction, please check the rating received by the user and pay more attention to the newly created account.',
            exchange_tip4: 'Please note，Rounding and price fluctuations may affect the amount of digital currency that is eventually traded.The fixed amount you enter determines the final amount, and the digital currency amount will be calculated from the instant exchange rate at the same time that the request is issued.',
            exchange_tip5: 'Hosting services protect both buyers and sellers of online transactions.In the event of a dispute, we will evaluate all the information provided and release the hosted digital currency to its legal owner.',
            warning1: 'Enter up to 2 decimal places',
            warning2: 'Order amount is',
            warning3: 'Enter up to 8 decimal places',
            warning4: 'The order quantity is',
            confirmbuyin: 'Confirm buy',
            confirmsellout: 'Confirm sell',
            buyin: 'Buy in',
            sellout: 'Sell out',
            warning5: 'Please fill in the order as required',
        },
        publishad: {
            createad: 'Create an ad deal',
            msg1: 'If you often trade, you can create your own trading ad.If you only trade occasionally, we recommend that you search directly',
            msg2: 'Create a trading ad is',
            msg3: 'Free of charge',
            msg4: 'If you want to directly edit the created ads, please check',
            tradead: 'Trading Advertising',
            myad: 'My advertisement',
            iwant: 'I want',
            sellonline: 'sell online',
            buyonline: 'buy online',
            exchangecoin: 'Exchange Coin',
            country: 'Country',
            currency: 'Coin',
            openfixedprice: 'Open fixed price',
            open: 'Open',
            close: 'Close',
            usetip: 'When enabled, your currency price will not fluctuate with the market and the price will not change.',
            premiseprice: 'Premium',
            premisepricetip: 'Please set your premium',
            fixedprice: 'fixed price',
            fixedpricetip: 'Please enter your exchange price',
            marketprice: 'Market reference price',
            marketpricetip: 'Premium refers to what percentage is higher than the current market price',
            exchangeprice: 'Exchange price',
            formual: 'Pricing formula',
            num: 'Amount',
            num_text1: 'Please enter what you want',
            num_text2: 'num',
            exchangeperiod: 'Transaction period',
            exchangeperiod_text1: 'Please enter your trading deadline',
            minute: 'min',
            tip1: 'How much time the buyer can accept transactions, please enter an integer',
            tip2: '【Tip】Can be bound to personal center/Add payment method',
            tip3: 'Please enter your minimum transaction amount',
            tip4: 'Please enter your maximum transaction amount',
            tip5: 'You can fill in your special requirements in the remarks information, such as: the buyer\'s requirements, online time and so on.',
            paymode: 'Pay method',
            minlimit: 'Minimum transaction amount',
            maxlimit: 'Maximum transaction amount',
            remark: 'Remarks',
            openautoreply: 'Open autoreply',
            msg5: 'When enabled, when the user initiates a transaction to you through this ad, the system automatically sends the auto reply phrase you selected to the other party.',
            autoreply: 'AutoReply',
            autoreplytip: 'After receiving the order, the information is automatically returned to the buyer, for example: payment method, collection account number, etc.',
            fundpwd: 'Fund password',
            fundpwdtip: 'Please enter your funds password',
            submit: 'Submit',
            warning1: 'Please enter the correct number',
            warning2: 'The overflow value is 0-20',
            warning3: 'Please enter your maximum transaction amount!',
            warning4: 'Please enter an integer',
            warning5: 'The maximum transaction amount must be greater than the minimum transaction amount!',
            warning6: 'The maximum transaction amount cannot exceed your total sale amount',
            warning7: 'Please enter your minimum transaction amount!',
            warning8: 'The minimum transaction amount must be greater than or equal to 100!',
            warning9: 'The minimum transaction amount must be less than the maximum transaction amount',
            sellout: 'Sell',
            buyin: 'Buy',
            inputtip1: 'Please enter the currency',
            inputtip2: 'Please enter the correct option',
            inputtip3: 'The overflow value is 0-20 and cannot be 0',
            inputtip4: 'Please enter the correct fixed price',
            inputtip5: 'Please enter the correct number, and the maximum number of transactions does not exceed 100',
            inputtip6: 'Please enter the correct trading deadline',
            inputtip7: 'Please select transaction method',
            inputtip8: 'Please enter funds password',
            zfb: 'Alipay',
            wx: 'Wechat',
            unionpay: 'UnionPay',
            submit_failure: 'Failure!',
            submittip1: 'Please proceed with a series of certifications such as real names',
            submittip2: 'Please conduct a series of certifications such as mobile phones',
            submittip3: 'Please proceed with a series of certifications such as capital passwords',
            submittip4: 'Please bind at least one payment method',
        },
        index: {
            title: 'Good advertising recommendation',
            exchangetimes: 'Transaction times',
            exchangeprice: 'Transaction price',
            exchangelimit: 'Transaction limit',
            paymode: 'Pay method',
            buy: 'buy',
            sell: 'sell',
            viewmore: 'More',
            bot1: 'Safe and reliable',
            bot1_tip: 'More than 10 years of experience in the financial risk control team\nCustomizable security policy system',
            bot2: 'Fast and easy',
            bot2_tip: 'Peer-to-peer user free transaction mode support\nKinds of money exchange channels',
            bot3: 'Full currency',
            bot3_tip: 'Selection of mainstream digital asset transaction currencies\nMulti-asset trading experience',
            ibuy: 'I want buy',
            isell: 'I want sell',
        }
    },
    activity: {
        all: "All",
        prepare: "Coming",
        ongoing: "Ongoing",
        distributing: "Distributing",
        completed: "Completed",
        progress: "Progress",
        totalsupply: "Total Supply",
        starttime: "Start Date",
        endtime:"End Date",
        viewdetail: "Detail",
        attend: "Attend",
        tokendistribute: "Token Distributing",
        baseinfo: "Activity Info",
        status: "Status",
        activitytype: "Activity Type",
        activitytype0: "Unkonwn",
        activitytype1: "List(FOF)",
        activitytype2: "List(AVA)",
        activitytype3: "HolderDivide",
        activitytype4: "FreeBuy",
        activitytype5: "MiningBuy",
        activitytype6: "Locked",
        ruledetail: "About Rule",
        publishprice: "Publish Price",
        activitycoin: "Activity Coin Symbol",
        accpetcoin: "Accept Coin Symbol",
        holdcoin: "Hold Coin Symbol",
        limittimes: "Personal Limit Times",
        limitamount: "Personal Limit Amount",
        unlimited: "Unlimited",
        alreadyamount: "Already Sell Amount",
        leftamount: "Left Amount",
        attention: "Attention",
        attentiontxt1: "1、Assets will be frozen after submitting for participation in [warehouse partitioning] and [free subscription] type activities until thawing is completed.",
        attentiontxt2: "2、Users may not withdraw their application for participation after participating in the activities of the type of 'Holder Divide' and 'Fress Buy'",
        attentiontxt3: "3、If the activity is cancelled due to the project party and other force majeure factors, the original route shall be returned to the frozen assets.",
        attentiontxt4: "* The right of final interpretation of this activity belongs to the official ownership of CoinPro.",
        inputamount: "Input Amount",
        inputholdamount: "Input Lock Amount",
        mybalance: "Available Balance",
        holdtips: "[HolderDivide] requires lockout until the end of the activity.",
        pleaseinputamount: "Please input amount!",
        pleaseinputholdamount: "Please input hold amount!",
        commitfailed: "Commit Failed！",
        minlimitamountfailed: "The quantity of exchange must not be less than the minimum minimum amount of exchange.！",
        maxlimitamountfailed: "The amount of exchange shall not be greater than the maximum purchase amount.！",
        limittimesfailed: "You have participated in more than the maximum number of individual purchases.！",
        balancenotenough: "Balance is not enough！",
        headertitledesc: "Open Laboratories for Win-win Cooperation among Investors, Projectors and Exchanges",
        currentdivided: "My current position will get",
        leveloneCount: "Limit Level One Count",
        submit: "Submit",
        tipsmobile: "Please use PC web（www.coin.pro）",
        tipsmobile1: "You can enter PC web to attend this activity.",
        intvalue: "Please input int value",
        inputminingamount: "Input Buy Count",
        moreactivity: "More Activities",
        releaseType: "Release Type",
        releaseType0: "Equal release",
        releaseType1: "Isometric release",
        lock: "Lock ",
        releaseDetail: "Release Detail",
        lockday: " Days",
        lockweek: " Weeks",
        lockmonth: " Months",
        lockyear: " Years",
        releasePercent: "Release Percent",
        lockFee: "Lock Fee",
        releaseTimes: "Release Times",
        times: "times"
    },
    invite: {
        myinvitelink: "Promotion Link",
        copy: "Copy",
        copyed: "Copied",
        mylevelone: "Level one friends",
        myleveltwo: "Level two friends",
        mycommission: "Commission income(USDT)",
        extrareward: "Additional rewards",
        partnerlevel: "Partnership level",
        ruledetail: "Rule details",
        ruleprofile1:"[Promotion Partner] is the highest proportion of online Commission return and the longest time of commission return (maximum lifetime commission). Promoters who make corresponding efforts can become real platform ‘partners’ and enjoy the dividend of growing together with CoinPro trading platform. Detailed rules are as follows:",
        ruleprofile2:"",
        ruleprofile3:"",
        ruleprofile4:"",
        ruletext1: "1、In order to preach the concept of digital assets and expand the scale of users, CoinPro launched the “Promotion Partner” program, which is effective for a long time.",
        ruletext2: "2、Inviting friends can be divided into two levels. If A invites B, then B is A's first-level friend. If B invites C, then C belongs to B's first-level friend and also to A's second-level friend.",
        ruletext3: "3、When promoting the registration of friends, the first-level friends of inviters can only be the first-level friends of inviters by links provided by inviters or by manually entering invitation codes of inviters.",
        ruletext4: "4、After the invitee registers with the inviter's invitation code and completes the real-name certification, the Commission incentive will take effect.",
        ruletext5: "5、The time of commission is N months from the beginning of the invitee's real-name certification. According to the different grades, the proportion of commission enjoyed is different.",
        ruletext6: "6、Promotion of commission assets is the transaction fee of lower-level Friend Currency, which is the three market base currencies, namely USDT, BTC and ETH.",
        ruletext7: "7、The proportion and duration of promotion commission are positively correlated with the number of first-level friends. The more the number of promotion, the higher the proportion of enjoyable commission. The specific promotion of commission rebate ratio is as follows：",
        ruletext8: "8、TOP10 or TOP100 will be awarded additional incentives by MKWORLDS from time to time.",
        ruletext9: "9、If other activities are in conflict with the activities of the Promotion Partner, the Partners will be expected to understand whether to change temporarily after consultation.",
        ruletext10: "10、L4(Xunfu), L5(Taishou) and L6(Jiedushi) envoy-level partners are entitled to 5%, 10% and 15% bonuses of the total annual Commission returns, respectively.",
        ruletext11: "11、The right of final interpretation of this activity belongs to CoinPro.",
        ruleexampletitle: "For Example：",
        ruleexamplecontent1: "If the user Xiaoyan invited 100 first-class friends, TA's first-class friends invited five second-class friends, then Xiaoyan had 100 first-class friends and 500 second-class friends. If the average person trades 3000 yuan per day, Xiaoyan's monthly income is about (100 * 3000 * 0.001 * 30% + 500 * 3000 * 0.001 * 10%)* 30 = 7200 / month.",
        ruleexamplecontent2: "If user Xiaoyan invited 1000 first-class friends, TA first-class friends invited 5 second-class friends, Xiaoyan had 1000 first-class friends and 5000 second-class friends. If the average person trades 3000 yuan per day, Xiaoyan's monthly income is about (1000*3000*0.001*50%+5000*3000*0.001*20%)*30=135000/month.",
        lastupdate: "Last updated in",
        laststastic: "Last stastic in",
        level: "Level",
        lv1: "Lizheng",
        lv2: "Xianling",
        lv3: "Zhifu",
        lv4: "Xunfu",
        lv5: "Taishou",
        lv6: "Jiedushi",
        levelonecount: "Number of Levlel one",
        levelonecommission: "Level-One Return Ratio / Return Time",
        leveltwocommission: "Level-Two Return Ratio / Return Time",
        partnerdivide: "Partner Dividend",
        month: "Months",
        alllife: "Lifetime",
        totalcommissionrank: "Promotion of total Commission",
        rank: "No.",
        userid: "User ID",
        rankday: "(Day)",
        rankweek: "(Week)",
        rankmonth: "(Month)",
        rankall: "(All)",
        promotioncount: "Number of Promotions",
        commissionusdt: "Commission(USDT)",
        ranktip1: "The number of invitations is the number of first-class friends. This list ranks the total amount of commissions from large to small.",
        ranktip2: "The number of invitations is the number of first-class friends. The list is ranked by the number of first-class friends invited.",
        thanks: "Thank you for supporting CoinPro！",
        headertip: "The above data is not updated in real time. The system statistics and updates every 24H.",
        ptools: "Promotion Tools",
        pt_title: "Cryptocurrency Gift Card",
        pt_desc: "This is a surprise from the future, to send friends, relatives, customers, partners.",
        pt_more: "More tools is coming",
        pt_tips: "If you have a good idea, please send an email to promotion@coin.pro, with a reward after adoption.！",
        pt_card_amount: "Card Amount",
        pt_card_deadline: "DeadLine",
        pt_card_noend: "Indefinite",
        pt_card_btn: "Get 30 cards for free(≈2000 CNY)",
        pt_card_btn_login: "Please login first",
        pt_card_rule: "Rule Details",
        pt_card_summary: "Digital currency gift card is a promotional tool developed to enable promotional partners to better invite offline friends. When users exchange gift cards, they will automatically become offline friends of promotional partners.",
        pt_card_rule1: "1. Free gift cards are limited to 30 cards per user. If more gift cards need to be issued, users need to pay for customization, which can customize the amount of cards, LOGO, instructions, etc. For customized requirements, please send an email to",
        pt_card_rule2: "2. Free gift cards are limited to one copy per user. Free gift cards issued by the CoinPro government are limited to one for each user. Even if different users receive free gift cards with different exchange codes, only one can be exchanged. Custom gift cards are not subject to this restriction.",
        pt_card_rule3: "3. Before the user exchanges the gift card, if there is no 'inviter' (i.e. the invitation code has not been entered at the time of registering the account), it will be automatically associated with the first-level friend of the card issuer. If A does not enter an invitation code when registering an account, then A does not belong to any one's first or second-level friends, but when A is converted into B's first-level friends through the gift card issued by B, A automatically becomes B's first-level friends.",
        pt_card_rule4: "4. In order to prevent brushing, the book value of gift cards is not paid in real time. It will be frozen for 180 days after collection and released into user account balance automatically after 180 days.",
        pt_card_rule5: "5. When receiving free gift cards, we need to complete the real-name certification. When converting, we can get them without completing the real-name certification. This is to facilitate promoters to get lower-level friends faster.",
        pt_card_rule6: "6. The right of final interpretation of free gift cards belongs to COIN.PRO.",
        pt_card_day: "Days",
        pt_card_title_tips: "Free promotion grant of 2000 CNY",
        pt_card_receivew_success: "Congratulations! Successful collection of partner promotion gift cards! Please go to the Personal Center - > Card and Voucher Center.",
        usepromotion: "Use Promotional Materials",
        saveimage: "Save Image",
        imagetips: "If you can't download pictures properly, please download them using the screenshot tool!",
        pt_invite_title: "Invitation registration leaflet",
        pt_invite_desc: "Automatically become subordinate friends through two-dimensional code registration, suitable for forwarding in various groups or forums.",
        colum_text0: "Rank",
        colum_text1: "Member",
        colum_text2: "Promotion",
        colum_text3: "Commission(USDT)",
        colum_text4: "Extra Reward",
		context_title: "Exclusive Redemption Code for Promotion Partners",
		context_title1: "Scan to learn more",
		context_title2: "My Invitation Code",
		context_title3: "Invitation Image"
    },
    uc: {
        verify: "Finish KYC First",
        login: {
            noaccount: 'No Account？',
            register: 'Sign Up',
            login: 'Log In',
            welcomelogin: 'Welcome',
            usertip: 'Enter phone number or email',
            pwdtip: 'Enter Password',
            validatecodeload: 'Loading verification code',
            validatemsg: 'Please finish verification first',
            forget: 'Forget password?',
            loginvalidate: 'Please input email or phone number',
            pwdvalidate1: 'Please input password',
            pwdvalidate2: 'Password length not less than six',
            success: 'Success',
            goregister: "To Regist",
            getlostpwd: "Forget Password"
        },
        forget: {
            title: "Find Password",
            hasaccount: 'Have a Account？To Log In',
            login: 'Log In',
            sendcode: 'Send',
            newpwd: 'Enter new password',
            confirmpwd: 'Enter new password again',
            save: 'Submit',
            pwdvalidate1: 'Enter confirm password',
            pwdvalidate2: 'Password not same!',
            resettelpwd: 'Reset tel password',
            resetemailpwd: 'Reset email password',
            newpwdtip: 'Enter new password',
            pwdvalidate3: 'Password length not less than six',
            telno: 'Tel number',
            smscode: 'Sms verification code',
            teltip: 'Enter tel',
            smscodetip: 'Enter sms verification code',
            email: 'Email',
            emailcode: 'Email verification code',
            emailtip: 'Enter email',
            emailcodetip: 'Enter email verification code',
            resetpwdsuccess: 'Reset password success',
            smswarn: 'Please check for SMS',
        },
        finance: {
            center: 'Finance center',
            personalassets: 'Currency Assets',
            swapassets: 'Swap Assets',
            billdetail: 'Bill detail',
            charge: 'Deposit',
            pickup: 'Withdrawal',
            tradetail: 'Trade Mining',
            paydividends: 'Pay dividends',
            invitingmining: 'Inviting mining Awards',
            money: {
                cointype: 'Coin',
                balance: 'Balance',
                frozen: 'Frozen Balance',
                operate: 'Action',
                charge: 'Deposit',
                pickup: 'Withdrawal',
                onkeytrans: "Trans",
                getaddress: 'Get Address',
                resetsuccess: 'Reset Success',
                match: 'Match',
                matchtip1: 'Available Match GCX Amount',
                matchtip2: 'Enter Match Amount',
                matcherr1: 'Please enter a valid quantity!',
                matcherr2: 'Exceeds the maximum number of matches!',
                matchsuccess: 'Match Success!',
                needreleased: "Assets to be released",
                totalassets: "Estimated Total Value of Assets："
            },
            swap: {
                totalassets: "Estimated Total Value of Assets：",
                swap: " Swap",
                swaptype: 'SWAP',
                swapassets: "Account Equity",
                avabalance: "Avali Margin",
                usebalance: "Used Margin",
                frozenbalance: "Frozen Margin",
                profitandloss: "Unrealized Pl",
                transfor: "Transfer",
                inputtransferamount: "Transfer Amount",
                all: "All",
                currencyaccount: "Spot Account",
                swapaccount: "Perpetual Swap Account",
                clickchange: "Click to change direction",
                avaamount: "Avaliable",
                pleaseselectwallet: "Please choose a transfer wallet fist.",
                pleaseinputamount: "Please input transfer amount",
                oktransfer: "Transfer Now"
            },
            trade: {
                accumulative_return: 'Mining fees have been repaid (BHB) : ',
                accumulat_return: 'Mining fees should be repaid(BHB) : ',
                start_end: 'Start-End',
                account_date: 'Time for receiving the capital',
                to: 'To',
                operatetype: 'Action Type',
                search: 'Search',
                charge: 'Charge',
                pickup: 'Pick up',
                transaccount: 'Transfer',
                exchange: 'Exchange',
                otcbuy: 'OTC-Buy',
                otcsell: 'OTC-Sell',
                activityaward: 'Activity Award',
                promotionaward: 'Promotion Award',
                dividend: 'Dividend',
                vote: 'Vote',
                handrecharge: 'Artificial recharge',
                match: 'Match',
                activitybuy: "Activity Buy",
                ctcbuy: "CTC-Buy",
                ctcsell: "CTC-Sell",
                chargetime: 'order generation time',
                type: 'Type',
                num: 'Amount',
                status: 'Status',
                finish: 'Finished',
                fee: 'Return of mining fees',
                transactionTime: 'Trading time',
                symbol: 'Coin Pair',
                direction: 'Direction',
                price: 'Price',
                entrustment: 'Entrustment',
                havedeal: 'Have done a deal',
                poundageAmount: 'Service Charge ',
                exchangeOrderId: "The order ID",
                mineAmount: "Dig a currency (BHB)",
                buy: "BUY",
                sell: "SELL",
                limit: "LIMIT",
                market: "MARKET",
                clear: "Clear Condition",
                turnover: "Turnover"
            },
            inviting: {
                accumulative_return: 'Inviting mining rewards has been returned and accumulated(BHB) : ',
                accumulat_return: 'Invite mining awards to be returned(BHB) : ',
                account_date: 'Time for receiving the capital',
                start_end: 'Start-End',
                to: 'To',
                operatetype: 'Action Type',
                search: 'Search',
                charge: 'Charge',
                pickup: 'Pick up',
                transaccount: 'Transfer',
                exchange: 'Exchange',
                otcbuy: 'OTC-Buy',
                otcsell: 'OTC-Sell',
                activityaward: 'Activity Award',
                promotionaward: 'Promotion Award',
                dividend: 'Dividend',
                vote: 'Vote',
                handrecharge: 'Artificial recharge',
                match: 'Match',
                chargetime: ' order generation time',
                type: 'Type',
                num: 'Amount',
                status: 'Status',
                finish: 'Finished',
                fee: 'Fee',
                symbol: 'Coin symbol',
                refereename: 'Name of the referee',
                referetel: 'Referee\'s cell phone number',
                invitingawards: 'Inviting mining Awards(BHB)',
                refere: 'Referee',
                refereinput: 'Please enter the name / cell phone number'
            },
            paydividende: {
                money_holding: 'Money sharing has been returned and accumulated(ETH) : ',
                money_hold: 'Pay dividends to return(ETH) : ',
                paydividends: 'Pay dividends(ETH)',
                account_date: 'Time for receiving the capital',
                datehodld: 'Date of holding money',
                start_end: 'Start-End',
                to: 'To',
                operatetype: 'Action Type',
                search: 'Search',
                charge: 'Charge',
                pickup: 'Pick up',
                transaccount: 'Transfer',
                exchange: 'Exchange',
                otcbuy: 'OTC-Buy',
                otcsell: 'OTC-Sell',
                activityaward: 'Activity Award',
                promotionaward: 'Promotion Award',
                dividend: 'Dividend',
                vote: 'Vote',
                handrecharge: 'Artificial recharge',
                match: 'Match',
                chargetime: 'Charge Time',
                type: 'Type',
                num: 'Amount',
                status: 'Status',
                finish: 'Finished',
                fee: 'Fee',
                symbol: 'Coin symbol',
            },
            record: {
                start_end: 'Start-End',
                to: 'To',
                operatetype: 'Action Type',
                search: 'Search',
                charge: 'Charge',
                pickup: 'Pick up',
                transaccount: 'Transfer',
                exchange: 'Exchange',
                otcbuy: 'OTC-Buy',
                otcsell: 'OTC-Sell',
                activityaward: 'Activity Award',
                promotionaward: 'Promotion Award',
                dividend: 'Dividend',
                vote: 'Vote',
                handrecharge: 'Artificial recharge',
                match: 'Match',
                chargetime: 'Charge Time',
                type: 'Type',
                num: 'Amount',
                status: 'Status',
                finish: 'Finished',
                fee: 'Fee',
                shouldfee: 'Fee payable',
                discountfee: 'Deduction charge',
                realfee: 'Service charge',
                symbol: 'Coin symbol',
                activitybuy: "Activity Buy",
                ctcbuy: "C2C BUY",
                ctcsell: "C2C SELL",
                redout: "RED-OUT",
                redin: "RED-IN",
                quickExchange: "Quick Exchange",
                currentRate: "Current Exchange Rate",
                from: "From",
                inputexchangeamount: "Input Quick Exchange Amount",
                inputexchangepasswd: "Input Exchange Password",
                chooseTransCoinUnit: "Choose Trans Coin",
                inputTransAmount: "Choose Trans Amount",
                predictAmount: "Will Exchange",
                qamount: "Amout",
                qrate: "Rate",
                qexamount: "Exchange Amount",
                withdrawcodein: "WithdrawCode Recharge",
                withdrawcodeout: "WithdrawCode Withdraw",
                contractfee: "Swap Fee",
                contractprofit: "Swap Profit",
                contractloss: "Swap Loss",
                optionfail: "Option Fail",
                optionfee: "Option Fee",
                optionreward: "Option Reward",
                other: "Other"
            },
            recharge: {
                recharge: 'Deposit',
                symbol: 'Coin',
                address: 'Deposit Address',
                copy: 'Copy',
                qrcode: 'QRCode',
                qrcodeaddress: 'QRCode For Deposit',
                msg1: 'Do not recharge any non-currency assets to the above address, otherwise the assets will not be recovered.',
                msg2: 'After you recharge to the above address, you need to confirm the entire network node, after 2 network confirmations arrive, after 6 network confirmation can be extracted.',
                msg3: 'Minimum recharge amount: ',
                msg3_1: ", recharge less than the minimum amount will not be accounted.",
                msg4: 'Your top-up address will not change frequently and you can repeat the recharge; if there is any change, we will try to notify you via website announcement or email.',
                msg5: 'Please be sure to confirm the security of your computer and browser to prevent information from being tampered with or leaked.',
                record: 'Recording',
                copysuccess: 'Success！',
                copyerr: 'Failure！Please copy it manually',
                time: 'Arrival time',
                amount: 'Amount',
                gettingaddress: "Getting deposit address，Please wait...",
                getaddress: "Get Deposit Address",
                memotips: "Please enter this Memo when recharging, otherwise you will lose assets.",
                withdrawrecharge: "Recharge by code",
                coderechargetip: "Please enter withdraw code",
                rechargesuccess: "Congratulations, Recharge success!",
                rechargeconfirm: "Recharge Confirm",
                status: "Status"
            },
            withdraw: {
                pickup: 'Pick up',
                addressmanager: 'Address management',
                withdrawbycode: 'Withdraw by code',
                withdrawcode: 'Withdraw Code',
                symbol: 'Coin',
                address: 'Address',
                num: 'Amount',
                avabalance: 'Available Balance',
                msg1: 'The amount of coins is lower than',
                msg2: 'Automatically arrives at account, otherwise manual review is required',
                increase: 'Increase credit',
                tip1: 'Enter up to ',
                tip11: 'decimal places and the minimum value is',
                tip2: 'The maximum value is',
                numtip1: 'Enter the number of coins',
                fee: 'Fees',
                range: 'Range',
                arriamount: 'The number of arrivals',
                msg3: 'The minimum number of coins is',
                msg4: 'currency',
                msg5: 'In order to ensure the security of funds, when your account security policy changes, passwords are changed, and you use the new address to withdraw coins, we will conduct a manual audit of the coins. Please be patient and wait for the staff to call or email.',
                msg6: 'Please be sure to confirm the security of your computer and browser to prevent information from being tampered with or leaked.',
                record: 'Withdrawals record',
                symboltip: 'Please select a currency',
                addresstip: 'Please fill in the address',
                numtip2: 'Please fill in the correct number of coins, the minimum value is',
                numtip3: 'Coinage amount must not be less than processing fee',
                amounttip: 'Please enter amount',
                feetip1: 'The minimum fee is',
                feetip2: 'The maximum value is',
                time: 'Withdrawal time',
                status: 'Status',
                status_1: 'Reviewing',
                status_2: 'Transfering',
                status_3: 'Failure',
                status_4: 'Success',
                remark: 'Remark',
                add: 'Add',
                addresslist: 'Address List',
                safevalidate: 'Safety verification',
                telno: 'Tel',
                smscode: 'Phone verification code',
                second: 'second',
                clickget: 'Get',
                email: 'Email',
                emailcode: 'Email  verification code',
                save: 'Save',
                delete: 'Delete',
                telerr: 'Incorrect phone number',
                emailerr: 'Incorrect email',
                codeerr: 'Incorrect verification code',
                remarktip: 'Enter remark',
                savemsg1: 'Failed to save!',
                savemsg2: 'Saved successfully!',
                operate: 'Operate',
                fundpwdtip: 'Please enter funds password',
                click: 'click',
                filtrate: 'can filtrate',
                txid: "TxId",
                generateCode: "Generate WithdrawCode",
                withdrawcodelist: "WithdrawCode List",
                withdrawcodestatus: "Status",
                submit: "Submit",
                generatecodesuccess: "WithdrawCode generated!"
            }
        },
        member: {
            usercenter: "User Profile",
            securitycenter: 'Security center',
            securitysetting: 'Security setting',
            accountsetting: 'Account setting',
            account: "Account",
            assets: "Finance",
            exchange: "Exchange",
            curdelegate: "Current Delegate",
            hisdelegate: "History Delegate",
            otc: "OTC"
        },
        order: {
            ordercenter: 'Order center',
            myorder: 'My order',
            myad: 'My ad',
        },
        regist: {
            hasaccount: 'Have a Account？To Log In',
            login: 'Log in',
            username: 'Username',
            country: 'Country',
            smscode: 'Sms verification code',
            sendcode: 'Send',
            resendcode: "Resend(",
            pwd: 'Password',
            repwd: 'Confirm password',
            confrimpwd: 'Confrim password',
            agreement: 'I have read and agree',
            userprotocol: 'User Agreement',
            regist: 'Sign up',
            teltip: 'Enter tel',
            telerr: 'Tel format error,enter again',
            emailtip: 'Enter email',
            emailerr: 'Email format error,enter again',
            confirmpwdtip: 'Enter confirm password',
            confirmpwderr: 'Two passwords not same!',
            telregist: 'Tel Regist',
            emailregist: 'Email Regist',
            usernametip: 'Enter username',
            usernamemsg: 'Username length not less than 3,not more than15',
            countrytip: 'Select country',
            smscodetip: 'Enter sms verification code',
            pwdtip: 'Enter login password',
            pwdmsg: 'Password length not less than 6',
            telno: 'Tel',
            email: 'Email',
            agreementtip: 'Click to agree',
            modaltitle: 'Please verification',
            promotion: 'Invite Code(Optional)',
			china: 'china',
            singapore: 'singapore',
            korea: 'korea',
            japan: 'japan',
            thailand: 'thailand',
            russia: 'russia',
            uk: 'uk',
            vietnam: 'vietnam',
            india: 'india',
            italy: 'italy',
            hk: 'hk',
            malaysia: 'malaysia',
            taiwan: 'taiwan',
            turkey: 'turkey',
            germany: 'germany',
            france: 'france',
            spain: 'spain'
        },
        safe: {
            safelevel_low: 'Security Level: Low',
            safelevel_high: 'Security Level: High',
            safelevel_medium: 'Security Level: Medium',
            nickname: 'Nickname',
            bind: 'Bind',
            binded: 'Binded',
            verifypass: "Verified",
            binding: 'Bindind',
            binderr: 'Failure',
            bindretry: 'Retry',
            verified: 'Verified',
            notverified: "Not verified",
            verifiedtip: 'To protect your account security, please complete real-name certification before you can trade operations!',
            realname: 'Actual name',
            idcard: 'ID Card',
            upload: 'Upload',
            upload_positive: 'Upload photo ID front',
            upload_negative: 'Upload ID card reverse photo',
            upload_hand: 'Upload handheld ID photos',
            save: 'Save',
            reset: 'Reset',
            email: 'Email',
            bindemail: 'Bind email',
            loginpwd: 'Login Password',
            emailcode: 'Email verification code',
            clickget: 'Click to get',
            second: 's',
            phone: 'Telphone',
            bindphone: 'Bind telphone',
            phonecode: 'Telphone verification code',
            logintip: 'Use when logging in to the platform',
            edit: 'Modify',
            oldpwd: 'Old login password',
            newpwd: 'New login password',
            confirmnewpwd: 'Confirm new password',
            fundpwd: 'Fund password',
            fundtip: 'When account funds change, you need to verify the funds password first',
            set: 'Set',
            confirmpwd: 'confirm password',
            oldfundpwd: 'Old Fund password',
            newfundpwd: 'New Fund password',
            newpwdmsg1: 'Please enter no less than 6 new login passwords',
            newpwdmsg2: 'New login password is inconsistent',
            pwdmsg1: 'Please enter no less than 6 passwords',
            pwdmsg2: 'Inconsistent password',
            emailtip: 'Please enter email',
            codetip: 'Please enter verification code',
            telnotip: 'Please enter phone number',
            oldpwdtip: 'Please enter the original password',
            realnametip: 'Please enter your real name',
            idcardtip: 'Please enter the ID number',
            bindphonetip: 'Please bind your phone first!',
            resetfundpwd: 'Reset funds password',
            upload_positivetip: 'Please upload the photo ID card front',
            upload_negativetip: 'Please upload the photo ID card reverse',
            upload_handtip: 'Please upload handheld ID photos',
            save_success: 'Success!',
            save_failure: 'Failure!',
            imgtip: 'Please upload your QRCode for receiver cash',
            idcard_verifymsg1: "1. The file is less than 8M.The information on the document should be clearly visible, no modifications in the document or cover by the foreign objects is allowed, and the document number and name should be visible.",
            idcard_verifymsg2: "2. The photograph should show you without a hat on. It is recommended that there is no make-up. The facial features of the hand-held document holders should be clear, and the arms should be completely exposed.",
            idcard_verifymsg3: "3. Need to hold both hand-held documents and handwritten copy (name + application date), 'All my actions on this site are my own operations, and I understand the risks involved and willing to bear all the legal consequences of this account.'"
        },
        account: {
            pagetitle: 'Bind real name account',
            pagetip: 'Please set your payment method, please be sure to use my real name account',
            backcardno: 'Bank account',
            backcardtip: 'Personal bank card information is not bound',
            modify: 'Modify',
            bind: 'Bind',
            name: 'Name',
            bankaccount: 'Bank account',
            bankbranch: 'Bank branch',
            bankno: 'Bank number',
            confirmbankno: 'Confirm card number',
            fundpwd: 'Fund password',
            save: 'Save',
            zfbaccount: 'Alipay account',
            zfbaccounttip: 'Personal Alipay account is not bound',
            wxaccount: 'Wechat account',
            wxaccounttip: 'Personal Wechat account is not bound',
            banknomsg1: 'Please enter the correct bank card number',
            banknomsg2: 'The bank card number entered twice is inconsistent!',
            verifiedmsg: 'Please perform real name authentication first',
            bankaccountmsg: 'Please select your bank',
            bankbranchmsg: 'Please enter account branch',
            banknomsg: 'Please enter the correct bank card number',
            fundpwdmsg1: 'Please enter the correct fund password',
            fundpwdmsg2: 'Password cannot be less than 6 characters',
            zfbaccountmsg: 'Please enter Alipay account',
            wxaccountmsg: 'Please enter WeChat account',
            save_success: 'Success!',
            save_failure: 'Failure!',
        },
        otcorder: {
            unpaid: 'Unpaid',
            paided: 'Paided',
            finished: 'Finished',
            canceled: 'Canceled',
            appealing: 'Appealing',
            searchtip: 'Enter the order number',
            orderno: 'OrderNo',
            created: 'Time',
            symbol: 'Coin',
            type: 'Type',
            type_sell: 'sell',
            type_buy: 'buy',
            tradename: 'TradeName',
            amount: 'Amount',
            money: 'Money',
            fee: 'Fee',
        },
        identity: {
            certified: "already certified",
            placeholder: "Please fill in the reason for cancellation",
            apply: 'Apply to become business',
            become: "Become CoinPro Certified Business and enjoy more transaction privileges",
            zhusnhu: "Businesses enjoy exclusive advertising booths to increase transaction success",
            tijiaoziliao: "Submit merchant authentication information",
            place: "Please upload the prepared merchant authentication information to the platform and submit it",
            tijiao: "Your merchant certification audit has been submitted",
            tijiaosuc: "congratulations Your merchant certification has passed",
            tijiaofail: "I'm sorry! Your merchant's certification has not passed",
            zhuxiaotijiao: "Your business cancellation application has been submitted",
            shenhefail: "Your business cancellation application has not been approved",
            shenhesuc: "Your business cancellation application has been approved",
            shangjiazhuxiao: "business cancellation",
            shenagain: "review",
            shenheshibai: "Audit Failed",
            sheqinggain: "To apply for",
            reason: "reason",
            shenqingtuibao: "Apply for loan",
            getquan: "You have the following permissions",
            read: "I read and agree",
            lijishenqing: "Apply immediately",
            tips: "prompt",
            wufachexiao: "You are in the business cancellation operation, after confirming the submission of the application operation, it will not be able to be revoked.",
            suredo: "Are you sure this is done?",
            shuzizichan: "Digital asset trading certificate",
            gerenzichan: "Personal digital asset certificate",
            second: {
                line: "How to apply for business?",
                step1: "Step 1: prepare business application materials as required",
                step1c1: "Prepare the following application:",
                step1c2: "Mobile phone number, WeChat ID, QQ number, personal digital asset certificate (picture), digital asset transaction certificate (picture)",
                step2: "Step 2: submit the application",
                step2c: "To complete the business certification audit materials that need to be filled in and uploaded, click submit for approval.",
                step3: "Step 3: data audit",
                stepc: "We'll be reviewing your business applications for the next three to five days, please keep your eyes open, but you can submit a page. After the approval, you can publish the advertisement in the French currency exchange area.",
                agree: "Agreed to freeze",
                agreec: "As a merchant deposit",
                shenqingchngweishangjia: "Apply for business",
            },
            yuanyin: "reason",
            bizhong: "Deposit currency",
            shuliang: "Margin amount",
            chosen: "Select the currency",
            seat: 'Exclusive booth',
            service: 'One-to-one service',
            lowfee: 'Lower fees',
            phone: 'Phone',
            balance: 'Personal assets',
            cardphoto: 'Positive and negative ID photos',
            wx: 'Wechat',
            exchange: 'Whether to engage in off-exchange trading of digital assets',
            handphoto: 'User holding ID photo',
            qq: 'QQ',
            ploy: 'Whether there is a corresponding risk control strategy',
            agreement: '《Certified Merchant Agreement》',
            applyfor: 'Apply',
            sendcode: 'Send',
            confirm: 'Confirm',
            prepare: 'Prepare materials',
            result: "Wait for the result",
            review: 'Submit review',
            passed: 'Review passed',
            approve: 'Please agree to the certification merchant agreement',
            emailtip1: 'Please email the following materials to',
            emailtip2: 'We will review your application as soon as possible.',
        },
        extension: {
            title1: 'Promotion links',
            title2: 'Promotion friends',
            title3: 'My commission',
            linkdesc: 'The following URL is your address for promotion to the outside world. You can promote it through friends, QQ, Wechat, Weibo, blogs, forums or your own website. All people who have accessed through this address will be your users after registration., And when these users submit strategies in this site, you can earn commissions, and detailed promotion can be viewed in the access record.',
            linktitle: 'Your romotion links',
            copy: 'Copy',
            copy_msg1: 'Copy Success！',
            copy_msg2: 'Failure！Please copy it manually',
            username: 'Name',
            currrecharge: 'Transaction',
            userlevel: 'User Level',
            createdtime: 'Registration time',
            currcommission: 'Current commission',
            managerfee: 'management fee',
            yuan: 'yuan(RMB)',
            symbol: 'COIN',
            amount: 'Amount',
            remark: 'Remark',
            amounttime: 'Deliver Time',
        },
        promotion: {
            title: "Promotion Center",
            subtitle1: "My Cards",
            subtitle2: "Exchange Records",
            subtitle3: "My Promotion",
            card_column0: "Exchange Code",
            card_column1: "Name",
            card_column2: "Coin Symbol",
            card_column3: "Amount",
            card_column4: "Dead Line",
            card_column5: "Total",
            card_column6: "Exchanged",
            card_column7: "Create Time",
            my_column0: "Invite User",
            my_column1: "Registion Date",
            my_column2: "Real Name",
            gopromotion: "Promotion",
            inputcardno: "Please input the card code",
            exchange: "Exchange",
            exchangesuccess: "Congratulations！You can check the asset in finance center！",
            exchangewithcode: "Exchange With Code"
        },
        activity: {
            navTitle: "Innovation ",
            subNavTitle1: "My Orders",
            subNavTitle2: "My Minings",
            subNavTitle3: "My Locked",
            column1: "Name",
            column2: "Type",
            column3: "Amount",
            column4: "Unit(IN)",
            column5: "Unit(OUT)",
            column6: "Status",
            column7: "Turnover",
            column8: "Create Time",
            mColumn1: "Image",
            mColumn2: "Name",
            mColumn3: "Coin Unit",
            mColumn4: "Period",
            mColumn5: "MiningDays",
            mColumn6: "MiningedDays",
            mColumn7: "Status",
            mColumn8: "Base Capacity",
            mColumn9: "Current Capacity",
            mColumn10: "Total Mininged",
            mColumn11: "End Date",
            mColumn12: "Create Date",
            lColumn1: "Image",
            lColumn2: "Name",
            lColumn3: "Coin Unit",
            lColumn4: "Period",
            lColumn5: "LockedDays",
            lColumn6: "ReleasedDays",
            lColumn7: "Status",
            lColumn8: "Base Capacity",
            lColumn9: "Current Capacity",
            lColumn10: "Total Released",
            lColumn11: "End Date",
            lColumn12: "Create Date",
        },
        mining: {
            title: "My Mining List",
            empty: "No Records",
            day: "Day",
            week: "Week",
            month: "Month",
            year: "Year",
            invitetip1: "The capacity of the mine machine can be improved by inviting friends. Each person invited to exchange the mine machine will increase the capacity ",
            invitetip2: "Upper limit of capacity increase ",
            miningUnit: "Mining Unit",
            miningPeriod: "Mining Period",
            miningDays: "Mining Times",
            miningedDays: "Mininged Times",
            miningDaysProfit: "Base Output",
            miningCurrentDaysProfit: "Current Output",
            status0: "To be deployed",
            status1: "Deployed",
            status2: "Over"
        },
        locked: {
            title: "My Locked List",
            empty: "No Records",
            day: "Day",
            week: "Week",
            month: "Month",
            year: "Year",
            invitetip1: "The capacity of the locked can be improved by inviting friends. Each person invited to exchange the locked will increase the capacity ",
            invitetip2: "Upper limit of capacity increase ",
            miningUnit: "Release Unit",
            miningPeriod: "Release Period",
            miningDays: "Release Times",
            miningedDays: "Released Times",
            miningDaysProfit: "Base Output",
            miningCurrentDaysProfit: "Current Output",
            status0: "To be release",
            status1: "Releasing",
            status2: "Over",
            totallocked: "Total Locked"
        },
        api: {
            apiAdmin: "API Management",
            createApi: "Create a new API Key ",
            mark: "remark",
            bindAddress: "The unbound IP address key is valid for 90 days",
            hint: 'hint',
            part1: "We provides you with a powerful API, you can use market query, automatic transactions and other services",
            apiDoc: "by API documentation.",
            howUse: "See about how to use",
            // part2: "Each user creates up to five sets of API keys",
            part2: "Create up to 5 sets of API Keys per user",
            part3: "Please do not disclose your API Key to avoid asset loss. For security reasons, it is recommended to bind IP for API Key, with a maximum of 5 IP for each API Key. Fill in a single address directly, multiple IP addresses half Angle commas, such as 192.168.1.1, 192.168.1.2 instead, 192.168.1.3.",
            myApiKey: "My API Key",
            ipAddress: "IP address",
            // createTime: "creation time",
            createTime: "CreateTime",
            accessKey: "Access Key",
            bindIpAddress: "Bind IP address",
            // ioDays: "Remaining validity period (days)",
            ioDays: "The remaining days",
            operation: "operation",
            create: "create",
            edit: "edit",
            delete: "delete",
            sureDelete: "Are you sure to delete?",
            tips: "The key is only displayed when it is added, please save it in time.",
            copy: "copy",
            access: "key",
            okText: "sure",
            cancel: "cancel"
        }
    },
    cms: {
        noticecenter: 'Announcement Center',
        newshelp: 'New user helping',
        appdownload: 'Download App',
        onlineservice: 'online service',
        faq: 'FAQ',
        notice: 'Announcement',
        servicecenter: 'Customer Center',
        about: 'About',
        joinus: 'Join us',
        aboutus: 'About us',
        exchangerule: 'Trading Rules',
        useprotocol: 'Use Agreement',
        feenote: 'Charge description',
        merchantprocotol: 'Merchant Agreement',
        features: "Features",
        contactus: "Contact us",
        noticelist: 'All Announcement',
        nextnotice: 'NEXT',
        prevnotice: "PREV",
        notexist: "The page you visited does not exist or has been delete",
        otherhelp: "Other In This Category",
        scanforshare: "Scan for share",
        download: "Download",
        downloadslogan: "CoinPro APP - Trade everywhere."
    },
    description: {
        title1: 'Security',
        title2: '24H Service',
        title3: 'Global Exchange',
        title4: 'Strictly Project',
        message1: 'All-round financial wind control system and anti-theft system, hot and cold wallet, multi-signature system to ensure the safety of funds',
        message2: '24H manual online audit can protect customers from missing out on the best investment opportunities.',
        message3: 'Global Business Service Network Coverage Helps You Invest in Global Encrypted Assets and Transact with Global Users',
        message4: 'We strictly select those high-quality blockchain projects to filter 80% extremely high-risk projects for you.',
        scanqrcode: "Download APP",
        aboutdesc1: "COIN.PRO is a leading digital asset trading platform in the world, registered in the Cayman Islands, with a core operating team in Hong Kong. The core members of COIN.PRO come from top Internet and financial companies. Most of them are deep believers in Bitcoin and block chains. We are convinced that block chains will change the traditional monopolistic financial system and build a more democratic and autonomous social structure.",
        aboutdesc2: "COIN.PRO platform has a professional financial-level trading architecture, a self-developed high concurrent memory matching trading engine. The platform uses a full-cold rechargeable wallet system + multiple signatures + high-protection DDOS attack system and other trading architecture to ensure customer asset security.",
        aboutdesc3: "In order to let customers better grasp the investment opportunities, COIN.PRO adopts flat management in customer service department, and establishes an extremely fast service response mechanism. The asset customer service manager is on-line 7*24H all the year round, providing customers with services such as recharge and cash of assets, and ensuring that customers complete recharge in 25 minutes.",
        aboutdesc4: "COIN.PRO strictly screens high-quality projects and provides secure and stable asset hosting services. COIN.PRO upholds the concept of “honesty, justice, enthusiasm and openness”, and strives to create a safe, reliable, efficient and friendly ultimate exchange for users.",
        aboutdesc5: "Although digital assets are only in a small circle now, we believe that this kind of assets that everyone can fully grasp will be popular in the future. Let's work together and look forward to it!",
        support: "Support",
        report: "Suggestion",
        service: "Customer Service",
        apply: "Board Apply",
        coop: "Cooperation",
        community: "CoinPro Community",
        wechat: "Wechat",
        addwechat: "Add 'coinpro01' as Wechat friends and then enter Wechat community",
        weibo: "Weibo",
        twitter: "Twitter",
        biyong: "Biyong",
        telegram: "Telegram",
        medium: "Medium",
        reddit: "Reddit"
    },
    bzb_description: {
        title1: "",
        title2: "",
        title3: "",
        title4: "",
        title5: "",
        title6: "",
        title7: "",
        title8: "",
        desc1: "",
        desc2: "",
        desc3: "",
        desc4: "",
        desc5: "",
        desc6: "",
        desc7: "",
        desc8: ""
    },
    footer: {
        wechatkefu: "wechat service",
        notice: 'Announcement',
        gsmc: 'Exchange The Best Cryptocurrency',
        bah: 'No. 13026899 -3, Shanghai ICP',
        gsjj: 'Profile',
        gywm: 'About Us',
        jrwm: 'Join Us',
        biyong: 'BiYong',
        lxwm: 'Contact us',
        yqlj: 'Links',
        bzzx: 'Help',
        xsrm: " A beginner's course",
        czzn: 'Recharge Guide',
        jyzn: 'Trade Guide',
        ptsm: 'Explain',
        fysm: 'Fees',
        fwtk: 'Service Policy',
        fltk: 'legal Policy',
        wechat: "Wechat Customer Service",
        xszn: "Beginner's Guide",
        cjwt: "FAQ",
        mztk: "Disclaimer",
        ystk: "Privacy Policy",
        tkxy: "Conditions",
        gybzb: "About BZB",
        kfyx: "Service Mailbox",
        swhz: "Cooperation",
        sbsq: "Market Application",
        jyzn: "Exchange Guide",
        bzzl: "Coin’s Info",
        tsjb: "Report",
        apidoc: "Api Doc",
        zwkf: "come soon"
    },
    index: {
        circulation: 'Safety and development fund circulation',
        hourEth: 'Cumulative revenue to be distributed in today',
        yesterdayEth: 'Cumulative revenue to be distributed in yesterday',
        yesterday: 'Mining output of the previous day:',
        bhbTotal: "BHB Total flux",
        bhbSecond: "BHB Two level market circulation",
        checkMineprinciple: "View the principle of mining",
        checkFlowVolum: 'Inspection of circulation',
        checkLockPosition: 'Check the lock situation',
        BHBregister: "CoinPro account registration",
        tibi: "The time and limit of the payment of the money to the bill",
        line_plane: "Announcement on BHB's on-line plan, circulation and service fee return",
        fenpeijizhi: 'A description of the CoinPro income distribution mechanism',
        jiangli_jihua: "Inviting the mining Award Scheme",
        friend_fanhuan: "Invite friends, earn extra fee and return"
    },
    plate: {
        title: "Platform income distribution scheme（BHB Holder's equity）",
        content1: "As described in the white paper, CoinPro will take up 80% of the platform (after deduct Taxes and fees )The income is allocated to BHB holders, and 20% of revenue is used to support R & D and operation of the platform.",
        content2: "The distribution of income takes days as a distribution cycle, and June 6, 2018 as the first distribution day. Every day after that, the total income to be distributed on the previous day will be allocated to BHB holders in proportion.",
        content3: "（notice：1. the BHB here only refers to the BHB that has been released / circulated. See also【",
        content3_1: 'Note on the ratio of BHB circulation / participation in income distribution',
        content3_2: "2. the hourly (whole point) snapshot is calculated once and the income distribution is executed once a day.",
        hourFenpei: "Distribution of income today",
        hourTotal: "Total income of today's platform",
        xiangqing: "Details of today's income distribution",
        yesterdaytit: "Distribution of income yesterday",
        yesterdaytotal: "Total income of yesterday's platform",
        yesterdayxiangqing: "Details of yesterday's income distribution",
        yesterday: {
            total: "Total Revenues",
            allocated: "Undistributed Revenues",
        }
    },
    feereturn: {
        ruletitle: "Note",
        rulecontent: 'Our White Paper has a detailed explanation of Reimbursement of Transaction Fees. Overall, 51% of the platform' + 's total BHB base (referred to as "community reward portion" from now on) will be reimbursed to users over time via our "Trans-Fee Mining" mechanism. However once this 51% of the total BHBs has been fully released via reimbursement, the "Trans-Fee Mining" mechanism will automatically end. ',
        recordtitle: 'List',
        recordcontent: 'Everyday (UTC+8, same as below) BHB will reimburse 100% of the users' + 'transaction fees incurred from the previous day in the form of BHB. This will be done by using the average BHB price of the previous day (this average price is calculated as total value transacted/total volume transacted). We will complete this reimbursement of transaction fees from the previous day in the form of BHBs, at 11am everyday.',
        time: "Date",
        todaycharge: "Average price (ETH)",
        totalChange: 'Total (ETH)',
        returnCharge: "Trans-Fee Mining reimbursement(BHB)",
        todayChargeReturn: "Incentive Program for FCoin reimbursement（BHB）"
    }
}
